// import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  HttpErrorResponse,
  HttpHeaders,
  HttpClient,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as rxOp from 'rxjs/operators';
import { catchError, tap } from 'rxjs/operators';
import { Activity, PaginatedList } from '../models';
import { UtilsService } from './utils.service';
import * as _ from 'lodash';
import { environment } from './../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({ providedIn: 'root' })
export class ActivityService {
  constructor(private http: HttpClient, private utils: UtilsService) { }

  list(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:activity-list');
    return this.http
      .get<PaginatedList>(url, this.utils.requestOptions(qp))
      .pipe(
        rxOp.map((resp: PaginatedList) => {
          // resp.results = Activity.fromList(resp.results);
          return resp;
        })
      );
  }

  tags(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:activity-tags');
    return this.http.get(url, this.utils.requestOptions(qp));
  }  

  // NOT USED AT THIS POINT
  listBaseActivities(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:activity-group-list');
    return this.http
      .get<PaginatedList>(url, this.utils.requestOptions(qp))
      .pipe(
        rxOp.map((resp: PaginatedList) => {
          resp.results = Activity.fromList(resp.results);
          return resp.results;
        })
      );
  }

  listGroups(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:activity-groups-list');
    return this.http
      .get<PaginatedList>(url, this.utils.requestOptions(qp))
      .pipe(
        rxOp.map((resp: PaginatedList) => {
          return resp.results;
        })
      );
  }

  updateGroup(id: number, obj: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:activity-groups-detail', id);
    return this.http.patch(url, obj, this.utils.requestOptions(qp));
  }  

  listVideos(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:activity-videos-list');
    return this.http
      .get<PaginatedList>(url, this.utils.requestOptions(qp))
      .pipe(
        rxOp.map((resp: PaginatedList) => {
          return resp.results;
        })
      );
  }

  listProducts(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:activity-products-list');
    return this.http
      .get<PaginatedList>(url, this.utils.requestOptions(qp))
      .pipe(
        rxOp.map((resp: PaginatedList) => {
          return resp.results;
        })
      );
  }

  get(id: string, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:activity-detail', id);
    return this.http.get(url, this.utils.requestOptions(qp));
  }

  create(obj: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:activity-list');
    return this.http.post(url, obj, this.utils.requestOptions(qp));
  }

  link(obj: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:user-activity-list');
    return this.http.post(url, obj, this.utils.requestOptions(qp));
  }

  unlink(id: number): Observable<any> {
    const url = this.utils.getUrl('rest:user-activity-detail', id);
    return this.http.delete(url);
  }

  update(id: number, obj: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:user-activity-detail', id);
    return this.http.patch(url, obj, this.utils.requestOptions(qp));
  }

  // ADMIN
  updateActivity(id: number, obj: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:activity-detail', id);
    return this.http.patch(url, obj, this.utils.requestOptions(qp));
  }

  // ERROR LOGGING
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a StockService message with the MessageService */
  private log(message: string) {
    console.log(message);
  }
}
