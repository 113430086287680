<button mat-button color="primary" (click)="utils.toggleSidenav()" class="menuBtn" 
*ngIf="(user == null && url != '/' && !url?.includes('/login') && !url?.includes('/join') && !url?.includes('forgot') && !url?.includes('/about')
&& url != '/search' && url != '/activities' && url != '/members' && url != '/posts')
  || url?.includes('/dashboard') || url?.includes('settings') || (url?.includes('profile') && !isPostDetail())" [class.iosMenu]="utils.ios()">
  <mat-icon>menu</mat-icon>
</button>

<button mat-button class="back" onclick="history.back(-1);window.scrollTo(0,0)" (click)="utils.sound()" *ngIf="url?.includes('/activity')" [class.iosMenu]="utils.ios()">
  <mat-animated-icon start="arrow_back" end="arrow_back" colorStart="none" colorEnd="none" [animate]="flags.animate_icon"></mat-animated-icon>
</button>

<div  class="navbarRight" [class.ios]="utils.ios()" *ngIf="(user == null && url?.includes('/:id/') && !url?.includes('login')) || (url?.includes('settings') && !url?.includes('returnUrl='))">
  <a routerLink="/dashboard" onclick="window.scrollTo(0,0)" (click)="utils.sound('home')">
  <img src="./assets/img/activity8-logo.png" height="50" style="padding-top:12px;">
  </a>
</div>

<div class="download animate__animated animate__fadeInDown" [class.ios]="utils.ios()" *ngIf="flags.showDownload && mobileWeb()">
  <button mat-button type="button" class="closeBtn" (click)="closeDownload()" [class.ios]="utils.ios()"><mat-icon>close</mat-icon></button>
  Get the app<br />
  <a href="https://play.google.com/store/apps/details?id=com.activity8.app&hl=en_IE&gl=US"><img src="https://www.activity8.com/wp-content/uploads/2021/04/download-android-white-300x90.png" /></a>
  <a href="https://apps.apple.com/us/app/activity8/id1537925959"><img src="https://www.activity8.com/wp-content/uploads/2021/04/download-ios-white-300x90.png" /></a>

</div>