import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { Component,ElementRef,EventEmitter,OnInit,Output,ViewChild,Input,SimpleChanges,OnDestroy,} from '@angular/core';
import {FormBuilder,FormControl,FormGroup,Validators,} from '@angular/forms';
import {MatAutocomplete,MatAutocompleteSelectedEvent,} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { User } from 'src/app/models';
import { UtilsService, CurrentUserService, UserService } from 'src/app/services';
import { ActivityService } from 'src/app/services/activity.service';
import { LocationService } from 'src/app/services/location.service';
import { GeoLocation } from 'src/app/models/location';
import { Geolocation as CapLocate } from '@capacitor/geolocation';
import { Capacitor } from '@capacitor/core';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit, OnDestroy {
  @Output() change: EventEmitter<GeoLocation>;
  @Input() inputLocation: any;
  @Input() mode: string;

  flags = {
    map: false,
    allowMap: true,
    viewMapOnly: false,
    settingActivity: false,
    loading: false,
    btnDoneDisabled: false,
    status: '',
    saveUserLocation: false,
    capLocation: false,
  };
  user: User;
  subs: Subscription[] = [];
  form: FormGroup;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  locationCtrl = new FormControl();
  filteredLocations: Observable<GeoLocation[]>;
  activity: GeoLocation;
  activities: GeoLocation[] = [];
  previousActivities: GeoLocation[] = [];
  previousInterests: GeoLocation[] = [];
  allLocations: GeoLocation[] = [];
  suggestedLocations: GeoLocation[] = [];
  
  mapLat: number = 51.678418;
  mapLong: number = 7.809007;
  
  @ViewChild('locationInput') locationInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private activityService: ActivityService,
    private meService: CurrentUserService,
    private userService: UserService,
    private locationService: LocationService,
    public utils: UtilsService,
    private formBuilder: FormBuilder
  ) {
    this.change = new EventEmitter();

    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
      this.flags.map = true;
      this.flags.allowMap = true;
      this.flags.viewMapOnly = false;
      this.initLocation();

    });
    this.subs.push(user);

    this.filteredLocations = this.locationCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value['name'])),
      // // map(name => name ? this._filter(name) : this.allLocations.slice()),
      map((name) => {
        return this._filter(name);
      })
    );

    // this.locate();
  }

  ngOnInit() {
    if(this.inputLocation == null){
      this.locate();
    }

    this.form = this.formBuilder.group({
      activities: ['', [Validators.required]],
    });

    this.setbtnDoneDisabled();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.inputLocation.currentValue != null){
      this.initLocation();
    }
  }

  initLocation(){
      if(this.user == null){
        return;
      }
      // SETTING LOCATION FROM HOME
      if(this.user.app_state.showSetLocation == 'set-location' && this.user.location != null && this.user.location.location != null && this.user.location.location.coordinates != null){
        this.flags.settingActivity = false;
        this.flags.map = false;
        this.flags.allowMap = false;
        this.mapLat = this.user.location.location.coordinates[1];
        this.mapLong = this.user.location.location.coordinates[0];
      }
      // SETTING ACTIVITY LOCATION
      else if(this.user.app_state.showSetLocation != 'set-location'){
        if(this.inputLocation == null){
          this.inputLocation = this.user.location;
        }
        if(this.inputLocation != null && this.inputLocation.location != null && this.inputLocation.location.coordinates != null)
        {
          this.flags.settingActivity = true;
          this.mapLat = this.inputLocation.location.coordinates[1];
          this.mapLong = this.inputLocation.location.coordinates[0];  
        }
        if(this.user.app_state.showSetLocation == 'view-map'){
          this.flags.viewMapOnly = true;
        }
      }
      this.getSuggestions();    
  }

  get f1() {
    return this.form.controls;
  }

  clickAddLocation() {
    // FADE BACK
    // this.user.app_state['homeCover'] = 'in';
    // setTimeout(() => this.user.app_state['homeCover'] = 'in', 700);
    // this.utils.appState.user.next(this.user);
  }

  // -- MAP --
  toggleMap(){
    this.flags.map = !this.flags.map;
  }

  mapClick($event){
    if(!this.flags.viewMapOnly){
      this.mapLat = $event.coords.lat;
      this.mapLong = $event.coords.lng;
    }
  }

  mapSetLocation(){
    this.getReverse(this.mapLong, this.mapLat);
    this.close();
  }
  // -- MAP --

  close() {
    this.user.app_state.showSetLocation = false;
  }

  // clickDone() {
  //   if ((this.locationCtrl.value || '').trim()) {
  //     // this.getActivity(this.locationCtrl.value.trim());
  //     this.setbtnDoneDisabled();
  //   }

  //   this.user.app_state.showsaveLocation = false;
  //   this.utils.appState.user.next(this.user);
  //   this.change.emit(false);
  // }

  addFromButton(control) {
    if ((control.value || '').trim()) {
      // this.getActivity(control.value.trim());
      this.setbtnDoneDisabled();
    }
    control.value = '';
    this.locationInput.nativeElement.value = '';
    this.setbtnDoneDisabled();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    console.log(value);

    // Add
    if ((value || '').trim()) {
      console.log(value);
      // this.saveLocation(value.trim());

      this.setbtnDoneDisabled();
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.locationCtrl.setValue(null);
  }

  clickPill(location: GeoLocation) {
    let city = location.fields.city;
    let state = location.fields.state;
    let country = location.fields.country;
    
    if(location.place != null && location.place.properties != null){
      city = location.place.properties.city;
      state = location.place.properties.stateCode;
      country = location.place.properties.countryCode;
    }

    if (this.user.app_state.showSetLocation == 'set-location') {
      this.saveLocation(city, state, location.fields.lng, location.fields.lat);
    } else {
      this.setLocation(location.place.geometry.coordinates, location.name, city, state, country);
    }
    this.close();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let location: GeoLocation = event.option.value;
    // location.place = {};
    if (this.user.app_state.showSetLocation == 'set-location') {
      this.saveLocation(location.place.properties.city, location.place.properties.stateCode, location.place.geometry.coordinates[0], location.place.geometry.coordinates[1]);
    } else {
      this.setLocation(location.place.geometry.coordinates, location.name, location.place.properties.city, location.place.properties.stateCode, location.place.properties.country);
      this.close();
    }
  }

  setLocation(coordinates, name, city, state, country){
    let location: GeoLocation = new GeoLocation({});
    // WHY DO WE NEED THIS? ITS STOPPING SAVE LOCATION ON LOCATION IN getReverse L:365
    // this.user.app_state.showSetLocation = null;
    location.setLocation(coordinates);
    location.place = name;
    location.city = city;
    location.state = state;
    location.country = country;
    this.user.app_state.setActivityLocation = location;

    if(!this.flags.settingActivity){
      this.user.location = location;
      this.utils.appState.user.next(this.user);
    }
    this.change.emit(location);
  }

  private _filter(name: string) {
    if (name.length > 1) {
      const filterValue = name.toLowerCase();

      if (this.user.app_state.showSetLocation != 'set-location' && this.user.location.location != null) {
        this.getLocationsByName(this.user.location.location.coordinates, filterValue);
      }
      else {
        this.getCitiesByName(filterValue);
      }

      // I NEED TO RETURN SUBSCRIPTION / PROMISE FROM ABOVE CALLS
      if (this.allLocations != null) {
        return this.allLocations.filter((location) => location.name.toLowerCase().indexOf(filterValue) >= 0);
      }
    }
  }

  displayFn(activity: GeoLocation): string {
    return activity && activity.name ? activity.name : '';
  }

  setbtnDoneDisabled() {
    if ((this.activities != null && this.activities.length > 0) || this.mode == 'interest') {
      this.flags.btnDoneDisabled = false;
    } else {
      this.flags.btnDoneDisabled = true;
    }
  }

  getPosition = async () => {
    const coordinates = await CapLocate.getCurrentPosition();
    if(coordinates != null){
      this.updateLocation(coordinates.coords.longitude, coordinates.coords.latitude);
      this.flags.capLocation = true;
    }
    console.log('Cap position:', coordinates.coords);
  };

  locate(showLoading=false): void {
    if(showLoading){
      this.flags.loading = true;
    }
    this.flags.status = 'locating';
    if (Capacitor.isNativePlatform()) {
      this.getPosition();
    }
    
    if (!Capacitor.isNativePlatform() && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const longitude = position.coords.longitude;
          const latitude = position.coords.latitude;
          if(!this.flags.capLocation){
            this.updateLocation(longitude, latitude);
          }
        },
        (e) => {
          let location: GeoLocation = new GeoLocation({});
          if(this.user.location != null){
            this.user.location.status = 'failed';
            this.user.location = location;
          }
          this.flags.loading = false;
          // alert('Finding your Location failed.');
        },
        { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true }
      );
    } 
    //IOS going in here even after getting location
    // else {
    //   this.flags.loading = false;
    //   alert('No support for geolocation');
    //   this.flags.status = 'failed';
    // }
  }

  updateLocation(longitude, latitude){
      // UPDATE MAP PIN
      this.mapLat = latitude;
      this.mapLong = longitude;

      let location: GeoLocation = new GeoLocation({});
      location.setLocation([longitude, latitude]);

      // SAVE IF THE LOCATION IS EMPTY OR HAS CHANGED
      if(this.user.location != location && !this.flags.settingActivity){
        this.flags.saveUserLocation = true;
      }

      // this.user.location = location;
      // this.utils.appState.user.next(this.user);
      // WE SAVE THE LOCATION TO THE USER ONCE WE GET THE REVERSE
      if(longitude != null && latitude != null){
        this.getReverse(longitude, latitude);
      }

      this.getSuggestions();
  }


  getSuggestions() {
    if (this.user.location != null && this.user.location.location != null && this.user.location.location.coordinates != null) {
      if (this.user.app_state.showSetLocation == 'set-location') {
        this.getCitySuggestions(this.user.location.location.coordinates);
      }
      else if (this.user.app_state.showSetLocation == 'unknown-activity') {
        // this.getLocationSuggestions(this.user.location.location.coordinates, this.user.location.city);
      }
      else if (this.user.app_state.showSetLocation != null) {
        this.getLocationSuggestions(this.user.location.location.coordinates, this.user.app_state.showSetLocation);
      }
    } else {
      // MOVED THIS TO onIt OTHERWISE LOOPS ON JOIN
      // this.locate();
    }
  }

  // DATA ITEMS
  getCitiesByName(name) {
    this.locationService.getCities(name, 15).subscribe((data) => {
      this.allLocations = data.results;
    });
  }

  getLocationsByName(coordinates, searchStr) {
    this.locationService.getNearBy(coordinates, searchStr, 10).subscribe((data) => {
      this.allLocations = data;
    });
  }

  getLocationSuggestions(coordinates, searchStr?) {
    this.locationService.getNearBy(coordinates, searchStr, 30).subscribe((data) => {
      this.suggestedLocations = data;
    });
  }

  getReverse(long, lat) {
    this.locationService.reverse(long, lat).subscribe((data) => {
      if(data.results[0].locations[0] != null){
        const location = data.results[0].locations[0];
        const city = location.adminArea5;
        const county = location.adminArea4;
        const state = location.adminArea3;
        const country = location.adminArea1;
        this.setLocation([long, lat], city + ', ' + state, city, state, country);
        // this.setLocation([long, lat], 'Map', city, state, country);
      }
      else{
        this.setLocation([long, lat], 'Map', 'Unknown', 'Unknown', 'Unknown');
      }

      // SAVE TO USER
      if(this.user.app_state.showSetLocation == 'set-location' || this.flags.saveUserLocation){
        if(this.user != null && this.user.location != null && this.user.location.location != null && this.user.location.location.coordinates != null){
          this.saveLocation(this.user.location.city, this.user.location.state, this.user.location.location.coordinates[0], this.user.location.location.coordinates[1]);
        }
      }
      this.flags.loading = false;
    }, error =>{
      this.setLocation([long, lat], 'Map', 'Unknown', 'Unknown', 'Unknown');
    });
  }

  getCitySuggestions(coordinates) {
    this.locationService.radius([coordinates[1], coordinates[0]]).subscribe((data) => {
      if (
        data['searchResults'] != null &&
        data['searchResults'][0] != null
      ) {
        this.suggestedLocations = _.uniqBy(data['searchResults'], item => item.fields.city);
        this.suggestedLocations.forEach(item => {
          item.name = item['fields'].city;
        });
      }
      else {
        this.user.location.status = 'failed';
      }
    });
  }

  saveLocation(city, state, long, lat) {
    const points = [long, lat];
    this.utils.sound();

    // UPDATE USER LOCATION
    if (this.user.app_state.showSetLocation == 'set-location' || this.flags.saveUserLocation) {
      let location: GeoLocation = new GeoLocation({});
      location.city = city;
      location.state = state;
      location.setLocation(points);

      this.user.location = location;
      this.meService.update({ location: this.user.location }).subscribe();
      this.user.app_state.showSetLocation = false;
      this.user.location.status = 'located';

      this.change.emit(location);
      this.utils.appState.user.next(this.user);

    }
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
