import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UtilsService } from './utils.service';

const httpOptions = {
  //   headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  headers: new HttpHeaders().set(
    'Content-Type',
    'application/x-www-form-urlencoded'
  ),
};

@Injectable({ providedIn: 'root' })
export class ImageService {
  constructor(private http: HttpClient, private utils: UtilsService) {}

  //   list(qp?: Object): Observable<any> {
  //     const url = this.utils.getUrl('rest:post-list');

  //     return this.http
  //       .get<PaginatedList>(url, this.utils.requestOptions(qp))
  //       .pipe(rxOp.map((resp: PaginatedList) => {
  //         resp.results = Post.fromList(resp.results);
  //         return resp.results;
  //       }));
  //   }

  //   get(id: string, qp?: Object): Observable<any> {
  //     const url = this.utils.getUrl('rest:post-detail', id);
  // return this.http.get(url, this.utils.requestOptions());
  //   }

  create(obj: Object, image: File, qp?: Object): Observable<any> {
    const formData = new FormData();
    formData.append('image', image);

    Object.keys(obj).forEach((key) => {
      formData.append(key, obj[key]);
    });

    const url = this.utils.getUrl('rest:images-upload');
    return this.http.post(url, formData);
  }

  delete(id: string, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:images-detail', id);
    return this.http.delete(url, this.utils.requestOptions(qp));
  }

  // ERROR LOGGING
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a StockService message with the MessageService */
  private log(message: string) {
    console.log(message);
  }
}
