import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models';
import { UtilsService } from 'src/app/services';
import { CommentService } from 'src/app/services/comment.service';
import { Post } from 'src/app/models/post';

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss'],
})
export class AddCommentComponent implements OnInit, OnDestroy {
  @Input() post: Post;
  @Output() change: EventEmitter<any>;

  flags = {
    loading: false,
    btnAdding: true,
    sfe: true,
    state: '',
  };
  user: User;
  subs: Subscription[] = [];
  form: FormGroup;

  constructor(
    private commentService: CommentService,
    private utils: UtilsService,
    private formBuilder: FormBuilder
  ) {
    this.change = new EventEmitter();

    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
    });
    this.subs.push(user);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.post = changes.post.currentValue;
  }

  ngOnInit() {
    this.flags.state = 'in';

    this.form = this.formBuilder.group({
      message: ['', [Validators.required]],
    });
  }

  get f() {
    return this.form.controls;
  }

  activateBtn() {
    if (
      this.form.controls.message != null &&
      this.form.controls.message.value != ''
    ) {
      this.flags.btnAdding = false;
    } else {
      this.flags.btnAdding = true;
    }
  }

  // DATA
  addComment() {
    if (this.form.invalid) {
      this.flags.sfe = true;
      return;
    }

    this.utils.sound('send');
    this.flags.btnAdding = true;
    this.flags.state = 'send';
    this.post.comment_count++;
    this.user.metrics.comments_by_me++;
    // console.log(this.post);

    // this.post.last_comment.message = this.f.message.value;
    // this.post.last_comment.user = this.user;
    this.post.setLastMessage(this.f.message.value, this.user);

    let data = {
      content_type: 'post.Post',
      content_id: this.post.id,
      message: this.f.message.value,
    };

    this.commentService.create(data).subscribe((data) => {
      this.user.app_state.addComment = null;
      this.utils.appState.user.next(this.user);
      this.f.message.setValue('');
      this.flags.state = 'in';
    });
    this.form.reset();
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
