<mat-progress-bar mode="indeterminate" *ngIf="flags.loading" [class.iosProgress]="utils.ios()"></mat-progress-bar>
<div [style.background-image]="'url(./assets/img/background/' + bgImg + '.jpg)'" class="backgroundCoverFull backgroundFixed" [@zoomBack]=user?.app_state?.homeBackground>
  <div fxlayout="row" class="homeComponent coverDark" [@fadeBackground]=user?.app_state?.homeCover>


    <button mat-button class="backBtn" [class.ios]="utils.ios()" routerLink="/messages"><mat-icon>arrow_back</mat-icon></button>
    <div class="header profile">
    <div fxlayout="row" class="item" [class.ios]="utils.ios()" [style.background-color]="conversationSelected?.meta?.to?.color + 'c7'" [class.lightMode]="conversationSelected?.meta?.to?.color|isLight">
      <div fxFlex class="text">
        <h2>{{conversationSelected?.meta?.names }}</h2>
        <!-- h4 for multiple names -->
        <p>{{conversationSelected?.last_message|slice:0:25 }}...</p>
        <h6 class="subHeading" [style.color]="conversationSelected?.meta?.to?.color">{{ conversationSelected?.last_activity|timeAgo}} ago &nbsp; 
          <!-- <button mat-button (click)="archieveMessage(!conversationSelected.archived)" class="subHeading" [style.color]="conversationSelected?.meta?.to?.color">
            <mat-icon *ngIf="conversationSelected.archived">unarchive</mat-icon>
            <mat-icon *ngIf="!conversationSelected.archived">inventory</mat-icon>
          </button> -->
        </h6>
      </div>
      <div>
        <!-- <a routerLink="/{{conversationSelected?.meta?.to?.username}}">
          <img [src]="conversationSelected?.meta?.to?.thumbnail?.sm" class="zoomIn" *ngIf="conversationSelected?.meta?.to?.thumbnail?.sm" />
          <div class="noImage" *ngIf="!conversationSelected?.meta?.to?.thumbnail?.sm">{{conversationSelected?.meta?.to?.full_name}}</div>
        </a> -->
        <div class="border" [style.border-color]="conversationSelected?.meta?.to?.color" >
          <button routerLink="/{{conversationSelected?.meta?.to?.username}}" (click)="utils.sound('click-face')" [style.background-image]="getProfileBackground(conversationSelected?.meta?.to)" class="dropLet">
            <mat-icon *ngIf="conversationSelected?.meta?.to?.is_premium && !conversationSelected?.meta?.to?.is_verified" class="premium" [style.color]="conversationSelected?.meta?.to?.color">star</mat-icon>
            <mat-icon *ngIf="conversationSelected?.meta?.to?.is_premium && conversationSelected?.meta?.to?.is_verified" class="premium" [style.color]="conversationSelected?.meta?.to?.color">fingerprint</mat-icon>
            <mat-icon *ngIf="conversationSelected?.meta?.to?.meta?.is_new" class="new" [style.color]="conversationSelected?.meta?.to?.color">fiber_new</mat-icon>              
            <!-- <div class="counter" [class.lightMode]="conversationSelected?.meta?.to?.color|isLight" [style.background-color]="conversationSelected?.meta?.to?.color">{{conversationSelected?.meta?.to?.activities?.length}}</div> -->
          </button>
          <div class="noImage" routerLink="/{{conversationSelected?.meta?.to?.username}}" *ngIf="!conversationSelected?.meta?.to?.thumbnail?.sm" [style.border-color]="conversationSelected?.meta?.to?.color"  [style.color]="conversationSelected?.meta?.to?.color + '80'">{{conversationSelected?.meta?.to?.full_name|slice:0:2}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="conversation profile" id="conversationFlow" (scroll)="onScroll($event)">
    <br clear="all" /><br clear="all" /><br clear="all" /><br clear="all" /><br clear="all" />
    <div *ngFor="let message of messages; first as first">
      <div fxlayout="row" [class.from]="message?.sender?.id != user?.id" class="entry">
        <div *ngIf="message?.sender?.id != user?.id" [routerLink]="'/'+ message?.sender?.username">
          <!-- <div class="name" *ngIf="conversationSelected?.room_type != 'oneToOne'">{{ message?.sender?.full_name }}</div> -->
          <img [src]="message?.sender?.thumbnail?.sm" [style.border]="'solid 2px ' + message?.sender?.color" *ngIf="message?.sender?.thumbnail?.sm" />
          <div class="noImage" *ngIf="!message?.sender?.thumbnail?.sm" [style.color]="conversationSelected?.meta?.to?.color + '80'">{{message?.sender?.full_name|slice:0:2}}</div>
        </div>          
        <div fxFlex>
          <p [style.background]="'linear-gradient(295deg,' + message?.sender?.color + 'eb,' + message?.sender?.color + 'b3)'" [class.lightMode]="message?.sender?.color|isLight" [innerHtml]="message?.message|linkify" (press)="utils.copyText(message?.message)">
            <!-- {{message?.message }} -->
          </p>
          <div *ngIf="first" class="typing" [class.active]="typing">
            <p class="loading"></p>
          </div>
        </div>
      </div>
    </div>
    <br clear="all" /><br clear="all" /><br clear="all" /><br clear="all" /><br clear="all" /><br clear="all" />
    <br clear="all" /><br clear="all" /><br clear="all" />

    <br clear="all" />
  </div>

  <div class="footerNav">
    <div class="messageInput">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div fxLayout="row" fxLayoutAlign="space-evenly start">
      <div fxFlex="5">
      </div>
      <div fxFlex="65">
        <mat-form-field>
          <textarea matInput placeholder="Enter message" autocomplete="off" (click)="startTyping()" (keyup)="activateBtn($event);startTyping()" (change)="activateBtn($event)" type="text" class="form-control" formControlName="message" [ngClass]="{ 'is-invalid': flags.sfe && f.email.invalid }"></textarea>
          <mat-error *ngIf="f.message.hasError('serverError')">
            <small>{{f.message.errors.serverError}}</small>
          </mat-error>
          <mat-error *ngIf="f.message.hasError('message')">
            <small>Invalid Message</small>
          </mat-error>
          <mat-error *ngIf="f.message.hasError('required')">
            <small>Message is required</small>
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="30">
        <button class="sendBtn" [disabled]="flags.btnSubmit" mat-button color="primary">Send!</button>
      </div>
    </div>
    </form>
    </div>  
  </div>  
  




</div>