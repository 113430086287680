import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UtilsService } from '../services/utils.service';


/**
 * Addes token in request headers
 */
@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private utils: UtilsService) {}

  private exclusion_list = [
    { method: 'POST', url: '/auth/registration/' },
    { method: 'POST', url: '/api/v1/auth/facebook/login' },
    { method: 'POST', url: '/api/v1/auth/google/login' },
    { method: 'POST', url: '/auth/login/' },
    { method: 'POST', url: '/auth/password/reset/' },
    { method: 'POST', url: '/api/v1/auth/google-mobile/login' },
    { method: 'GET', url: '/api/v1/activity/' },
    { method: 'GET', url: '/api/v1/activity/group/' },
    { method: 'GET', url: '/api/v1/post/' },
    // '/api/v1/post/',
  ];

  private inclusion_list = [environment.apiUrl];

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const is_included = this.inclusion_list.find((item: string) => {
      return req.url.search(item) >= 0;
    });
    const is_excluded = this.exclusion_list.find((item) => {
      if (req.method == item.method) {
        return req.url.search(item.url) >= 0;
      }
    });

    if (is_included && !is_excluded) {
      const token = this.utils.token;
      if (token) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.utils.token}`,
          },
        });
      }
    }

    return next.handle(req);
  }
}
