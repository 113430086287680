import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as rxOp from 'rxjs/operators';
import { catchError, tap } from 'rxjs/operators';
import { Activity, PaginatedList } from '../models';
import { UtilsService } from './utils.service';
import * as _ from 'lodash';
import { Post } from '../models/post';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class PostService {
  constructor(
    private http: HttpClient,
    private utils: UtilsService,
  ) { }

  list(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:post-list');

    return this.http
      .get<PaginatedList>(url, this.utils.requestOptions(qp))
      .pipe(rxOp.map((resp: PaginatedList) => {
        return resp;
        // resp.results = Post.fromList(resp.results);
        // return resp.results;
      }));
  }

  get(id: string, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:post-detail', id);
    return this.http.get(url, this.utils.requestOptions(qp))
      .pipe(rxOp.map((resp: Post) => {
        return new Post(resp);
      }));
  }

  getBySlug(username: string, slug: string, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:post-retrieve-by-slug', username, slug);
    return this.http.get(url, this.utils.requestOptions(qp))
      .pipe(rxOp.map((resp: Post) => {
        return new Post(resp);
      }));
  }

  delete(id: number, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:post-detail', id);
    return this.http.delete(url, this.utils.requestOptions(qp));
  }

  create(obj: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:post-list');
    return this.http.post(url, obj, this.utils.requestOptions(qp));
  }

  update(id: number, obj: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:post-detail', id);
    return this.http
      .patch(url, obj, this.utils.requestOptions(qp));
  }

  pay(id: number, obj:Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:post-checkout', id);
    console.log(url);
    return this.http.post(url, obj, this.utils.requestOptions(qp));
  }




  // ERROR LOGGING
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a StockService message with the MessageService */
  private log(message: string) {
    console.log(message);
  }

}
