import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../services/utils.service';
import { inOutAnimation } from 'src/app/animations';
import { ActivityService } from 'src/app/services/activity.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-welcome',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
  animations: [inOutAnimation],
})
export class RedirectComponent implements OnInit {

  returnUrl: string;
  flags = {
    loading: false,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public utils: UtilsService,
  ) {

    if(['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)){
      window.location.href = 'https://apps.apple.com/us/app/activity8/id1537925959';
    }
    else{
      window.location.href = 'https://play.google.com/store/apps/details?id=com.activity8.app&hl=en_IE&gl=US';
    }


  }

  ngOnInit() {
  }

}
