import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../models/user';
import { NotificationService } from '../../services/notification.service';
import { UtilsService } from '../../services/utils.service';

export interface DialogData {
  notification: any;
  create: boolean;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currentUser: User;
  now: number;
  today: Date = new Date();

  constructor(
    private utils: UtilsService,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    this.utils.appState.user.subscribe((resp) => {
      this.currentUser = resp;
    });
  }

  ngOnInit() { }
}
