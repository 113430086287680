<!-- <div class="items animate__animated animate__fadeIn" *ngIf="inputLocation != null">
    
    <div class="item map">
      <div class="btns">
        <button mat-button class="switchBtn"><mat-icon>map</mat-icon> Location</button>
        <button mat-button class="closeBtn" (click)="close()"><mat-icon>close</mat-icon></button>
      </div>
      <br clear="all" /> -->
      
      <agm-map (mapClick)="mapClick($event)" class="backgroundCoverFull"
        [latitude]="mapLat" [longitude]="mapLong" [zoom]="16" [streetViewControl]="false" [mapTypeControl]="false" 
        [fullscreenControl]="false" [clickableIcons]="false" markerClickable="true">

        <agm-marker [latitude]="mapLat" [longitude]="mapLong">
        <agm-info-window [disableAutoPan]="true" isOpen="true">
          <div class="infoWindow">
            <h2><span *ngIf="inputLocation?.place != null">{{ inputLocation?.place }}</span></h2>
            <span *ngIf="inputLocation?.city != null">{{ inputLocation?.city }}, </span>
            <span *ngIf="inputLocation?.state != null">{{ inputLocation?.state }}</span>
            <a class="mat-button" [href]="'https://www.google.com/maps/search/?api=1&query='+ inputLocation.location.coordinates[1] +','+ inputLocation.location.coordinates[0]" target="_blank">
              <mat-icon>directions</mat-icon> Open Google
            </a>

          </div>
        </agm-info-window>

      </agm-marker>
        <!-- <agm-marker [latitude]="mapLat" [longitude]="mapLong" [iconUrl]="'https://activity8.app/assets/icons/icon-128x128.png'"></agm-marker> -->
      </agm-map>

    <!-- </div>


</div> -->