<mat-progress-bar mode="indeterminate" *ngIf="flags.loading" [class.iosProgress]="utils.ios()"></mat-progress-bar>
<app-add-conversation></app-add-conversation>
<div [style.background-image]="'url(./assets/img/background/' + bgImg + '.jpg)'" class="backgroundCoverFull backgroundFixed">
  <div fxlayout="row" class="homeComponent coverDark" [class.ios]="utils.ios()" [@fadeBackground]=user?.app_state?.homeCover>

    <div class="profile">
      <br />
      <button mat-button class="addBtn" (click)="clickAddConversation()">
        <mat-icon *ngIf="!user?.app_state?.startConversation">add</mat-icon>
        <mat-icon *ngIf="user?.app_state?.startConversation">close</mat-icon>
      </button>
      <h1>Messages</h1>

        <br />
      <div class="item" *ngFor="let conversation of conversations" [style.background-color]="conversation?.meta?.to?.color + '85'"
      [class.unread]="conversation?.unread_count > 0">
        <div fxlayout="row" (click)="selectConversation(conversation)" [routerLink]="'/messages/'+ conversation.id">
          <div class="left">
              <!-- <img [src]="conversation?.meta?.to?.thumbnail?.sm" class="zoomIn" *ngIf="conversation?.meta?.to?.thumbnail?.sm" />
              <div class="noImage" *ngIf="!conversation?.meta?.to?.thumbnail?.sm">{{conversation?.meta?.to?.full_name}}</div> -->
          
              <div class="border" [style.border-color]="conversation?.meta?.to?.color" >
                <button  (click)="utils.sound('click-face')" [style.background-image]="getProfileBackground(conversation?.meta?.to)" *ngIf="conversation?.meta?.to?.thumbnail?.sm">
                  <mat-icon *ngIf="conversation?.meta?.to?.is_premium && !conversation?.meta?.to?.is_verified" class="premium" [style.color]="conversation?.meta?.to?.color">star</mat-icon>
                  <mat-icon *ngIf="conversation?.meta?.to?.is_premium && conversation?.meta?.to?.is_verified" class="premium" [style.color]="conversation?.meta?.to?.color">fingerprint</mat-icon>
                  <mat-icon *ngIf="conversation?.meta?.to?.meta?.is_new" class="new" [style.color]="conversation?.meta?.to?.color">fiber_new</mat-icon>              
                  <div class="counter" [class.lightMode]="conversation?.meta?.to?.color|isLight" [style.background-color]="conversation?.meta?.to?.color" *ngIf="conversation?.unread_count > 0">{{conversation?.unread_count}}</div>
                  <!-- <div class="counter" [class.lightMode]="conversation?.meta?.to?.color|isLight" [style.background-color]="conversation?.meta?.to?.color">{{conversation?.meta?.to?.activities?.length}}</div> -->
                </button>
                <div class="noImage" *ngIf="!conversation?.meta?.to?.thumbnail?.sm" [style.border-color]="conversation?.meta?.to?.color"  [style.color]="conversation?.meta?.to?.color + '80'">{{conversation?.meta?.to?.full_name|slice:0:2}}</div>
              </div>
                
          </div>
          <div fxFlex>
            <h2 *ngIf="conversation?.meta?.names != ''">{{conversation?.meta?.names }}</h2>
            <h2 *ngIf="conversation?.meta?.names == ''">Mystery</h2>
            <p>{{conversation?.last_message|slice:0:25 }}...</p>
            <h6>{{ conversation?.last_activity|timeAgo}} ago</h6>
          </div>
        </div>
      </div>

      <!-- <div class="archived">
        <a (click)="flags.archived=true;loadConversations()" *ngIf="flags.archived==false">Archived Conversations</a>
        <a (click)="flags.archived=false;loadConversations()" *ngIf="flags.archived==true">Back to Conversations</a>
      </div> -->

      <div class="item" *ngIf="flags.noResultsConversations && conversations != null && conversations?.length <= 0" (click)="clickAddConversation()">
        <div fxlayout="row">
          <div fxFlex class="noMessages">
            <br />
            <h2>No messages, yet!</h2>
            <p>create a new one?</p>
            <br />
          </div>
        </div>

      </div>

    </div>



  </div>
</div>