import { Injectable, NgModule } from '@angular/core';
import {
  BrowserModule,
  HammerGestureConfig,
  HammerModule,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  MetaLoader,
  MetaModule,
  MetaStaticLoader,
  PageTitlePositioning,
} from '@ngx-meta/core';
import { SocialLoginModule } from 'angularx-social-login';
import * as Hammer from 'hammerjs';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { AlertComponent } from './directives';
import { AuthGuard } from './guards';
import { httpInterceptorProviders } from './helpers/httpInterceptors';
import { MessagesComponent } from './messages/messages.component';
import { MessageDetailComponent } from './messages/message-detail/message-detail.component';
import { AlertService, AuthenticationService, UserService } from './services';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { SOCIAL_AUTH_CONFIG } from './social.auth';
import { UtilsModule } from './utils/utils.module';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // swipe: { direction: Hammer.DIRECTION_ALL },
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL  },
    doubletap: { enable: true },
    pinch: { enable: false },
    rotate: { enable: false },
    pan: { enable: false },
  };
}

export function metaFactory(): MetaLoader {
  return new MetaStaticLoader({
    pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
    pageTitleSeparator: ' - ',
    applicationName: 'activity8',
    defaults: {
      title: 'Ready . Play . Earn!',
      description: 'I thought you may like this on activity8 app 🤍 ',
      'og:image':
      'https://activity8.app/assets/img/activity8-logo.png',
      'og:type': 'website',
      'og:locale': 'en_US',
    },
  });
}

@NgModule({
  declarations: [AppComponent, MessagesComponent, MessageDetailComponent, AlertComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    BrowserAnimationsModule,
    RouterModule,
    HammerModule,
    ComponentsModule,
    UtilsModule,
    SocialLoginModule,
    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: metaFactory,
    }),
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    GoogleAnalyticsService,
    AuthGuard,
    AlertService,
    AuthenticationService,
    UserService,
    httpInterceptorProviders,
    { provide: 'SocialAuthServiceConfig', useValue: SOCIAL_AUTH_CONFIG },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
