import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { UtilsService, UserService } from 'src/app/services';
import { User } from 'src/app/models';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { InteractionService } from 'src/app/services/interaction.service';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-interaction',
  templateUrl: './interaction.component.html',
  styleUrls: ['./interaction.component.scss']
})
export class InteractionComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  user: User;
  interactions: any[] = [];
  bgImages = ['2', '3', '4', '5', '6', '7'];
  bgImg = this.bgImages[Math.floor(Math.random() * this.bgImages.length)];
  flags = {
    sfe: false, // Show Form Errors
    loading: false,
    noResults: true,
    gotOffestZero: false,
  };

  loadLength: number = 40;
  resultOffset: number = 0;
  resultTotal: number = 0;

  constructor(
    public utils: UtilsService,
    private interactionService: InteractionService,
    private userService: UserService,
  ) {

    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
      if (!this.flags.gotOffestZero || this.user.app_state.locationFilter) {
        this.interactions = [];
        this.flags.noResults = false;
        this.resultOffset = 0;
        this.resultTotal = 0;
        this.getInteractions();
      }
    });
    this.subs.push(user);


  }

  ngOnInit(): void {
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight / 1.2) {
      // you're at the bottom of the page
      if (!this.flags.loading && !this.flags.noResults) {
        this.loadMore();
      }
    }

  };

  loadMore() {
    this.resultOffset += this.loadLength;
    this.getInteractions();
  }

  getInteractions() {
    this.flags.gotOffestZero = true;
    this.flags.loading = true;

    let filters: any = {};
    filters.offset = this.resultOffset;
    filters.limit = this.loadLength;
    filters.expand = 'action_object';

    this.interactionService.list(filters).subscribe(data => {
      this.resultTotal = data.count;

      this.interactions = this.interactions.concat(data.results);
      this.flags.loading = false;

      if (data.results.length == 0) {
        this.flags.noResults = true;
      }
      // METRICS
      if (this.user.metrics.unread_interactions > 0) {
        this.user.metrics.unread_interactions = 0;
        this.utils.appState.user.next(this.user);
        this.userService.updateMetrics(this.user.id, { unread_interactions: 0 }).subscribe();
      }
    });
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }

}
