<!-- <div class="items"> -->
<div class="items animate__animated" *ngIf="user?.app_state?.showSetActivities" [class.animate__fadeInDown]="flags.animation=='start'" [class.animate__fadeOutUp]="flags.animation=='end'">
  <div class="height90">
    <div class="item">
      <div class="question" *ngIf="mode != 'interest'">
        <mat-icon>accessibility_new</mat-icon><br />
        Activities
      </div>
      <div class="question" *ngIf="mode == 'interest'">
        <mat-icon>spa</mat-icon><br />
        Interests
      </div>
      <br />  


      <form [formGroup]="form" (ngSubmit)="clickAddActivities()">
        <mat-form-field class="example-chip-list">
          <mat-chip-list #chipList aria-label="Activities">
            <mat-chip
              *ngFor="let activity of activities"
              [class.accentPill]="activity?.activity?.is_premium"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(activity)">
              {{activity?.activity?.name}}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
              [placeholder]="placeholder"
              #activityInput
              [formControl]="activityCtrl"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="add($event)">
          </mat-chip-list>
          <!-- <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let activity of filteredActivities | async" [value]="activity">
              {{activity}}
            </mat-option>
          </mat-autocomplete> -->
          
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selected($event)">
            <mat-option *ngFor="let activity of filteredActivities | async" [value]="activity" [class.accentPill]="activity?.is_premium">
              {{activity?.name}}
            </mat-option>
          </mat-autocomplete>

        </mat-form-field>
        <button mat-button mat-flat-button (click)="addFromButton(activityCtrl)" class="addBtn" [disabled]="activityCtrl?.value == null || activityCtrl?.value == '' || activityCtrl?.value?.length <= 0"><mat-icon>keyboard_return</mat-icon></button>
        <br />

        <ng-container *ngIf="popularActivities?.length > 0 && activities.length < 8">
          <h4>or select from our Popular activities...</h4>
          <button *ngFor="let activity of popularActivities|slice:0:20" mat-button type="button" class="pill" [class.accentPill]="activity?.is_premium" (click)="clickActivityPill(activity)">
            {{ activity?.name }} <span *ngIf="activity?.is_premium">*</span>
          </button>
        </ng-container>
        
        <div class="text-center" *ngIf="activities.length >= 8">
          <h2>Excellent!</h2>
          <br />
          <p *ngIf="this.mode != 'interest'">You have 8 activities, you can also have 8 interests!</p>
          <p *ngIf="this.mode == 'interest'">You have 8 interests, you can also have 8 activities!</p>
        </div>

        <br clear="all" />
      </form>
      <br />
    </div>
  </div>
  <button [disabled]="flags?.btnAddingActivities" mat-flat-button color="primary" (click)="clickDone()" class="item height10">
    <mat-icon>arrow_forward</mat-icon>
  </button>

</div>