import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models';
import { BackgroundAnimations, inOutAnimation } from '../animations';
import { Conversation } from '../models/conversation';
import { MessageService } from '../services/message.service';
import { UtilsService } from '../services/utils.service';
import { UserService } from '../services';
import { Message } from '../models/message';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  animations: [BackgroundAnimations, inOutAnimation],
})
export class MessagesComponent implements OnInit, OnDestroy {
  form: FormGroup;
  user: User;
  subs: Subscription[] = [];
  flags = {
    sfe: false, // Show Form Errors
    loading: false,
    btnSubmit: false,
    noResults: false,
    noResultsConversations: false,
    archived: false,
  };
  bgImages = ['2', '3', '4', '5', '6', '7'];
  bgImg = this.bgImages[Math.floor(Math.random() * this.bgImages.length)];
  typing: boolean = false;

  conversations: Conversation[] = [];
  messages: Message[] = [];
  conversationid: number;
  addIcon: string = 'add';

  loadLength: number = 20;
  resultOffset: number = 0;
  resultTotal: number = 0;

  resultOffsetConversations: number = 0;
  resultTotalConversations: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    public utils: UtilsService,
    private messageService: MessageService,
    private userService: UserService,
  ) {
    this.route.params.subscribe(
      (params) => {
        this.conversationid = params['id'];
      }
    );

    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
      this.loadConversations();
    });
    this.subs.push(user);

    this.form = this.formBuilder.group({
      // message: ['', Validators.required],
      message: [''],
    });
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });
  }

  getProfileBackground(profile){
    if(profile != null && profile.thumbnail != null && profile.thumbnail.sm){
      return 'url('+ profile.thumbnail.sm +')';
    }
    else{
      return 'none';
    }  
  }
 
  @HostListener("window:scroll", [])
  onScrollConversations(): void {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight / 1.3) {
      // you're at the bottom of the page
      if (!this.flags.loading && !this.flags.noResultsConversations) {
        this.loadMoreConversations();
      }
    }
  }

  loadMoreConversations() {
    this.resultOffsetConversations += this.loadLength;
    this.getConversations();
  }

  loadConversations() {
    this.conversations = [];
    this.resultTotalConversations = 0
    this.resultOffsetConversations = 0;
    this.resultOffset= 0;
    this.resultTotal = 0;
    this.getConversations();
  }

  get f() {
    return this.form.controls;
  }

  startTyping() {
    this.typing = true;
    setTimeout(() => (this.typing = false), 3000);
  }

  clickAddConversation() {
    this.user.app_state.startConversation == null
      ? (this.user.app_state.startConversation = true)
      : (this.user.app_state.startConversation = null);
    this.addIcon == 'add' ? (this.addIcon = 'close') : (this.addIcon = 'add');
  }

  selectConversation(conversation) {
    conversation.archived = this.flags.archived;
    this.utils.appState.conversation.next(conversation);
  }

  activateBtn(event) {
    if (!this.form.invalid) {
      this.flags.btnSubmit = false;
    } else {
      this.flags.btnSubmit = true;
    }
  }

  randomColor() {
    return (
      'rgb(' +
      Math.floor(Math.random() * 225) +
      ',' +
      Math.floor(Math.random() * 225) +
      ',' +
      Math.floor(Math.random() * 225) +
      ', 0.2)'
    );
  }

  randomColorSolid() {
    return (
      'rgb(' +
      Math.floor(Math.random() * 225) +
      ',' +
      Math.floor(Math.random() * 225) +
      ',' +
      Math.floor(Math.random() * 225) +
      ', 1)'
    );
  }

  // DATA
  getConversations() {
    this.flags.loading = true;

    if (this.resultOffset >= this.resultTotal && this.resultOffset != 0) {
      this.flags.noResults = true;
    }

    let filters: any = {};
    filters.offset = this.resultOffsetConversations;
    filters.limit = this.loadLength;
    filters.expand = 'members';
    // filters.archived = this.flags.archived;

    this.messageService.listConversations(filters).subscribe((data) => {
      this.resultTotalConversations = data.count;

      let conversations = Conversation.fromList(data.results);

      let totalUnreadCount = 0;
      _.each(conversations, (conversation) => {
        let members = [];
        conversation.meta = {};
        conversation.members.forEach((member) => {
          if (this.user.id != member.id) {
            members.push(member.full_name);
            // GOOD FOR oneToOne room_type
            conversation.meta['to'] = member;
          }
        });
        conversation.meta['names'] = members.join(', ');

        if (conversation.unread_count != null && conversation.unread_count > 0) {
          totalUnreadCount++;
        }
      });

      if (data.results.length == 0) {
        this.flags.noResultsConversations = true;
      }
      this.conversations = this.conversations.concat(Conversation.fromList(conversations));
      this.flags.loading = false;


      // RESET MESSAGE COUNT
      if (this.user.metrics.unread_message != 0 && totalUnreadCount == 0) {
        this.user.metrics.unread_message = 0;
        this.utils.appState.user.next(this.user);

        this.userService.updateMetrics(this.user.id, { unread_message: 0 }).subscribe();
      }
      this.flags.loading = false;
    });
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
