import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UtilsService, CurrentUserService } from 'src/app/services';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit, OnDestroy {
  @Output() change: EventEmitter<any>;

  user: User;
  subs: Subscription[] = [];
  colors: string[] = [];
  setColor: boolean = false;

  constructor(
    public utils: UtilsService,
    private meService: CurrentUserService
  ) {
    this.change = new EventEmitter();

    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
    });
    this.subs.push(user);
  }

  ngOnInit() {
    this.getColors();
    this.setColor = true;
  }

  getColors() {
    this.colors = [];
    for (let i = 0; i < 30; i++) {
      this.colors.push(this.randomColor());
    }
  }

  randomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  clickColor(color) {
    this.utils.sound('next');
    this.user.color = color;
    this.meService.update({ color: color }).subscribe((r) => {
      this.utils.appState.user.next(this.user);
    });
    // this.change.emit(color);
    this.change.emit(false);
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
