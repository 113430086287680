import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models';
import { UtilsService } from 'src/app/services';
import * as _ from 'lodash';
import { Capacitor } from '@capacitor/core';
import { timeStamp } from 'console';

@Component({
  selector: 'app-search-base',
  templateUrl: './search-base.component.html',
  styleUrls: ['./search-base.component.scss'],
})
export class SearchBaseComponent implements OnInit, OnDestroy {
  searchStr: string = null;
  display: string = 'activities';
  form: FormGroup;
  flags = {
    sfe: false, // Show Form Errors
    loading: false,
    btnSubmit: true,
    loginBox: 'in',
    backgroundAlign: 'right',
    showSettings: false,
    userCount: null,
    postCount: null,
    activityCount: null,
  };
  user: User;
  subs: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    public utils: UtilsService,
  ) {
    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
      //FORCE CHANGE FOR RE-SEARCH WHEN USER HADE CHANGED SETTINGS
      if(this.form != null){
        this.form.setValue({searchStr:this.searchStr === '' ? ' ': ''});
      }

      if (this.user.app_state.search_myactivities || this.user.app_state.search_location) {
        this.flags.showSettings = true;
      }
      if(this.router.url.includes('/members') && this.user.app_state.search_last_activity){
        this.flags.showSettings = true;
      }
      if(this.router.url.includes('/posts') && this.user.app_state.search_upcoming){
        this.flags.showSettings = true;
      }

    });
    this.subs.push(user);
// console.log(this.route);
    this.route.params.subscribe(
      (params) => {
        if(params['searchStr'] != null){
          this.searchStr = params['searchStr'].toLowerCase();
        }
      }
    );

    this.form = this.formBuilder.group({
      searchStr: [this.searchStr],
    });

    if (this.router.url.includes('/activities')) {
      this.display = 'activities';
    }
    else if (this.router.url.includes('/members')) {
      this.display = 'users';
    }
    else {
      this.display = 'posts';
    }
  }

  ngOnInit() { 
  }

  clearSearchInput(){
    this.f.searchStr.setValue('');
  }

  userCount(v){
    console.log(v);
  }

  get f() {
    return this.form.controls;
  }

  activateBtn(event) {
    if (!this.form.invalid) {
      this.flags.btnSubmit = false;
    } else {
      this.flags.btnSubmit = true;
    }
  }

  onEnter(event){
    if (event.key === "Enter") {
      this.onSubmit();
    }    
  }

  onSubmit() {
    this.utils.sound();
    this.searchStr = this.f.searchStr.value;
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }

}
