<mat-progress-bar mode="indeterminate" *ngIf="flags.loading" [class.iosProgress]="utils.ios()"></mat-progress-bar>
<div [style.background-image]="'url(./assets/img/background/' + bgImg + '.jpg)'" class="backgroundCoverFull backgroundFixed">
  <div fxlayout="row" class="interactionsComponent coverDark" [class.ios]="utils.ios()">
    <br />
    <h1>Recent</h1>

      <br />
  
    <div *ngIf="interactions?.length == 0 && flags.noResults" class="empty animate__animated animate__zoomInUp">
      <p>No interactions to display yet...</p>
      <h3>Love is an act of surrender to another person.</h3>
      <p><a href="https://www.youtube.com/watch?v=3Qptyt0-S9U" target="_blank">- Alan Watts</a></p>
    </div>

    <div *ngFor="let interaction of interactions" class="interaction" [class.lightMode]="!(interaction?.actor?.color|isLight)" [style.background]="'linear-gradient(45deg, '+interaction?.actor?.color + '3b'+', #00000082)'">
      <ng-container *ngIf="interaction.actor.id != user.id">
        <div [routerLink]="'/' + interaction?.actor?.username" class="profile">
          <img [src]="interaction?.actor?.thumbnail?.sm" [style.border]="'solid 2px ' + interaction?.actor?.color" *ngIf="interaction?.actor?.thumbnail?.sm" />
          <div class="noImage" *ngIf="!interaction?.actor?.thumbnail?.sm" [style.border]="'solid 1px ' + interaction?.actor?.color" [style.color]="interaction?.actor?.color"><span *ngIf="interaction?.actor?.full_name != ''">{{interaction?.actor?.full_name|slice:0:2}}</span><span *ngIf="interaction?.actor?.full_name == ''">My</span></div>
          <div class="name"><a [routerLink]="'/'+interaction?.actor?.username"><span *ngIf="interaction?.actor?.full_name != ''">{{ interaction?.actor?.full_name }}</span><span *ngIf="interaction?.actor?.full_name == ''">Mystrey</span></a></div>
        </div>

        <!-- {{ interaction?.verb }} -->
        <span *ngIf="interaction.verb == 'kudos' && interaction.target.object_type == 'accounts.User'"> gave you Kudos. 🤘</span>
        <span *ngIf="interaction.verb == 'likes' && interaction.target.object_type == 'accounts.User'"> likes your <a [routerLink]="'/'+user?.username">profile</a></span>
        <span *ngIf="interaction.verb == 'likes' && interaction.target.object_type == 'post.Post'"> likes your <a [routerLink]="'/'+user?.username+'/'+interaction.target?.id">post</a></span>
        <span *ngIf="interaction.verb == 'wrote a comment'"> commented on your <a [routerLink]="'/'+user?.username+'/'+ interaction.target?.id">post</a></span>
        
        <div class="time"> {{ interaction?.timestamp|timeAgo}} ago</div>
      </ng-container>
    </div>


    <mat-progress-bar mode="indeterminate" class="loadMore" *ngIf="flags.loading && resultOffset > 0"></mat-progress-bar>
  </div>
</div>
