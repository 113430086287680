import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: '[tc-server-error]',
  templateUrl: './server-error.component.html',
  // template: `
  //   <ng-container *ngFor="let error of field.getError('serverError')">{{
  //     error
  //   }}</ng-container>
  // `,
})
export class ServerErrorComponent implements OnInit {
  @Input()
  field: FormControl;
  constructor() { }

  ngOnInit() { }
}
