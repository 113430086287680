<div class="items animate__animated" *ngIf="user.app_state.showSetExperience != 'out'" [class.animate__backInRight]="flags.animation=='start'" [class.animate__backOutLeft]="flags.animation=='end'">
<!-- <div class="items animate__animated animate__backInRight" *ngIf="activity != null"> -->
  <div class="height90">
    <div class="item">
      <div class="question">
        <mat-icon>signal_cellular_alt</mat-icon><br />
        {{activity?.activity?.name}} experience
      </div>
      <br />
      <br />
    </div>
    <div class="item experience">
      <div>
        <mat-icon class="schoolIcon" *ngIf="activity?.experience == 1 && activity?.will_teach">school</mat-icon>
        <mat-icon *ngIf="activity?.experience >= 1" (click)="set(1)" [style.color]="user?.color">star</mat-icon>
        <mat-icon *ngIf="activity?.experience == 0" (click)="set(1)">star_border</mat-icon>
        <p>Noob</p>
      </div>
      <div>
        <mat-icon class="schoolIcon" *ngIf="activity?.experience == 2 && activity?.will_teach">school</mat-icon>
        <mat-icon *ngIf="activity?.experience >= 2" (click)="set(2)" [style.color]="user?.color">star</mat-icon>
        <mat-icon *ngIf="activity?.experience <= 1" (click)="set(2)">star_border</mat-icon>
        <p>Good</p>
      </div>
      <div>
        <mat-icon class="schoolIcon" *ngIf="activity?.experience == 3 && activity?.will_teach">school</mat-icon>
        <mat-icon *ngIf="activity?.experience >= 3" (click)="set(3)" [style.color]="user?.color">star</mat-icon>
        <mat-icon *ngIf="activity?.experience <= 2" (click)="set(3)">star_border</mat-icon>
        <p>Legend</p>
      </div>
      <div>
        <mat-icon class="schoolIcon" *ngIf="activity?.experience == 4 && activity?.will_teach">school</mat-icon>
        <mat-icon *ngIf="activity?.experience >= 4" (click)="set(4)" [style.color]="user?.color">star</mat-icon>
        <mat-icon *ngIf="activity?.experience <= 3" (click)="set(4)">star_border</mat-icon>
        <p>Guide</p>
      </div>
      <br />
      <br />
    </div>
    <div class="item checkboxes">
      <mat-checkbox [checked]="activity?.will_teach" (change)="activity.will_teach=!activity.will_teach"><i>I am willing to Share, Teach & Earn</i></mat-checkbox><br />
    </div>
    <div class="item checkboxes">
      <mat-checkbox [checked]="activity?.has_certification" (change)="activity.has_certification=!activity.has_certification"><i>I am a certified Teacher</i></mat-checkbox>
      <mat-icon class="certIcon" *ngIf="activity?.has_certification">badge</mat-icon>
    </div>
  </div>
  <button [disabled]="!flags?.btnDone" mat-flat-button color="primary" (click)="clickDone()" class="item height10">
    <mat-icon>arrow_forward</mat-icon>
  </button>

</div>