<!-- <mat-toolbar class="navtoolbar" [class.ios]="utils.ios()" fxlayout="row" *ngIf="user && user?.activities && user?.activities.length > 0 && user?.color != null"> -->

<div class="addmenu-container animate__animated" (click)="toggleMenu()" [class.animate__slideInUp]="flags.showMenu" [class.animate__slideOutDown]="flags.showMenu==false" [class.hidden]="flags.showMenu==null">
  <div class="addmenu" [style.background]="'linear-gradient(0deg, #111, '+user?.color+')'">
    <button mat-button (click)="showPostBox('planning')" routerLinkActive="active">
      <mat-icon>add</mat-icon>          
    </button>
    <button mat-button (click)="showPostBox('getacoach')" routerLinkActive="active">
      <mat-icon>emoji_people</mat-icon>          
    </button>
    <button mat-button (click)="showPostBox('coaching')" routerLinkActive="active">
      <mat-icon>group</mat-icon>
    </button>
 
  </div>
</div>


<div  class="navtoolbar" *ngIf="user != null && !url?.includes('messages/') && !url?.includes('/about') && url != '/'" [class.iosHomeBar]="utils.ios() && capacitor()">
  <button mat-button routerLink="/dashboard" onclick="window.scrollTo(0,0)" routerLinkActive="active" (click)="closeMenu();utils.sound('home')" *ngIf="!url?.includes('settings') && !url?.includes('contact') && url != '/dashboard/invites'">
    <mat-animated-icon start="home" end="home" colorStart="none" colorEnd="none" [animate]="flags.animate_icon"></mat-animated-icon>
  </button>
  <button mat-button routerLink="/dashboard" onclick="window.scrollTo(0,0)" (click)="back();closeMenu();utils.sound('home')" *ngIf="url?.includes('settings') || url?.includes('contact') || url == '/dashboard/invites'">
    <mat-animated-icon start="arrow_back" end="arrow_back" colorStart="none" colorEnd="none" [animate]="flags.animate_icon"></mat-animated-icon>
  </button>

  <button mat-button routerLink="/search" onclick="window.scrollTo(0,0)" (click)="closeMenu();utils.sound()" routerLinkActive="active">
    <mat-animated-icon start="search" end="search" colorStart="none" colorEnd="none" [animate]="flags.animate_icon"></mat-animated-icon>          
  </button>
  <div (click)="utils.sound('toggle');toggleMenu();" class="logoIcon" [class.on]="flags.showMenu">
      <img src="./assets/icons/icon-512x512.png">
  </div>
  <button mat-button routerLink="/messages" onclick="window.scrollTo(0,0)" (click)="closeMenu();utils.sound()" routerLinkActive="active">
    <div class="counter" *ngIf="user?.metrics?.unread_message > 0">{{ user?.metrics?.unread_message }}</div>
    <mat-animated-icon start="message" end="message" colorStart="none" colorEnd="none" [animate]="flags.animate_icon"></mat-animated-icon>          
  </button>

  <button mat-button routerLink="/recent" onclick="window.scrollTo(0,0)" class="last" (click)="closeMenu();utils.sound()" routerLinkActive="active" *ngIf="user?.metrics?.unread_interactions > 0">
    <div class="counter" *ngIf="user?.metrics?.unread_interactions > 0">{{ user?.metrics?.unread_interactions }}</div>          
    <mat-animated-icon start="favorite" end="favorite" colorStart="none" colorEnd="none" [animate]="flags.animate_icon"></mat-animated-icon>          
  </button>
  
  <!-- <button mat-button [routerLink]="'/' + user?.username" onclick="window.scrollTo(0,0)" class="last" (click)="closeMenu();utils.sound()" routerLinkActive="active" *ngIf="user?.metrics?.unread_interactions <= 0">
    <mat-animated-icon start="face" end="face" colorStart="none" colorEnd="none" [animate]="flags.animate_icon"></mat-animated-icon>          
  </button>   -->


  <button mat-button class="profile" *ngIf="user?.metrics?.unread_interactions <= 0"routerLink="/{{user?.username}}" (click)="closeMenu();utils.sound('click-face')" [style.color]="user?.color">
    <div class="border" [style.border-color]="user?.color">
      <div class="noImage" *ngIf="getProfileBackground(user) == 'none'">
        <span *ngIf="user?.first_name!=''">{{user?.first_name}}</span>
        <span *ngIf="user?.first_name==''">:(</span>
      </div>
      <div class="image" [style.background-image]="flags.userImg">
        <mat-icon *ngIf="user?.is_premium && !user?.is_verified" class="premium" [style.color]="user?.color">star</mat-icon>
        <mat-icon *ngIf="user?.is_premium && user?.is_verified" class="premium" [style.color]="user?.color">fingerprint</mat-icon>
        <mat-icon *ngIf="user?.meta?.is_new" class="new">fiber_new</mat-icon>
        <!-- <div class="counter" [class.lightMode]="user?.color|isLight" [style.background-color]="user?.color">{{user?.activities?.length}}</div> -->
      </div>
    </div>
  </button>


</div>

<div  class="navtoolbar" *ngIf="user == null && url != '/' && !url?.includes('/join')  && !url?.includes('/login') && !url?.includes('/forgot') && !url?.includes('/about')">
  <button mat-button (click)="utils.toggleSidenav()" *ngIf="url == '/search' || url == '/search/posts' || url == '/search/activities' || url == '/search/members'">
    <mat-animated-icon start="menu" end="close" colorStart="none" colorEnd="none" [animate]="flags.animate_icon"></mat-animated-icon>
  </button>

  <button mat-button routerLink="/"  (click)="utils.sound()">
    <mat-animated-icon start="login" end="home" colorStart="none" colorEnd="none" [animate]="flags.animate_icon"></mat-animated-icon>          
  </button>
</div>