<div class="items animate__animated" *ngIf="user.app_state.showSetDays != 'out'" [class.animate__backInRight]="flags.animation=='start'" [class.animate__backOutLeft]="flags.animation=='end'">
<!-- <div class="items animate__animated animate__backInRight" *ngIf="activity != null"> -->
  <div class="height90">
    <div class="item">
      <!-- <button mat-button class="closeBtn" (click)="user.app_state.setDays = 'out'"><mat-icon>close</mat-icon></button> -->
      <div class="question">
        <mat-icon>event_available</mat-icon><br />
        {{activity?.activity?.name}} Routine
      </div>
  </div>
  <br />
  <br />
  <div class="item">
    <p>Which days do you like to {{activity?.activity?.name}}?</p>
  </div>
  <div class="item days">
      <div class="checkboxes">
        <mat-checkbox [(ngModel)]="weekdays" [checked]="weekdays" [(indeterminate)]="weekdaysIncomplete" (change)="toggleWeekdays()">Weekdays</mat-checkbox><br />
        <br />
        <mat-checkbox [(ngModel)]="mon" [checked]="activity?.days?.includes('Mon')" (change)="enableBtn()">Monday</mat-checkbox><br />
        <mat-checkbox [(ngModel)]="tues" [checked]="activity?.days?.includes('Tues')" value='Tues' (change)="enableBtn()">Tuesday</mat-checkbox><br />
        <mat-checkbox [(ngModel)]="wed" value='Wed' (change)="enableBtn()">Wednesday</mat-checkbox><br />
        <mat-checkbox [(ngModel)]="thurs" value='Thurs' (change)="enableBtn()">Thursday</mat-checkbox><br />
        <mat-checkbox [(ngModel)]="fri" value='Fri' (change)="enableBtn()">Friday</mat-checkbox><br />
      </div>
      <div class="checkboxes">
        <mat-checkbox [(ngModel)]="weekends" [(indeterminate)]="weekendsIncomplete" (change)="toggleWeekends()">Weekends</mat-checkbox><br />
        <br />
        <mat-checkbox [(ngModel)]="sat" value='Sat' (change)="enableBtn()">Saturday</mat-checkbox><br />
        <mat-checkbox [(ngModel)]="sun" value='Sun' (change)="enableBtn()">Sunday</mat-checkbox>
      </div>
    </div>
  </div>
  <button [disabled]="flags.btnDone" mat-button color="primary" class="item height10" (click)="save()">
    <mat-icon>arrow_forward</mat-icon>
  </button>
</div>
