import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { AlertService, UtilsService } from '../services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '../models';

@Component({
  selector: 'alert',
  templateUrl: 'alert.component.html',
})
export class AlertComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  subs: Subscription[] = [];
  message: any;
  user: User;
  animation: String = 'start';

  constructor(
    private alertService: AlertService,
    public utils: UtilsService,
    public snackBar: MatSnackBar
  ) { 
    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
    });
    this.subs.push(user);
  }

  ngOnInit() {

    const subscription = this.alertService.getMessage().subscribe((message) => {
      this.message = message;
      this.animation = 'start';
      this.utils.delay(11800).then(() => {
        if(this.animation != 'close'){
          this.animation = 'end';
        }
      });
  
      if (message) {
        let btnName = 'close';
        if (message.type == 'error') {
          btnName = 'Error';
        }

        // this.snackBar.open(message.text, 'close', {
        //   duration: message.duration,
        //   // duration: 999999999999999,
        //   panelClass: message.type,
        //   verticalPosition: 'top',
        // });
      }
    });
    this.subs.push(subscription);

  }


  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
