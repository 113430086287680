import {
  trigger,
  state,
  style,
  transition,
  animate,
  group,
  query,
  stagger,
  keyframes,
} from '@angular/animations';

export const routerTransition = trigger('routerTransition', [
  transition('profile => *', [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' }), {
      optional: true,
    }),
    group([
      query(
        ':enter',
        [
          style({ transform: 'translateX(-100%)' }),
          animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
        ],
        { optional: true }
      ),
      query(
        ':leave',
        [
          style({ transform: 'translateX(0%)' }),
          animate('0.5s ease-in-out', style({ transform: 'translateX(100%)' })),
        ],
        { optional: true }
      ),
    ]),
  ]),

  // transition('* <=> *', [
  //   /* order */
  //   /* 1 */ query(
  //     ':enter, :leave',
  //     style({ position: 'fixed', width: '100%' }),
  //     { optional: true }
  //   ),
  //   /* 2 */ group([
  //     // block executes in parallel
  //     query(
  //       ':enter',
  //       [
  //         style({ transform: 'translateX(100%)' }),
  //         animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' })),
  //       ],
  //       { optional: true }
  //     ),
  //     query(
  //       ':leave',
  //       [
  //         style({ transform: 'translateX(0%)' }),
  //         animate(
  //           '0.5s ease-in-out',
  //           style({ transform: 'translateX(-100%)' })
  //         ),
  //       ],
  //       { optional: true }
  //     ),
  //   ]),
  // ]),
]);

export const openCloseTransition = trigger('openCloseTransition', [
  transition('* <=> *', [
    /* order */
    /* 1 */ query(':enter, :leave', style({ display: 'none', opacity: '0' }), {
    optional: true,
  }),
    /* 2 */ group([
    // block executes in parallel
    query(
      ':enter',
      [
        style({ transform: 'translateX(100%)' }),
        animate(
          '0.5s ease-in-out',
          style({ display: 'block', opacity: '1' })
        ),
      ],
      { optional: true }
    ),
    query(
      ':leave',
      [
        style({ transform: 'translateX(0%)' }),
        animate('0.5s ease-in-out', style({ display: 'none', opacity: '0' })),
      ],
      { optional: true }
    ),
  ]),
  ]),
]);

export const fadeInOut = [
  trigger('hideShowAnimator', [
    state('in', style({ opacity: 1 })),
    state('out', style({ opacity: 0 })),
    transition('0 => 1', animate('.5s')),
    transition('1 => 0', animate('.9s')),
  ]),
];

export const inOutAnimation = [
  trigger('slideInOut', [
    state(
      'in',
      style({
        opacity: '1',
        visibility: 'visible',
        // left: '0px',
      })
    ),
    state(
      'outRight',
      style({
        // opacity: '0', visibility: 'hidden',
        transform: 'translateX(100%)',
      })
    ),
    state(
      'outLeft',
      style({
        // opacity: '0', visibility: 'hidden',
        transform: 'translateX(-100%)',
      })
    ),
    transition('in => outRight', [
      group([
        animate(
          '300ms ease-in-out',
          style({
            // opacity: '0', visibility: 'hidden',
            transform: 'translateX(100%)',
          })
        ),
      ]),
    ]),
    transition('outRight => in', [
      group([
        animate(
          '300ms ease-in-out',
          style({
            opacity: '1',
            visibility: 'visible',
            transform: 'translateX(0%)',
          })
        ),
      ]),
    ]),
    transition('in => outLeft', [
      group([
        animate(
          '300ms ease-in-out',
          style({
            opacity: '1',
            visibility: 'visible',
            transform: 'translateX(-100%)',
          })
        ),
      ]),
    ]),
    transition('outLeft => in', [
      group([
        animate(
          '300ms ease-in-out',
          style({
            opacity: '1',
            visibility: 'visible',
            transform: 'translateX(0%)',
          })
        ),
      ]),
    ]),
  ]),

  trigger('fadeInOut', [
    state(
      'in',
      style({
        opacity: '1',
        visibility: 'visible',
      })
    ),
    state(
      'out',
      style({
        opacity: '0',
        visibility: 'hidden',
      })
    ),
    transition('in => out', [
      group([
        animate(
          '700ms ease-in-out',
          style({
            opacity: '0',
            visibility: 'hidden',
          })
        ),
      ]),
    ]),
    transition('out => in', [
      group([
        animate(
          '700ms ease-in-out',
          style({
            opacity: '1',
            visibility: 'visible',
          })
        ),
      ]),
    ]),
  ]),

  trigger('zoomInOut', [
    state(
      'in',
      style({
        transform: 'scale(1)',
        opacity: '1',
      })
    ),
    state(
      'out',
      style({
        transform: 'scale(0.1)',
        opacity: '0',
      })
    ),
    transition('in => out', [
      group([
        animate(
          '700ms ease-in-out',
          style({
            transform: 'scale(0.1)',
            opacity: '0',
          })
        ),
      ]),
    ]),
    transition('out => in', [
      group([
        animate(
          '700ms ease-in-out',
          style({
            transform: 'scale(1)',
            opacity: '1',
          })
        ),
      ]),
    ]),
  ]),
];

export const BackgroundAnimations = [
  trigger('alignBackground', [
    state(
      'left',
      style({
        'background-position': 'left',
        transition: 'background-position  .700s ease-in',
      })
    ),
    state(
      'right',
      style({
        'background-position': 'right',
        transition: 'background-position  .700s ease-in',
      })
    ),
    transition('left => right', [
      group([
        animate(
          '700ms ease-in-out',
          style({
            'background-position': 'right',
          })
        ),
      ]),
    ]),
    transition('right => left', [
      group([
        animate(
          '700ms ease-in-out',
          style({
            'background-position': 'left',
          })
        ),
      ]),
    ]),
  ]),

  trigger('fadeBackground', [
    state(
      'in',
      style({
        'background-color': 'rgba(0, 0, 0, 0.4)',
      })
    ),
    state(
      'out',
      style({
        'background-color': 'rgba(0, 0, 0, 0)',
      })
    ),
    transition('in => out', [
      group([
        animate(
          '700ms ease-in-out',
          style({
            'background-color': 'rgba(0, 0, 0, 0)',
            transition: 'background-color .1700s linear',
          })
        ),
      ]),
    ]),
    transition('out => in', [
      group([
        animate(
          '1700ms ease-in-out',
          style({
            'background-color': 'rgba(0, 0, 0, 0.4)',
            transition: 'background-color .1700s linear',
          })
        ),
      ]),
    ]),
  ]),

  trigger('zoomBack', [
    state(
      'on',
      style({
        filter: 'blur(7px)',
        transform: 'scale(1.5)',
      })
    ),
    state(
      'off',
      style({
        filter: 'blur(0px)',
        transform: 'scale(1)',
      })
    ),
    transition('on => off', [
      group([
        animate(
          '700ms ease-in-out',
          style({
            filter: 'blur(0px)',
            transform: 'scale(1)',
          })
        ),
      ]),
    ]),
    transition('off => on', [
      group([
        animate(
          '1700ms ease-in-out',
          style({
            filter: 'blur(7px)',
            transform: 'scale(1.5)',
          })
        ),
      ]),
    ]),
  ]),
];
