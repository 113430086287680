import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { AppComponent } from '../../app.component';
import { User } from '../../models/user';
import { AppStateService } from '../../services/app-state.service';
import { UtilsService } from '../../services/utils.service';
import { Subscription } from 'rxjs';
import { BillingService } from 'src/app/services/billing.service';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'a8-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  today: Date = new Date();
  flags = {
    sidenav: true,
    userImg: 'none',
  };
  balanceText: string = '-';
  balance: number;
  subs: Subscription[] = [];
  user: User;

  constructor(
    public utils: UtilsService,
    private app: AppComponent,
    private router: Router,
    private billingService: BillingService,
  ) {
    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
      this.flags.userImg = this.getProfileBackground(this.user);
      this.getBalance();
    });
    this.subs.push(user);


    this.utils.appState.sidenav.subscribe((resp) => {
      this.flags.sidenav = resp;
    });

    if (!_.isEmpty(this.user)) {
      // DO SOMETHING
    }
  }

  ngOnInit() { }

  getBalance(){
    if(this.user != null){
      this.billingService.getBalance().subscribe(data => {
        this.balanceText = '$' + data.balance.toFixed(2);
        this.balance = data.balance;
      });
    }
  }

  claim(){    
    if(!this.user.has_bank_account){
      this.billingService.addBankAccount().subscribe(data => {
        window.open(data.account_link, '_self');
      });
      return;
    }

    this.billingService.claimBalance({amount:this.balance}).subscribe(data => {
      this.balanceText = '-';
      this.utils.alert.success('Money transferred to your stripe account.');
      this.getBalance();
    });
  }

  openExternal(link) {
    this.router.ngOnDestroy();
    window.open(link, '_blank');
  }

  clickLogin(){
    this.router.navigateByUrl('/login?returnUrl=' + this.router.url);
    this.closeSidenav();
  }

  closeSidenav(): void {
    // this.utils.sound('click-back');
    this.app.closeSideNav();

    // this.flags.sidenav = !this.flags.sidenav;
    this.utils.appState.sidenav.next(false);
  }

  getProfileBackground(profile){
    if(profile != null && profile.profile_pics != null && profile.profile_pics.photos && profile.profile_pics.photos.length > 0){
      return 'url('+ profile.profile_pics.photos[profile.profile_pics.photos.length-1].image.sm +')';
    }
    else{
      return 'none';
    }  
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }

}
