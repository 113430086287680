import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { AlertService } from './alert.service';
import { AppStateService } from './app-state.service';
// import { EventBrokerService } from 'ng-event-broker';
import { Router } from '@angular/router';
import { User } from '../models';
import { Capacitor } from '@capacitor/core';
import * as moment from 'moment';

declare var DjUrls: any;

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  user: User;
  flags = {
    sidenav: false,
  };

  private TOKEN_KEY = 'authToken';
  private _token: string;
  constructor(
    public alert: AlertService,
    public snackBar: MatSnackBar,
    public appState: AppStateService,
    public router: Router,
  ) {

    this.appState.user.subscribe((resp) => {
      this.user = resp;
    });

    this.appState.sidenav.subscribe((resp) => {
      this.flags.sidenav = resp;
    });

  }

  public getUrl(name: string, ...args: any[]) {
    // if (_.isEmpty(DjUrls[name])) {
      // CHECK IF ONLINE
      // https://www.npmjs.com/package/ng-connection-service
    // }
    try{
      const url = DjUrls[name].apply(this, args);
      return url;
    }
    catch{}

  }

  removeToken() {
    localStorage.removeItem(this.TOKEN_KEY);
    this._token = null;
  }

  set token(token: string) {
    this._token = token;
    // localStorage.setItem('isSameSite', 'Lax');
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  get token() {
    if (_.isEmpty(this._token)) {
      this._token = localStorage.getItem(this.TOKEN_KEY);
    }
    return this._token;
  }

  public showServerErrorsInForm(error: HttpErrorResponse, form: FormGroup) {
    if (_.has(error.error, 'non_field_errors')) {
      this.alert.error(error.error.non_field_errors);
    } else {
      this.alert.error('Please check form errors');
      _.each(_.keys(error.error), (key) => {
        if (form.contains(key)) {
          form.controls[key].setErrors({ serverError: error.error[key] });
        }
      });
    }
  }

  public requestOptions(qp?: any, responseType?) {
    const httpOptions = {};
    if (qp) {
      httpOptions['params'] = new HttpParams({ fromObject: qp });
    }
    return httpOptions;
  }

  public ios(){
    return Capacitor.isNativePlatform() && ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod', 'MacPPC', 'Mac68K', 'Pike v7.6 release 92', 'Pike v7.8 release 517'].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  }

  public android(){
    return Capacitor.isNativePlatform() && (navigator.platform.includes('Linux') || navigator.platform.includes('Android'));
  }

  public isNoon(date){
    if(date.includes('T20:00:00Z')){
      return true;
    }
  }

  delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  humanDate(date){
    return moment(date).calendar(null,{
      lastDay : '[Yesterday]',
      sameDay : '[Today]',
      nextDay : '[Tomorrow]',
      lastWeek : '[last] dddd',
      nextWeek : 'ddd',
      sameElse : 'ddd'
    });
  }

  daysDiff(date){
    var today = moment(new Date);
    var postdate = moment(date);
    return postdate.diff(today, 'days');
    // today.startOf('day')
    // postdate.endOf('day')
  }

  copyText(text){
    this.sound('next');
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (text));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    this.alert.success('Copied to clipboard');
  }

  public sound(sound?) {
    if (this.user && this.user.settings && !this.user.settings.disable_sounds) {

      if (sound == null) {
        sound = "click";
      }

      let audio = new Audio();
      audio.src = "./assets/audio/" + sound + ".mp3";

      audio.load();
      audio.play();
    }
  }

  toggleSidenav(): void {
    this.sound();    
    this.flags.sidenav = !this.flags.sidenav;
    this.appState.sidenav.next(this.flags.sidenav);
  }

}
