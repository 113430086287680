<!-- <div fxLayout="row" fxLayoutAlign="center center" fxFlex class="createPostComponent" [@slideInOut]=user?.app_state?.createPostBox> -->
  <mat-progress-bar mode="indeterminate" class="loginProgress" *ngIf="flags.loading" [class.iosProgress]="utils.ios()"></mat-progress-bar>

  <div class="items animate__animated" [class.animate__zoomInUp]="!submitted"  [class.animate__backOutUp]="submitted">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="item height90">
      <button mat-button (click)="emitClose()" class="close"><mat-icon>close</mat-icon></button>

      <h1>Invite {{profile.first_name}} <mat-icon>date_range</mat-icon></h1>

        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="60">
            <mat-form-field>
              <mat-select placeholder="Activity" (selectionChange)="activity=$event.value" formControlName="activity" [value]="activity">
                <!-- <mat-option value=''>Select</mat-option> -->
                <!-- <mat-option *ngFor="let activities of activities" [value]="activities['activity']" [class.green]="youHaveActivity(activity.activity)">
                  {{activities['activity'].name}}
                </mat-option> -->

                <mat-optgroup label="Theirs">
                  <mat-option *ngFor="let activities of profile.activities" [value]="activities['activity']">
                    {{activities['activity'].name}}
                  </mat-option>
                </mat-optgroup>

                <mat-optgroup label="Yours">
                  <mat-option *ngFor="let activities of user.activities" [value]="activities['activity']">
                    {{activities['activity'].name}}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="35">
            <mat-form-field>
              <mat-select placeholder="Duration" formControlName="duration">
                <mat-option *ngFor="let duration of durations" [value]="duration.value">
                  {{duration.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="60">
            <mat-form-field>
              <mat-label>Date</mat-label>
              <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" (keyup)="activateBtn()" (change)="activateBtn()" type="text" formControlName="date" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.date.errors }">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker [startAt]="startDate"></mat-datepicker>
              <mat-error *ngIf="f.date.hasError('serverError')">
                <div>{{f.date.errors.serverError}}</div>
              </mat-error>
              <mat-error *ngIf="f.date.hasError('birthday')">
                <div>Invalid entry.</div>
              </mat-error>
              <mat-error *ngIf="f.date.hasError('required')">
                <div>Required field.</div>
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="35">
            <mat-form-field>
              <mat-select placeholder="Time" formControlName="time">
                <mat-option *ngFor="let time of times" [value]="time">
                  {{time}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="60">
            <mat-form-field>
              <input matInput placeholder="Location" type="text" autocomplete="off" (keyup)="activateBtn()" (click)="setLocation()" (change)="activateBtn()" class="form-control" formControlName="locationDisplay" [ngClass]="{ 'is-invalid': flags.sfe && f.locationDisplay.invalid }">
              <!-- <input matInput placeholder="Location" type="text" (keyup)="activateBtn()" (change)="activateBtn()" class="form-control" formControlName="location" [ngClass]="{ 'is-invalid': flags.sfe && f.location.invalid }"> -->
              <mat-icon matSuffix>location_on</mat-icon>
              <mat-error *ngIf="f.locationDisplay.hasError('serverError')">
                <small>{{f.locationDisplay.errors.serverError}}</small>
              </mat-error>
              <mat-error *ngIf="f.locationDisplay.hasError('location')">
                <small>Invalid Location</small>
              </mat-error>
              <mat-error *ngIf="f.locationDisplay.hasError('required')">
                <small>Location is required</small>
              </mat-error>
            </mat-form-field>
          </div>

        </div>

        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="60">
            <mat-form-field>
              <input matInput placeholder="Description" type="text" class="form-control" formControlName="description" [ngClass]="{ 'is-invalid': flags.sfe && f.description.invalid }">
              <mat-error *ngIf="f.description.hasError('serverError')">
                <small>{{f.description.errors.serverError}}</small>
              </mat-error>
            </mat-form-field>
          </div>

        </div>


    </div>
    <button [disabled]="flags?.btnSubmit"mat-flat-button color="primary" class="item height10">
      <mat-icon>arrow_forward</mat-icon>
    </button>
    
    <!-- <button [disabled]="flags.btnSubmit" mat-flat-button color="primary" class="item">Send Invite</button> -->
  </form>


  </div>

