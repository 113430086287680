import { Injectable } from '@angular/core';
import * as rx from 'rxjs';
import * as rxOp from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpHeaders,
  HttpClient,
} from '@angular/common/http';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { UtilsService } from './utils.service';
import { User } from '../models/user';
import { catchError, tap } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

export class AnyonmusUser extends User {
  get isAuthenticated() {
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  subs: Subscription[] = [];
  private _user: User;
  private _userObservable: rx.Observable<User>;
  constructor(
    private http: HttpClient,
    private router: Router,
    private utils: UtilsService
  ) {}

  set currentOrgId(id) {
    localStorage.setItem('currentOrgId', id);
  }

  // get current org id from localstore
  get currentOrgId() {
    return localStorage.getItem('currentOrgId');
  }

  isAuth(
    state?: RouterStateSnapshot,
    noRedirect: Boolean = false
  ): Promise<boolean> {
    const prom = new Promise((resolve, reject) => {
      this.user.subscribe((user) => {
        if (!user.isAuthenticated) {
          console.log('user not auth');
          if (!noRedirect) {
            let params = state?.url ? { returnUrl: state.url } : {};
            this.router.navigate(['/login'], { queryParams: params });
          }
        }
        resolve(user.isAuthenticated);
      });
    });
    return <Promise<boolean>>prom;
  }

  // hasPerm(perm:string){
  //   return this._user.permissions.indexOf(perm) >= 0;
  // }

  public get user(): rx.Observable<User> {
    const ovservable = null;
    if (this._user) {
      return rx.of(this._user);
    } else if (this._userObservable) {
      return this._userObservable;
    } else {
      return this.fetchUser();
    }
  }

  // public serverHeaders() {
  //   return { 'Authorization': 'Token ' + this.token };
  // }

  // private requestOptions() {
  //   const httpOptions = {
  //     headers: new HttpHeaders(this.serverHeaders())
  //   };
  //   return httpOptions;
  // }

  private fetchUser() {
    const url = this.utils.getUrl('rest_user_details');
    let qp = this.utils.requestOptions({
      expand: 'activities,profile_pics,metrics,settings',
    });
    return this.http
      .get(url, qp)
      .pipe(
        rxOp.map((resp) => {
          this._user = new User(resp);
          this._user.meta = {};
          this._user.meta.refresh = false;
          this.utils.appState.user.next(this._user);
          this.utils.appState.createUiState(this._user.app_state);
          return this._user;
        })
      )
      .pipe(rxOp.shareReplay(1))
      .pipe(
        rxOp.catchError((error: HttpErrorResponse) => {
          return rx.of(new AnyonmusUser({}));
        })
      );
  }

  public refreshUser() {
    return this.fetchUser();
  }

  update(obj: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest_user_details');
    return this.http.patch(url, obj, this.utils.requestOptions(qp));
  }

  updateCompany(id: number, obj: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('companies-detail', id);
    return this.http
      .patch(url, obj, this.utils.requestOptions(qp))
      .pipe(
        rxOp.map((resp) => {
          return new User(resp);
        })
      )
      .pipe(
        tap((obj1: User) => this.log(`updated companyuser id=${obj1.id}`)),
        catchError(this.handleError<any>('update company'))
      );
  }

  updatePassword(obj: Object): Observable<any> {
    const url = this.utils.getUrl('rest_password_change');
    return this.http.post(url, obj);
  }

  updateEmail(obj: Object): Observable<any> {
    const url = this.utils.getUrl('change_email');
    return this.http.post(url, obj);
  }

  confirmOTP(obj: Object): Observable<any> {
    const url = this.utils.getUrl('change_email_confirm');
    return this.http.post(url, obj);
  }

  // userout:AnyonmusUser;
  logout() {
    this.http.post(this.utils.getUrl('rest_logout'), {}).subscribe(
      (resp) => {
        this.utils.removeToken();
        this.currentOrgId = null;
        localStorage.removeItem('currentOrgId');
        window.location.assign('index.html');
        // window.location.reload();
      },
      (error) => {
        window.location.assign('/');
      }
    );
  }

  delete(id: number): Observable<any> {
    const url = this.utils.getUrl('rest:users-detail', id);
    console.log(url);
    return this.http.delete(url).pipe(
      rxOp.map((resp) => {
        this.utils.removeToken();
        this.currentOrgId = null;
        localStorage.removeItem('currentOrgId');
        window.location.replace('/');
        return resp;
      })
    );
  }

  currentPlan(): Observable<any> {
    // const url = this.utils.getUrl('user-subscription-plans-current-plan');
    const url = this.utils.getUrl(
      'subscription-braintree-current-subscription'
    );

    return this.http.get(url);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a StockService message with the MessageService */
  private log(message: string) {
    console.log(message);
  }
}
