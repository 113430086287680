import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilsService } from './utils.service';
import { HttpClient } from '@angular/common/http';
import { PaginatedList } from '../models';
import * as rxOp from 'rxjs/operators';
import { Conversation } from '../models/conversation';
import { Message } from '../models/message';

@Injectable({ providedIn: 'root' })


export class CommentService {
  constructor(private http: HttpClient, private utils: UtilsService) {}

  create(obj: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:comments-list');
    return this.http.post(url, obj, this.utils.requestOptions(qp));
  }

  listComments(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:comments-list');
    return this.http
      .get<PaginatedList>(url, this.utils.requestOptions(qp))
      .pipe(
        rxOp.map((resp: PaginatedList) => {
          resp.results = Message.fromList(resp.results);
          return resp.results;
        })
      );
  }

  delete(id: number): Observable<any> {
    const url = this.utils.getUrl('rest:comments-detail', id);
    return this.http.delete(url);
  }
}
