import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilsService } from './utils.service';
import { HttpClient } from '@angular/common/http';
import { PaginatedList } from '../models';
import * as rxOp from 'rxjs/operators';
import { Conversation } from '../models/conversation';
import { Message } from '../models/message';

@Injectable({ providedIn: 'root' })
export class MessageService {
  constructor(private http: HttpClient, private utils: UtilsService) { }

  // messages: string[] = [];

  // add(message: string) {
  //   this.messages.push(message);
  // }

  // clear() {
  //   this.messages = [];
  // }

  create(obj: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:chats-list');
    return this.http.post(url, obj, this.utils.requestOptions(qp));
  }

  getConversation(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:chats-list');
    console.log(url);
    return this.http
      .get<PaginatedList>(url, this.utils.requestOptions(qp))
      .pipe(
        rxOp.map((resp: PaginatedList) => {
          // resp.results = Conversation.fromList(resp.results);
          return resp;
        })
      );
  }

  listConversations(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:chats-list');
    return this.http
      .get<PaginatedList>(url, this.utils.requestOptions(qp))
      .pipe(
        rxOp.map((resp: PaginatedList) => {
          // resp.results = Conversation.fromList(resp.results);
          return resp;
        })
      );
  }

  listMessages(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:messages-list');
    return this.http
      .get<PaginatedList>(url, this.utils.requestOptions(qp))
      .pipe(
        rxOp.map((resp: PaginatedList) => {
          // resp.results = Message.fromList(resp.results);
          return resp;
        })
      );
  }

  respond(obj?: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:messages-list');
    return this.http.post(url, obj, this.utils.requestOptions(qp));
  }

  update(id: number, obj?: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:messages-detail', id);
    return this.http.patch(url, obj, this.utils.requestOptions(qp));
  }

  archieve(id: number, obj?: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:chats-archive', id);
    return this.http.post(url, obj, this.utils.requestOptions(qp));
  }
}
