export const environment = {
  production: true,
  apiUrl: 'https://dev-apis.activity8.com/',
  // apiUrl: 'https://apis.activity8.com/',
  appUrl: 'https://dev.activity8.com/',
  termsChanged: new Date('9/20/2020'),
  VERSION: require('../../package.json').version,
  firebase: {
    // projectId: '<your-project-id>',
    // messagingSenderId: '<your-messaging-sender-id>'
    apiKey: 'AIzaSyDMSMZcCd23EUlwAyFsMQMzkUrB3NkZWGc',
    authDomain: 'dev.activity8.com',
    databaseURL: 'activity8-001.com',
    storageBucket: 'activity8-001.appspot.com',
  },
  stripePublishableKey:
    'pk_test_51GuMY8BkhxZ8Z7zgHQue3BEzV6JMjqd2YQ0Lq8RNLJ5zCjsDFal9rRFDkhYqNzKj9E1sd32bUUBkBEOslc2AYHd7008Oiuaics',
  vapid_public_key:
    'BGQY6yRJcU-BAJqWBWtoiJsHs0BUIs3z14fJEbFLPxvdatDn5FFyIxCC2NsWMCOhk7SQZhWlObRyS9fgVm3Ws9Y',
  googleAppId:
    '130705535822-npj9co8qt3gft600g6cnu8n1p0q6i0mu.apps.googleusercontent.com',
  facebookAppId: '91128126112',
};
