// import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    HttpErrorResponse,
    HttpHeaders,
    HttpClient,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as rxOp from 'rxjs/operators';
import { Activity, PaginatedList } from '../models';
import { UtilsService } from './utils.service';
import * as _ from 'lodash';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({ providedIn: 'root' })
export class InteractionService {
    constructor(private http: HttpClient, private utils: UtilsService) { }

    list(qp?: Object): Observable<any> {
        const url = this.utils.getUrl('rest:activity-log-list');
        return this.http
            .get<PaginatedList>(url, this.utils.requestOptions(qp))
            .pipe(
                rxOp.map((resp: PaginatedList) => {
                    // resp.results = Activity.fromList(resp.results);
                    return resp;
                })
            );
    }

    // get(id: string, qp?: Object): Observable<any> {
    //   const url = this.utils.getUrl('rest:activity-detail', id);
    //   return this.http.get(url, this.utils.requestOptions());
    // }

    // ERROR LOGGING
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            // console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    /** Log a StockService message with the MessageService */
    private log(message: string) {
        console.log(message);
    }
}
