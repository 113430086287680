<div class="items animate__animated" [class.animate__zoomInDown]="flags?.state == 'in'" [class.animate__backOutUp]="flags?.state == 'send'" *ngIf="user?.app_state?.startConversation != null">
  <div class="item">
    <button mat-button class="closeBtn" (click)="user.app_state.startConversation = null" *ngIf="to"><mat-icon>close</mat-icon></button>

    <div class="question">
      <mat-icon>message</mat-icon><br />
      New Message
    </div>
    <br />


    <form [formGroup]="form" (ngSubmit)="clickAddPill()">
      <mat-form-field class="example-chip-list">
        <mat-chip-list #chipList aria-label="Recipients">
          <mat-chip
            *ngFor="let recipient of recipients"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(recipient)">
            {{recipient?.full_name}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="Enter recipient/s"
            #recipientInput
            [formControl]="recipientCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selected($event)">
          <mat-option *ngFor="let recipient of filteredRecipients | async" [value]="recipient">
            {{recipient?.full_name}}
          </mat-option>
        </mat-autocomplete>

      </mat-form-field>

      <!-- <p *ngIf="popularRecipients?.length > 0">Near by</p>
      <button *ngFor="let recipient of popularRecipients|slice:0:10" mat-button type="button" class="pill" (click)="clickAddPill(recipient)">
        {{ recipient?.full_name }}
      </button> -->

      <br clear="all" />
    </form>
  </div>

  <div class="item">

    <ng-container *ngIf="recipients.length > 0">
      <br />
      <form [formGroup]="form2" (ngSubmit)="sendMessage()">
      <mat-form-field>
        <textarea matInput placeholder="Message" (keyup)="activateBtn()" (change)="activateBtn()" type="text" class="form-control" formControlName="message" [ngClass]="{ 'is-invalid': flags.sfe && f2.invalid }">
        </textarea>
        <mat-error *ngIf="f2.message.hasError('serverError')">
          <small>{{f2.message.errors.serverError}}</small>
        </mat-error>
        <mat-error *ngIf="f2.message.hasError('message')">
          <small>Message contains unallowed words.</small>
        </mat-error>
      </mat-form-field>
      </form>
    </ng-container>
  </div>

  <button *ngIf="recipients.length > 0" [disabled]="flags?.btnAdding" mat-button color="primary" class="item" (click)="sendMessage()">
    SEND <mat-icon>send</mat-icon>
  </button>

</div>
