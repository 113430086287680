import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import { environment } from 'src/environments/environment';

const fbLoginOptions = {
  // scope: 'user_gender,user_friends,public_profile,user_birthday,email',
  scope: 'public_profile,email,user_birthday',
  version:  'v10.0',
  return_scopes: true,
  enable_profile_selector: true
  //pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages
}; 

export const SOCIAL_AUTH_CONFIG = {
  autoLogin: false,
  providers: [
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(environment.googleAppId),

    },
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider(environment.facebookAppId, fbLoginOptions),
      // provider: new FacebookLoginProvider(environment.facebookAppId),
    },
  ],
} as SocialAuthServiceConfig;
