export class Image {
  id: number;
  image?: string;

  constructor(data: Object) {
    Object.assign(this, data);
  }
}

export class ImageSnippet {
  pending: boolean = false;
  status: string = 'init';

  constructor(public src: string, public file: File) { }
}
