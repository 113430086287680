import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as rxOp from 'rxjs/operators';
import { catchError, tap } from 'rxjs/operators';
import { Activity, PaginatedList } from '../models';
import { UtilsService } from './utils.service';
import * as _ from 'lodash';
import { GeoLocation } from '../models/location';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({ providedIn: 'root' })
export class LocationService {
  constructor(private http: HttpClient, private utils: UtilsService) { }

  //   list(qp?: Object): Observable<any> {
  //     const url = this.utils.getUrl('rest:post-list');

  //     return this.http
  //       .get<PaginatedList>(url, this.utils.requestOptions(qp))
  //       .pipe(rxOp.map((resp: PaginatedList) => {
  //         // resp.results = Post.fromList(resp.results);
  //         return resp.results;
  //       }));
  //   }

  reverse(long: number, lat: number): Observable<any> {
    const key = 'muRTxwhV7vbnSboQKGMw4JWq3cg2cr6l';
    const url =
      'https://www.mapquestapi.com/geocoding/v1/reverse?key=' +
      key +
      '&location=' +
      lat +
      ',' +
      long;
      // + '&includeRoadMetadata=true&includeNearestIntersection=true';
    return this.http.get(url).pipe(
      rxOp.map((resp) => {
        // console.log(resp);
        return resp;
      })
    );
  }

  getCities(searchStr: string, count?: number, qp?: Object): Observable<any> {
    if (count == null) {
      count = 10;
    }
    // OTHER COLECTIONS: poi,address,category,franchise,airport
    const key = 'muRTxwhV7vbnSboQKGMw4JWq3cg2cr6l';
    const url = 'https://www.mapquestapi.com/search/v3/prediction?key=' + key + '&limit=' + count + '&collection=adminArea&q=' + searchStr;

    return this.http.get(url, this.utils.requestOptions(qp)).pipe(
      rxOp.map((resp) => {
        // console.log(resp);
        return resp;
      })
    );
  }

  // getNearBy(long: number, lat: number, count?: number, qp?: Object): Observable<any> {
  getNearBy(coordinates: any, searchStr?: string, count?: number, qp?: Object): Observable<any> {
    if (count == null) {
      count = 10;
    }
    let key = 'muRTxwhV7vbnSboQKGMw4JWq3cg2cr6l';
    // const category = '&hostedData=mqap.ntpois|group_sic_code=?|962107';
    const category = '';
    const url = "https://www.mapquestapi.com/search/v4/place?key=" + key + '&location=' + coordinates + '&sort=distance&feedback=false&q=' + searchStr + category;
    // const url ='https://www.mapquestapi.com/search/v2/search?key=' + key + '&maxMatches=' + count +'&shapePoints=' +coordinates[1] +',' +coordinates[0];

    return this.http
      .get<PaginatedList>(url, this.utils.requestOptions(qp))
      .pipe(rxOp.map((resp: PaginatedList) => {
        resp.results = GeoLocation.fromList(resp.results);
        // console.log(resp);
        return resp.results;
      }));
  }

  radius(coordinates: any, distance?: number, count?: number, qp?: Object): Observable<any> {
    if (distance == null) {
      distance = 100;
    }
    if (count == null) {
      count = 30;
    }
    const category = '&hostedData=mqap.ntpois|group_sic_code=?|962107';
    // const category = '';
    const key = 'muRTxwhV7vbnSboQKGMw4JWq3cg2cr6l';
    const url = 'https://www.mapquestapi.com/search/v2/radius?key=' + key + '&origin=' + coordinates + '&radius=' + distance + '&maxMatches=' + count + '&ambiguities=ignore' + category;
    return this.http.get(url, this.utils.requestOptions(qp)).pipe(
      rxOp.map((resp) => {
        return resp;
      })
    );
  }

  ipInfo(ip: string, qp?: Object): Observable<any> {
    const key = 'muRTxwhV7vbnSboQKGMw4JWq3cg2cr6l';
    const url =
      'http://api.ipstack.com/' +
      ip +
      '?access_key=1c5b10f389531cbbc1c5700fa0a87c36';
    return this.http.get(url, this.utils.requestOptions(qp)).pipe(
      rxOp.map((resp) => {
        // console.log(resp);
        return resp;
      })
    );
  }

  getIp(): Observable<any> {
    // const url = 'http://api.ipify.org/?format=json';
    const url =
      'https://geo.ipify.org/api/v1?apiKey=at_sH6cMaNVbZNdyjSqQ6bz0M4qchOUT';
    return this.http.get(url).pipe(
      rxOp.map((resp) => {
        return resp;
      })
    );
  }

  //   delete(id: string, qp?: Object): Observable<any> {
  //     const url = this.utils.getUrl('rest:post-detail', id);
  //     return this.http.delete(url, this.utils.requestOptions(qp));
  //   }

  //   create(obj: Object, qp?: Object): Observable<any> {
  //     const url = this.utils.getUrl('rest:post-list');
  //     return this.http
  //       .post(url, obj, this.utils.requestOptions(qp));
  //   }

  //   update(id: number, obj: Object, qp?: Object): Observable<any> {
  //     const url = this.utils.getUrl('rest:post-detail', id);
  //     return this.http
  //       .patch(url, obj, this.utils.requestOptions(qp));
  //   }

  // ERROR LOGGING
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a StockService message with the MessageService */
  private log(message: string) {
    console.log(message);
  }
}
