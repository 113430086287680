import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as rxOp from 'rxjs/operators';
import { catchError, tap } from 'rxjs/operators';
import { Activity, PaginatedList } from '../models';
import { UtilsService } from './utils.service';
import * as _ from 'lodash';
import { Post } from '../models/post';
import { Invite } from '../models/invite';
import * as moment from 'moment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({ providedIn: 'root' })
export class InviteService {
  constructor(private http: HttpClient, private utils: UtilsService) {
    const today = moment();
    this.current_week = {
      start: today.clone().startOf('week'),
      end: today.clone().endOf('week'),
    };
    this.last_week = {
      start: this.current_week.start.clone().subtract(7, 'days'),
      end: this.current_week.end.clone().subtract(7, 'days'),
    };
    this.next_week = {
      start: this.current_week.start.clone().add(7, 'days'),
      end: this.current_week.end.clone().add(7, 'days'),
    };
  }

  list(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:invites-list');
    return this.http
      .get<PaginatedList>(url, this.utils.requestOptions(qp))
      .pipe(
        rxOp.map((resp: PaginatedList) => {
          resp.results = Invite.fromList(resp.results);
          return resp.results;
        })
      );
  }

  get(id: string, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:post-detail', id);
    return this.http.get(url, this.utils.requestOptions(qp));
  }

  delete(id: string, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:post-detail', id);
    return this.http.delete(url, this.utils.requestOptions(qp));
  }

  create(obj: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:invites-list');
    return this.http.post(url, obj, this.utils.requestOptions(qp));
  }

  respond(id: number, obj?: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:invites-respond', id);
    return this.http.post(url, obj, this.utils.requestOptions(qp));
  }

  review(id: number, obj?: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:invites-review', id);
    return this.http.post(url, obj, this.utils.requestOptions(qp));
  }

  reject(id: number, obj?: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:invites-reject', id);
    return this.http.post(url, obj, this.utils.requestOptions(qp));
  }

  update(id: number, obj: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:post-detail', id);
    return this.http.patch(url, obj, this.utils.requestOptions(qp));
  }

  current_week: { [key: string]: moment.Moment };
  last_week: { [k in 'start' | 'end']: moment.Moment };
  next_week: { [k in 'start' | 'end']: moment.Moment };

  calendar(data: Invite[]) {
    // const mapped_data = this.transform_data();
    const grouped = this.group(data);
    const dataset = {};
    dataset['last_week'] = this.week_view(
      grouped.last_week,
      this.last_week.start.clone()
    );
    dataset['current_week'] = this.week_view(
      grouped.current_week,
      this.current_week.start.clone()
    );
    dataset['next_week'] = this.week_view(
      grouped.next_week,
      this.next_week.start.clone()
    );
    dataset['past'] = this.month_view(grouped.past);
    dataset['future'] = this.month_view(grouped.future);
    return dataset;
  }

  /** Convert to month view */
  month_view(invites: Invite[]) {
    const events = {};
    const grouped_by_month = _.groupBy(invites, (invite: Invite) => {
      return moment(invite.datetime).format('YYYY-MM');
    });
    _.each(_.keys(grouped_by_month), (month) => {
      const monthStartDate = moment(month).startOf('month');
      const invites = grouped_by_month[month];
      const month_events = {};
      _.each(
        _.range(0, monthStartDate.clone().endOf('month').date()),
        (day) => {
          const mday = monthStartDate.clone().add(day, 'day');
          const next_day = mday.clone().add(1, 'day');
          const day_invites = [];
          _.each(invites, (invite: Invite) => {
            const date = moment(invite.datetime);
            if (date.isBetween(mday, next_day) || date.isSame(mday)) {
              day_invites.push(invite);
            }
          });
          month_events[mday.format('YYYY-MM-DD')] = day_invites;
        }
      );
      events[month] = month_events;
    });
    return events;
  }

  /** Convert to week view */
  week_view(week_invites: Invite[], week_start_date) {
    const events = {};
    _.each(_.range(0, 7), (day) => {
      const mday = week_start_date.clone().add(day, 'day');
      const next_day = mday.clone().add(1, 'day');
      const day_invites = [];
      _.each(week_invites, (invite: Invite) => {
        const date = moment(invite.datetime);
        if (date.isBetween(mday, next_day)) {
          day_invites.push(invite);
        }
      });

      events[mday.format('YYYY-MM-DD')] = this.groupByActivity(day_invites);
    });
    return events;
  }

  /** Seggregate invites into 5 categories. past, last_week, current_week, next_week, future */
  group(data: Invite[]) {
    const grouped: { [key: string]: Invite[] } = _.groupBy(data, (item) => {
      const date = moment(item.datetime);
      if (date.isBetween(this.current_week.start, this.current_week.end)) {
        return 'current_week';
      } else if (date.isBetween(this.last_week.start, this.last_week.end)) {
        return 'last_week';
      } else if (date.isBetween(this.next_week.start, this.next_week.end)) {
        return 'next_week';
      } else if (date.isBefore(this.last_week.start)) {
        return `past`;
      } else if (date.isAfter(this.next_week.end)) {
        return `future`;
      } else {
        return 'other';
      }
    });
    return grouped;
  }

  ref: number = 0;
  groupByActivity(data: Invite[]) {
    const grouped: { [key: string]: Invite[] } = _.groupBy(data, (item) => {
      if (item.activity != null) {
        return item.activity.name;
      }
    });

    _.each(grouped, (activity) => {
      this.ref++;
      activity.status = this.inviteStatusOverview(activity);
      activity.ref = this.ref;
    });

    return grouped;
  }

  /** Set overall status for grouped invites */
  inviteStatusOverview(invites) {
    if (invites == null) {
      return;
    }

    const total = invites.length;
    const paid = _.filter(invites,(invite: { status: string }) => invite.status == 'paid' || invite.status == 'paid-read' || invite.status == 'paid-cancel' || invite.status == 'paid-canceled').length;
    const accepted = _.filter(invites,(invite: { status: string }) => invite.status == 'accepted' || invite.status == 'accepted-read' || invite.status == 'accepted-yes').length;
    const declinded = _.filter(invites,(invite: { status: string }) =>invite.status == 'declined' || invite.status == 'declined-read' || invite.status == 'accepted-no').length;
    const maybe = _.filter(invites,(invite: { status: string }) =>invite.status == 'maybe' || invite.status == 'maybe-read' || invite.status == 'accepted-maybe').length;
    const pending = _.filter(invites,(invite: { status: string }) => invite.status == 'pending').length;

    if (paid > 0) {
      return 'paid';
    } 
    else if (accepted > 0) {
      return 'some-accepted';
    } 
    else if (total > 0 && total == declinded) {
      return 'all-declined';
    } 
    else if (maybe > 0) {
      return 'some-maybe';
    } 
    else if (pending > 0) {
      return 'pending';
    } 
    else {
      return '';
    }
  }

  // ERROR LOGGING
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a StockService message with the MessageService */
  private log(message: string) {
    console.log(message);
  }
}
