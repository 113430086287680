import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Activity, User } from 'src/app/models';
import { UserService, UtilsService } from 'src/app/services';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
  selector: 'app-idcard',
  templateUrl: './idcard.component.html',
  styleUrls: ['./idcard.component.scss'],
})
export class IDCardComponent implements OnInit, OnDestroy {
  @Input() userid: number;
  @Output() close: EventEmitter<any>;

  flags = {
    loading: false,
  };
  id: any;
  // user: User;
  // subs: Subscription[] = [];

  constructor(
    public utils: UtilsService,
    private userService: UserService
  ) {
    this.close = new EventEmitter();

    // const user = this.utils.appState.user.subscribe((resp) => {
    //   this.user = resp;
    // });
    // this.subs.push(user);
  }

  ngOnInit() {
    this.utils.sound();
    this.getVerifiedInfo();
  }

  getVerifiedInfo(){
    this.flags.loading = true;
    this.userService.getVerifiedInfo(this.userid).subscribe(data => {
      this.id = data;
      this.flags.loading = false;
      console.log(data);

    });
  }

  closeClick(){
    this.close.emit(true);
  }

  ngOnDestroy() {
  //   _.each(this.subs, (sub) => {
  //     sub.unsubscribe();
  //   });
  }
}
