import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SocialAuthService } from 'angularx-social-login';
import { map } from 'rxjs/operators';
import { UtilsService } from './utils.service';

@Injectable()
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private utils: UtilsService,
    public socialAuthService: SocialAuthService
  ) {}

  // login(email: string, password: string) {
  login(credentials: Object) {
    // return this.http.post<any>(`${environment.apiUrl}/api/v1/auth/login/`, { email: email, password: password })
    const url = this.utils.getUrl('rest_login');
    return this.http.post<any>(url, credentials).pipe(
      map((user) => {
        // login successful if there's a jwt token in the response
        this.utils.token = user['key'];
        return user;
      })
    );
  }

  // FB
  fbLogin(obj: Object) {
    const url = this.utils.getUrl('rest:fb_login');
    return this.http.post<any>(url, obj).pipe(
      map((user) => {
        this.utils.token = user['key'];
        return user;
      })
    );
  }

  fbLink(token) {
    const url = this.utils.getUrl('rest:fb_connect');
    return this.http.post<any>(url, { access_token: token }).pipe(
      map((user) => {
        this.utils.token = user['key'];
        return user;
      })
    );
  }

  // APPLE
  appleLogin(obj: Object) {
    const url = this.utils.getUrl('rest:apple_login');
    // const url = 'https://dev-apis.activity8.com/auth/apple/login';
    return this.http.post<any>(url, obj).pipe(
      map((user) => {
        this.utils.token = user['key'];
        return user;
      })
    );
  }

  appleLink(data) {
    const url = this.utils.getUrl('rest:apple_connect');
    return this.http.post<any>(url, data).pipe(
      map((user) => {
        this.utils.token = user['key'];
        return user;
      })
    );
  }

  // GOOGLE
  googleLogin(obj: Object) {
    const url = this.utils.getUrl('rest:google_login');
    return this.http.post<any>(url, obj).pipe(
      map((user) => {
        this.utils.token = user['key'];
        return user;
      })
    );
  }

  googleNativeLogin(obj: Object) {
    const url = this.utils.getUrl('rest:google_mobile_login');
    return this.http.post<any>(url, obj).pipe(
      map((user) => {
        this.utils.token = user['key'];
        return user;
      })
    );
  }

  googleLink(token) {
    const url = this.utils.getUrl('rest:google_connect');
    return this.http.post<any>(url, { access_token: token }).pipe(
      map((user) => {
        this.utils.token = user['key'];
        return user;
      })
    );
  }

  sendReset(email: string) {
    const url = this.utils.getUrl('rest_password_reset');
    return this.http.post<any>(url, { email: email }).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  confirmEmail(id: string) {
    const url = this.utils.getUrl('rest_verify_email');
    return this.http.post<any>(url, { key: id }).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  resetPassword(obj: Object) {
    const url = this.utils.getUrl('rest_password_reset_confirm');
    return this.http.post<any>(url, obj).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  logout() {
    // remove user from local storage to log user out
    this.utils.removeToken();
  }
}
