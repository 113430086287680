import { animation } from '@angular/animations';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  Input,
  SimpleChanges,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Activity, User } from 'src/app/models';
import { UtilsService } from 'src/app/services';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-add-activities',
  templateUrl: './add-activities.component.html',
  styleUrls: ['./add-activities.component.scss'],
})
export class AddActivitiesComponent implements OnInit, OnDestroy {
  @Output() change: EventEmitter<any>;
  @Input() mode: string;

  flags = {
    loading: false,
    btnAddingActivities: true,
    animation: 'start',
  };
  user: User;
  subs: Subscription[] = [];
  form: FormGroup;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  activityCtrl = new FormControl();
  filteredActivities: Observable<Activity[]>;
  activity: Activity;
  activities: Activity[] = [];
  previousActivities: Activity[] = [];
  previousInterests: Activity[] = [];
  allActivities: Activity[] = [];
  popularActivities: Activity[] = [];
  placeholder: string = 'Enter activities here...';
  @ViewChild('activityInput') activityInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private activityService: ActivityService,
    private utils: UtilsService,
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
  ) {
    this.change = new EventEmitter();

    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
      console.log(this.user);
      this.splitActivitiesInterests();
    });
    this.subs.push(user);

    this.filteredActivities = this.activityCtrl.valueChanges.pipe(
      startWith(''),
      map((value) =>
        (typeof value === 'string' ? value : value['name'])
      ),
      // map(name => name ? this._filter(name) : this.allActivities.slice()),
      map((name) => {
        if (name.length > 0) {
          return this._filter(name);
        }
      })
    );

    this.getPopularActivities();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      activities: ['', [Validators.required]],
    });

    this.setBtnAddingActivities();
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.mode = changes.mode.currentValue;
    if (this.mode != null) {
      this.splitActivitiesInterests();
    }
    if (this.mode == 'interest') {
      this.placeholder = 'Enter interests here...';
    }
    else {
      this.placeholder = 'Enter activities here...';
    }
  }

  splitActivitiesInterests() {
    this.previousInterests = _.filter(this.user.activities, (activity) => activity.is_interest);
    this.previousActivities = _.filter(this.user.activities, (activity) => !activity.is_interest);
    if (this.user.app_state.showSetActivities == 'interest') {
      this.activities = this.previousInterests;
    } else {
      this.activities = this.previousActivities;
    }
  }

  get f1() {
    return this.form.controls;
  }


  clickDone() {
    this.flags.animation = 'end';
    this.utils.sound('next');
    if ((this.activityCtrl.value || '').trim()) {
      this.getActivity(this.activityCtrl.value.trim());
      this.setBtnAddingActivities();
    }

    if (this.user.app_state.showSetActivities == 'interest') {
      this.user.activities = _.orderBy(this.activities.concat(this.previousActivities), ['activity.name']);
    } else {
      this.user.activities = _.orderBy(this.activities.concat(this.previousInterests), ['activity.name']);
    }

    this.utils.delay(800).then(() => {
      this.user.app_state.showSetActivities = false;
      this.utils.appState.user.next(this.user);
      this.change.emit(false);
      this.flags.animation = 'start';
      });
  }

  clickAddActivities() {
    // FADE BACK
    // this.user.app_state['homeCover'] = 'in';
    // setTimeout(() => this.user.app_state['homeCover'] = 'in', 700);
    // this.utils.appState.user.next(this.user);
  }

  clickActivityPill(activity) {
    this.utils.sound();
    this.popularActivities = this.popularActivities.filter(
      (obj) => obj.id != activity.id
    );
    if (!this.isLinked(activity)) {
      this.linkActivity(this.user.id, activity);
    }
  }

  addFromButton(control) {
    this.utils.sound();
    if ((control.value || '').trim()) {
      this.getActivity(control.value.trim());
      this.setBtnAddingActivities();
    }
    control.value = '';
    this.activityInput.nativeElement.value = '';
    this.setBtnAddingActivities();
  }

  activityLimit() {
    if (this.activities.length >= 8) {
      return true;
    }
    else {
      return false;
    }
  }

  add(event: MatChipInputEvent): void {
    this.utils.sound();
    const input = event.input;
    const value = event.value;

    // Add activity
    if ((value || '').trim()) {
      this.getActivity(value.trim());

      this.setBtnAddingActivities();
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.activityCtrl.setValue('');
  }

  remove(activity: Activity): void {
    this.activities = this.activities.filter((obj) => obj.id !== activity.id);
    this.unLinkActivity(activity.id);
    this.setBtnAddingActivities();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.utils.sound();
    let activity = event.option.value;
    this.popularActivities = this.popularActivities.filter(
      (obj) => obj.id != activity.id
    );
    if (!this.isLinked(activity)) {
      this.linkActivity(this.user.id, activity);
    }
    this.activityInput.nativeElement.value = '';
    this.activityCtrl.setValue('');
    this.setBtnAddingActivities();
  }

  private _filter(name: string): Activity[] {
    const filterValue = this.capitalizeFirstLetter(name);

    this.getActivitiesByName(filterValue);

    return this.allActivities.filter((activity) => this.capitalizeFirstLetter(activity.name).indexOf(filterValue) === 0);
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  displayFn(activity: Activity): string {
    return activity && activity.name ? activity.name : '';
  }

  setBtnAddingActivities() {
    if ((this.activities != null && this.activities.length > 0) || this.mode == 'interest') {
      this.flags.btnAddingActivities = false;
    } else {
      this.flags.btnAddingActivities = true;
    }
  }

  filterPopular() {
    this.previousInterests.forEach((activity) => {
      _.remove(this.popularActivities, (obj) => obj.id == activity.activity.id);
    });
    this.previousActivities.forEach((activity) => {
      _.remove(this.popularActivities, (obj) => obj.id == activity.activity.id);
    });
  }

  // FUNCTIONS
  isLinked(activity): boolean {
    let exists = this.activities.filter(
      (obj) => obj.activity.id === activity.id
    );
    if (exists.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  // DATA ITEMS
  getActivity(name) {
    if (name != null) {
      name = name.toLowerCase();
    }
    this.activityService.get(name).subscribe(
      (data) => {
        if (data != null) {
          this.linkActivity(this.user.id, data);
        } else if (data.detail != null && data.detail.toLowerCase() == 'not found.') {
          this.addActivity(name);
        }
      },
      (error) => {
        if (error.error.detail == 'Not found.') {
          this.addActivity(name);
        }
      }
    );
  }

  linkActivity(userid, activity) {
    if (this.activityLimit()) {
      return;
    }

    let data: any = { user: userid, activity: activity.id, experience: 0 };
    if (this.mode == 'interest') {
      data.is_interest = true;
    }
    this.activityService.link(data).subscribe((data) => {
      let useractivity = new Activity(data);
      useractivity.activity = activity;
      useractivity.experience = 0;
      this.activities.push(useractivity);
      // this.utils.appState.user.next(this.user);
      this.filterPopular();

      this.setBtnAddingActivities();
    });
  }

  unLinkActivity(activityid) {
    this.activityService.unlink(activityid).subscribe((data) => {
      // this.utils.appState.user.next(this.user);
      this.filterPopular();
    });
  }

  addActivity(name) {
    if (this.activityLimit()) {
      return;
    }

    this.activityService.create({ name: this.capitalizeFirstLetter(name),tags:'',is_alias:true }).subscribe((data) => {
      this.linkActivity(this.user.id, data);
    });
  }

  getActivitiesByName(name) {
    this.activityService.list({ search: name, limit: 10, is_alias:false }).subscribe((data) => {
      // NEED TO REMOVE USER ACTIVITIES
      // _.remove(data, function(activity) {
      //     return activity === this.user.activities;
      // });

      this.allActivities = Activity.fromList(data.results);
    });
  }

  getPopularActivities() {
    this.activityService.list({ limit: 30, ordering: '-popular', is_alias:false }).subscribe((data) => {
      this.popularActivities = Activity.fromList(data.results);
      this.filterPopular();
    });
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
