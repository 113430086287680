    <!-- QUESTION COLOR -->
    <div class="height100 grayBack items animate__animated animate__flipInY">
      <!-- <div class="items" *ngIf="user?.activities != null && user?.gender != null && setColor"> -->
      <div class="item">
        <button mat-button class="closeBtn" (click)="change.emit(false)" *ngIf="user.color != null"><mat-icon>close</mat-icon></button>
        <div class="question" (click)="getColors();utils.sound('click-face');">
        <mat-icon>color_lens</mat-icon><br />
        Color
        </div>
        <br />
        <p class="text-center">Choose a color for your account.</p>
    </div>
    <div class="item color">
      <mat-radio-group>
        <!-- <mat-radio-button [value]="'#FFFFFF'" [style.background-color]="'#FFFFFF'" (click)="clickColor('#FFFFFF')"></mat-radio-button> -->
        <mat-radio-button *ngFor="let color of colors" [value]="color" [style.background-color]="color" 
          (click)="clickColor(color)"></mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="question" (click)="getColors();utils.sound('click-face');">
      <mat-icon>refresh</mat-icon><br />
    </div>
</div>