import { User } from ".";
import * as _ from 'lodash';

export class Conversation {
    id: number;
    title: string;
    room_type: string;
    members: User[];
    creator: User[];
    message: string;
    last_activity: Date;
    last_message: Date;
    meta: any;
    unread_count: number;

    constructor(data: Object) {
        Object.assign(this, data);
      }

    public static fromList(datalist: object[]) {
      return _.map(datalist, item => {
        return new Conversation(item);
      });
    }      
}
  