import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { User, PaginatedList } from '../models';
import { UtilsService } from './utils.service';
import { Observable } from 'rxjs';
import * as rxOp from 'rxjs/operators';

@Injectable()
export class UserService {
  braintreeClientToken: string;

  constructor(private http: HttpClient, private utils: UtilsService) { }

  getAll(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:users-list');
    return this.http
      .get<PaginatedList>(url, this.utils.requestOptions(qp))
      .pipe(
        rxOp.map((resp: PaginatedList) => {
          return resp;
        })
      );
  }

  get(id: number, qp?: Object): Observable<User> {
    const url = this.utils.getUrl('rest:users-detail', id);
    return this.http.get(url, this.utils.requestOptions(qp)).pipe(
      rxOp.map((resp: User) => {
        return new User(resp);
      })
    );
  }

  sendActivation(): Observable<any> {
    const url = this.utils.getUrl('resend-email-verification');
    return this.http.get(url).pipe(
      rxOp.map((resp: User) => {
        return new User(resp);
      })
    );
  }

  getVerifiedInfo(id:number): Observable<any> {
    const url = this.utils.getUrl('rest:users-verified-info', id);
    return this.http.get(url).pipe(
      rxOp.map((resp: User) => {
        return new User(resp);
      })
    );
  }

  // CONTENT PREFERENCES
  blockContent(object?: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:block-content-list');
    return this.http.post(url, object, this.utils.requestOptions(qp));
  }

  removeBlocked(id: number): Observable<any> {
    const url = this.utils.getUrl('rest:block-content-detail', id);
    console.log(id);
    return this.http.delete(url);
  }

  getBlocked(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:block-content-list');
    return this.http
      .get<PaginatedList>(url, this.utils.requestOptions(qp))
      .pipe(
        rxOp.map((resp: PaginatedList) => {
          // return resp.results = User.fromList(resp.results);
          return resp.results;
        })
      );
  }
  // --

  metrics(id: number, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:users-metric', id);
    return this.http.get(url, this.utils.requestOptions(qp));
  }

  updateMetrics(id: number, object?: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:users-metric', id);
    return this.http.patch(url, object, this.utils.requestOptions(qp));
  }

  getByName(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:users-list');
    return this.http
      .get<PaginatedList>(url, this.utils.requestOptions(qp))
      .pipe(
        rxOp.map((resp: PaginatedList) => {
          return resp.results = User.fromList(resp.results);
          // return resp.results;
        })
      );
  }

  getActivityLog(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:activity-log-list');
    return this.http
      .get<PaginatedList>(url, this.utils.requestOptions(qp))
      .pipe(
        rxOp.map((resp: PaginatedList) => {
          // resp.results = User.fromList(resp.results);
          return resp.results;
        })
      );
  }

  getSettings(qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:user-settings-list');
    return this.http
      .get<PaginatedList>(url, this.utils.requestOptions(qp))
      .pipe(
        rxOp.map((resp: PaginatedList) => {
          // resp.results = User.fromList(resp.results);
          return resp;
        })
      );
  }

  saveSettings(data: any) {
    const url = this.utils.getUrl('rest:user-settings-list');
    return this.http.post(url, data);
  }

  register(data: any) {
    const url = this.utils.getUrl('rest_register');
    return this.http.post(url, data);
  }

  // update(id: number, qp?: Object): Observable<any> {
  //   const url = this.utils.getUrl('rest:users-detail', id);
  //   return this.http.patch(url, this.utils.requestOptions(qp));
  // }


  // update(user: User) {
  //   return this.http.put(`${environment.apiUrl}/users/` + user.id, user);
  // }

  // delete(id: number) {
  //   return this.http.delete(`${environment.apiUrl}/users/` + id);
  // }
}
