<mat-progress-bar mode="indeterminate" *ngIf="selectedFile?.pending" [class.iosProgress]="utils.ios()"></mat-progress-bar>
<img src="{{profileImagePreload}}" [hidden]="true" *ngIf="profileImagePreload" />
<div class="backgroundCoverFull" [style.background]="color" [class.lightMode]="color|isLight" (tap)="doubleTapProfile($event, profile?.id)" (press)="pressGuesture()">
  <!-- <div class="stats" fxLayoutAlign="space-evenly start">
    <div>Likes: {{ profile?.metrics?.likes_for_me }}</div>
    <div>Posts: {{ profile?.metrics?.posts }}</div>
    <div>Partners: {{ profile?.metrics?.partners }}</div>
    <div>Kudos: {{ profile?.metrics?.kudos }}</div>
  </div> -->
  <div [style.background-image]="'url('+ profileImage +')'" (swipeleft)="nextImage(+1)"  (swiperight)="nextImage(-1)" class="backgroundCoverHalf" [class.backgroundIn]="flags?.fadeBackground">
    <div fxlayout="row" class="profileComponent">

      <!-- <mat-icon *ngIf="activity?.meta?.is_new" class="new animate__animated animate__hinge" [style.color]="randomColor()">fiber_new</mat-icon> -->
      <!-- MEMBER IS PREMIUM -->
      <!-- <mat-icon *ngIf="activity?.is_premium" class="new animate__animated" [style.color]="profile?.color">star</mat-icon> -->

      <div class="actions" *ngIf="user?.isAuthenticated">
        <button mat-button color="primary" (click)="infoLink()" *ngIf="activity?.url != null"><mat-icon>info</mat-icon></button>

        <button mat-button color="primary" (click)="share();utils.sound('yes')" *ngIf="flags.enableShare"><mat-icon>shared</mat-icon></button>
        <button [matMenuTriggerFor]="shareMenu" mat-button color="primary" *ngIf="!flags.enableShare"><mat-icon>shared</mat-icon></button>
        <mat-menu #shareMenu="matMenu" [overlapTrigger]="false">
          <div mat-menu-item >
            <button mat-icon-button shareButton="twitter" #twtBtn="shareButton"><fa-icon *ngIf="twtBtn" [icon]="twtBtn.icon" size="lg"></fa-icon></button>
            <button mat-icon-button shareButton="facebook" #fbBtn="shareButton"><fa-icon *ngIf="fbBtn" [icon]="fbBtn.icon" size="lg"></fa-icon></button>
            <button mat-icon-button shareButton="email" #emailBtn="shareButton"><fa-icon *ngIf="emailBtn" [icon]="emailBtn.icon" size="lg"></fa-icon></button>
            <button mat-icon-button shareButton="whatsapp" #whatsappBtn="shareButton"><fa-icon *ngIf="whatsappBtn" [icon]="whatsappBtn.icon" size="lg"></fa-icon></button>
            <button mat-icon-button shareButton="sms" #smsBtn="shareButton"><fa-icon *ngIf="emailBtn" [icon]="smsBtn.icon" size="lg"></fa-icon></button>
          </div>
        </mat-menu>
        <button mat-button color="primary" (click)="addActivity()" *ngIf="flags.showAddActivity" [class.zoomBoom]="flags.activityAdded"><mat-icon>add</mat-icon></button>

      </div>
      <div class="actions user" *ngIf="!user?.isAuthenticated">
        <button mat-button color="primary" (click)="infoLink()" *ngIf="activity?.url != null"><mat-icon>info</mat-icon></button>
        <button mat-button color="primary" (click)="share();utils.sound('yes')" *ngIf="flags.enableShare"><mat-icon>shared</mat-icon></button>
      </div>


      <div class="imageNav">
        <ng-container *ngFor="let img of profileImages;let i = index;">
          <mat-icon *ngIf="profileImages?.length > 1 && i == imageNumber" (click)="nextImage(-1)">face</mat-icon>
          <mat-icon *ngIf="profileImages?.length > 1 && i != imageNumber" class="small" (click)="nextImage(-1)">brightness_1</mat-icon>
        </ng-container>
      </div>

    </div>
  </div>

  <div class="info" (swipeleft)="navigate(+1)"  (swiperight)="navigate(-1)">
    <div class="intro">
      <h1> {{ activity?.name }}</h1>
      <br clear="all" />
      <div class="username">#{{activity?.slug}}</div>
    </div>
    <div class="content" [class.lightMode]="color|isLight">
      
      <div class="scrollNav" *ngIf="!activity?.is_alias">
        <button mat-button (click)="scroll(groupsAnchor)" *ngIf="groups?.length != 0">Groups</button>
        <button mat-button (click)="scroll(productsAnchor)" *ngIf="products?.length != 0">Gear</button>
        <button mat-button (click)="scroll(videosAnchor)" *ngIf="videos?.length != 0">Videos</button>
        <button mat-button (click)="scroll(membersAnchor)" *ngIf="flags.memberCount != 0">Members</button>
        <!-- <button mat-button (click)="scroll(postsAnchor)" *ngIf="postCount?.length != 0">Posts</button> -->
      </div>


        <!-- <button mat-button (click)="goToActivity(primaryActivity)" *ngIf="activity?.is_alias && flags.hasPrimary">Go to {{primaryActivity?.name}}</button> -->

      <div class="relatedActivities" *ngIf="relatedActivities?.length > 0">
        <button mat-button [style.background-color]="colorPill(activity)" [class.invertPill]="activity?.is_alias" class="pill" (click)="goToActivity(activity)" *ngFor="let activity of relatedActivities">{{activity?.name}}</button>
      </div>

      <p class="description">
        {{ activity?.description }}
      </p>

      <div fxlayout="row" class="tags">
        <button mat-button class="pill" *ngFor="let tag of activity?.tags" routerLink="/activities/tag/{{tag}}">{{ tag }}</button>
      </div>      
      <br clear="all" />
      <br />
      <div #postsAnchor></div>
      <app-search-posts [slug]="searchStr" (resultCount)="flags.postsCount=$event"></app-search-posts>
      <button mat-button [routerLink]="'/search/posts/'+ activity.name" class="more" color="primary" *ngIf="flags.postsCount > 18">More</button>
      <br clear="all" />

      <!-- <div class="scrollDown">
        <button mat-button onclick="window.scrollTo(0, screen.height)" class="animate__animated animate__shakeY"><mat-icon [style.color]="user?.color">double_arrow</mat-icon></button>
      </div> -->

      <div #groupsAnchor></div>
      <div fxlayout="row" class="groups" *ngIf="groups?.length != 0">
        <div class="resultsHeader on"><mat-icon>groups</mat-icon> Groups</div>
        <ul>
          <li *ngFor="let group of groups">
            <div class="groupName">
              <b>{{ group.name}}</b>
              <mat-icon class="paid" *ngIf="group?.paid">monetization_on</mat-icon>
            </div>
            <div class="groupIcons">
              <a [href]="group?.url" target="_blank"><mat-icon [style.color]="color">website</mat-icon></a>
              <a *ngIf="group?.location?.place != group?.location?.city+', '+group?.location?.state" [href]="'https://www.google.com/maps/?q='+group?.location?.place" target="_blank"><mat-icon [style.color]="color">location_on</mat-icon></a>
              <a *ngIf="group?.location?.place == group?.location?.city+', '+group?.location?.state" [href]="'https://www.google.com/maps/?q='+group?.location?.location?.coordinates[1]+','+group?.location?.location?.coordinates[0]+'&query_place='+group?.name" target="_blank"><mat-icon [style.color]="color">location_on</mat-icon></a>
            </div>
          </li>
        </ul>
        <br clear="all" />
      </div>
      
      <div #productsAnchor></div>
      <div fxlayout="row" class="groups" *ngIf="products?.length != 0">
        <div class="resultsHeader on"><mat-icon>shopping_cart</mat-icon> Gear</div>
        <ul>
          <li *ngFor="let product of products"><b>{{ product.name}}</b> <a [href]="product?.url" target="_blank"><mat-icon [style.color]="color">website</mat-icon></a><br *ngIf="product.description != ''" />
            <span>{{ product.description}}</span>
          </li>
        </ul>
      </div>

      <div #videosAnchor></div>
      <div class="videos" *ngIf="videos?.length != 0">
        <div class="resultsHeader on"><mat-icon>videocam</mat-icon> Videos</div>
        <div *ngFor="let video of videos">
      
          <div class="title">{{ video.category }}</div>
          <iframe class="videoSquares fadeIn" [src]="video.url|safe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      
      <div #membersAnchor></div>
      <app-search-users [slug]="searchStr" (resultCount)="flags.memberCount=$event"></app-search-users>
      <!-- <button mat-button [routerLink]="'/search/activities/'+ activity.name" *ngIf="flags.memberCount > 20">More</button> -->
      
      <br clear="all" />

    </div>
  </div>

</div>