import { Component, Input, OnInit, SimpleChanges, OnDestroy, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { BackgroundAnimations, inOutAnimation } from 'src/app/animations';
import { Activity, User } from 'src/app/models';
import { ImageSnippet } from 'src/app/models/image';
import { UtilsService } from 'src/app/services';
import { ActivityService } from 'src/app/services/activity.service';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-search-settings',
  templateUrl: './search-settings.component.html',
  styleUrls: ['./search-settings.component.scss'],
  animations: [BackgroundAnimations, inOutAnimation],
})
export class SearchSettingsComponent implements OnInit, OnDestroy {
  // @Input() searchStr;
  flags = {
    sfe: false, // Show Form Errors
    loading: false,
    btnSubmit: true,
    noResults: false,
    singleClicks: 0,
    straightClicks: 0,
    smokerClicks: 0,
    drinkerClicks: 0,
    moreSettings: false,
  };

  loadLength: number = 30;
  resultOffset: number = 0;
  resultTotal: number = 0;

  private _searchStr;
  get searchStr(): any {
    return this._searchStr;
  }

  @Input()
  set searchStr(val: any) {
    // console.log('previous item = ', this._searchStr);
    // console.log('currently selected item=', val);
    this._searchStr = val;
  }

  activities: Activity[] = [];
  user: User;
  subs: Subscription[] = [];
  selectedFile: ImageSnippet;
  form: FormGroup;

  constructor(
    private activityService: ActivityService,
    public utils: UtilsService,
    public router: Router,
    private imageService: ImageService,
    private formBuilder: FormBuilder,
  ) {
    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
    });
    this.subs.push(user);
  }

  ngOnInit() {

    this.form = this.formBuilder.group({
      image: [''],
    });

   }

   toggleActive() {
    this.user.app_state.search_last_activity = this.user.app_state.search_last_activity === false ? true : false;
    this.saveSettings();
    if(this.user.app_state.search_last_activity && this.user.app_state.search_date_joined){
      this.toggleJoined();
    }
  }

  toggleJoined() {
    this.user.app_state.search_date_joined = this.user.app_state.search_date_joined === false ? true : false;
    this.saveSettings();
    if(this.user.app_state.search_last_activity && this.user.app_state.search_date_joined){
      this.toggleActive();
    }
  }

  toggleLocation() {
    this.user.app_state.search_location = this.user.app_state.search_location === false ? true : false;
    this.saveSettings();
  }

  toggleGender() {
    this.utils.sound('toggle');
    if (this.user.app_state.search_gender == null) {
      this.user.app_state.search_gender = 'M';
    }
    else if (this.user.app_state.search_gender == 'M') {
      this.user.app_state.search_gender = 'F';
    }
    else {
      this.user.app_state.search_gender = null;
    }
    this.saveSettings();
  }
  toggleSingle() {
    this.utils.sound('toggle');
    this.flags.singleClicks++;
    if (this.flags.singleClicks > 2) {
      this.user.app_state.search_is_single = null;
      this.flags.singleClicks = 0;
    }
    else if (this.user.app_state.search_is_single) {
      this.user.app_state.search_is_single = false;
    }
    else if (!this.user.app_state.search_is_single) {
      this.user.app_state.search_is_single = true;
    }
    this.saveSettings();
  }  
  toggleStraight() {
    this.utils.sound('toggle');
    this.flags.straightClicks++;
    if (this.flags.straightClicks > 2) {
      this.user.app_state.search_is_straight = null;
      this.flags.straightClicks = 0;
    }
    else if (this.user.app_state.search_is_straight) {
      this.user.app_state.search_is_straight = false;
    }
    else if (!this.user.app_state.search_is_straight) {
      this.user.app_state.search_is_straight = true;
    }
    this.saveSettings();
  }
  toggleDrinker() {
    this.utils.sound('toggle');
    this.flags.drinkerClicks++;
    if (this.flags.drinkerClicks > 2) {
      this.user.app_state.search_is_drinker = null;
      this.flags.drinkerClicks = 0;
    }
    else if (this.user.app_state.search_is_drinker) {
      this.user.app_state.search_is_drinker = false;
    }
    else if (!this.user.app_state.search_is_drinker) {
      this.user.app_state.search_is_drinker = true;
    }
    this.saveSettings();
  }
  toggleSmoker() {
    this.utils.sound('toggle');
    this.flags.smokerClicks++;
    if (this.flags.smokerClicks > 2) {
      this.user.app_state.search_is_smoker = null;
      this.flags.smokerClicks = 0;
    }
    else if (this.user.app_state.search_is_smoker) {
      this.user.app_state.search_is_smoker = false;
    }
    else if (!this.user.app_state.search_is_smoker) {
      this.user.app_state.search_is_smoker = true;
    }
    this.saveSettings();
  }

  singleNull() {
    if (this.user.app_state.search_is_single == null) {
      return true;
    }
    else {
      return false;
    }
  }
  straightNull() {
    if (this.user.app_state.search_is_straight == null) {
      return true;
    }
    else {
      return false;
    }
  }
  drinkerNull() {
    if (this.user.app_state.search_is_drinker == null) {
      return true;
    }
    else {
      return false;
    }
  }
  smokerNull() {
    if (this.user.app_state.search_is_smoker == null) {
      return true;
    }
    else {
      return false;
    }
  }

  // POSTS
  toggleUpcoming() {
    this.user.app_state.search_upcoming = this.user.app_state.search_upcoming === false ? true : false;
    this.saveSettings();
  }

  toggleMyActivities() {
    this.user.app_state.search_myactivities = this.user.app_state.search_myactivities === false ? true : false;
    this.saveSettings();
  }


  // DATA
  toggleSettings(setting){
    // activity.is_premium = !activity.is_premium;
    // this.activityService.updateActivity(activity.id, {is_premium:activity.is_premium}).subscribe();
  }

  saveSettings(){
    this.utils.appState.user.next(this.user);
  }



  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
