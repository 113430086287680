import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { BackgroundAnimations, inOutAnimation } from 'src/app/animations';
import { isLiked } from 'src/app/directives/pipe.component';
import { User } from 'src/app/models';
import { LikeService } from 'src/app/services/like.service';
import { Activity } from '../../models/activity';
import { CurrentUserService, UserService } from '../../services';
import { ImageService } from '../../services/image.service';
import { UtilsService } from '../../services/utils.service';
import { LocationService } from 'src/app/services/location.service';
import { AbuseService } from 'src/app/services/abuse.service';
import { BillingService } from 'src/app/services/billing.service';
import { environment } from 'src/environments/environment';
import { ImageSnippet } from 'src/app/models/image';
import { ActivityService } from 'src/app/services/activity.service';
import { exit } from 'process';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-search-activity',
  templateUrl: './search-activity.component.html',
  styleUrls: ['./search-activity.component.scss'],
  animations: [BackgroundAnimations, inOutAnimation],
})
export class SearchActivityComponent implements OnInit, OnDestroy {
  color: string;
  selectedFile: ImageSnippet;
  totalDefaultImages: number = 8;
  searchStr: string;
  imageNumber: number;
  profileImage: string;
  profileImagePreload: string;
  profileImages: any;
  colors: string[] = [];
  setColor: boolean = false;
  isUser: boolean = false;
  user: User;
  likes: any;
  liked: number = 0;
  matches: User[];
  videos: any[] = [];
  products: any[] = [];
  //   {category:'Beginner', name:'What is Vinyasa?',url:'https://www.youtube.com/embed/cQ69bxW0DN4?controls=0',meta:{timing:10}},
  //   {category:'Beginner', name:'Types of yoga',url:'https://www.youtube.com/embed/6y9hyZLG4fc?controls=0',meta:{timing:10}},
  //   {category:'Gear', name:'What to have?',url:'https://www.youtube.com/embed/aXkAlJORkf4?controls=0',meta:{timing:10}},
  // ];
  matchCount: number = -1;
  minDate: Date;
  startDate: Date;
  subs: Subscription[] = [];
  userForm: FormGroup;
  dobForm: FormGroup;
  form: FormGroup;
  profile: User;
  activity: Activity;
  primaryActivity: Activity;
  relatedActivities: Activity[] = [];
  inviteActivity: Activity;
  groups: any[] = [];

  flags = {
    sfe: false, // Show Form Errors
    loading: false,
    hasPrimary: false,
    fadeBackground: false,
    enableShare: false,
    idcard: false,
    activityAdded: false,
    showAddActivity: false,
    noVideos: false,
    memberCount: 0,
    postsCount: 0,
  };

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private location: Location,
      public utils: UtilsService,
      private meService: CurrentUserService,
      private userService: UserService,
      private activityService: ActivityService,
      private isLiked: isLiked,
  ) {
    this.route.params.subscribe((params) => {
      if(params['searchStr'] != null){
        this.searchStr = params['searchStr'].toLowerCase();
      }
    });

    window.scrollTo(0, 0);

    this.meService.isAuth(router.routerState.snapshot, true);

    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
    });
    this.subs.push(user);


    this.getActivity(this.searchStr);

    // for (let i = 0; i < 30; i++) {
    //   this.colors.push(this.randomColor());
    // }

    // SHOW SHARE BUTTON
    let navigator: any;
    navigator = window.navigator;
    if (navigator && navigator.share) {
      this.flags.enableShare = true;
    }
  }

  ngOnInit() {
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }  
  
  infoLink(){
    window.open(this.activity.url, '_blank');
  }

  addActivity(){
    if (!this.isLinked(this.activity)) {
      this.linkActivity(this.user.id, this.activity);
    }
  }

  isLinked(activity): boolean {
    let exists = this.user.activities.filter(
      (obj) => obj.activity.id === activity.id
    );
    if (exists.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  linkActivity(userid, activity) {
    let type = 'activities';
    let activities = _.filter(this.user.activities, (useractivities) => !useractivities.is_interest);
    let interests = _.filter(this.user.activities, (useractivities) => useractivities.is_interest);

    if (activities.length + interests.length >= 16) {
      this.utils.alert.error('Limit reached, adjust in profile');
      this.flags.activityAdded = true;
      return;
    }

    let data: any = { user: userid, activity: activity.id, experience: 0 };

    if(activities.length >= 8){
      data.is_interest = true;
      type = 'interests';
    }

    this.activityService.link(data).subscribe((data) => {
      let useractivity = new Activity(data);
      useractivity.activity = activity;
      useractivity.experience = 0;
      this.user.activities.push(useractivity);
      this.utils.appState.user.next(this.user);

      this.flags.activityAdded = true;
      this.utils.alert.success(this.activity.name + ' added to your ' + type);

    });
  }


  doubleTapProfile(event, id) {
    if (event.tapCount > 1) {
      // this.utils.alert.success('hello 🦄');
    }
  }

  pressGuesture(){
    // this.utils.alert.success('meeeow 😻');
  }

  share() {
    let navigator: any;
    navigator = window.navigator;

    let title = 'Check this activity! 😁';
    let description = 'What you think? -' + this.user.full_name;

    // // WITH FILES
    // let filesArray = [this.getFile()];
    // if (navigator.canShare && navigator.canShare({ files: filesArray })) {
    //   navigator
    //     .share({
    //       files: filesArray,
    //       title: title,
    //       text: description,
    //       url: '/' + profile.username,
    //     })
    //     .then(() => console.log('Share was successful.'))
    //     .catch((error) => console.log('Sharing failed', error));
    // }
    // // NO FILES
    // else if (navigator && navigator.share) {
    if (navigator && navigator.share) {
      navigator
        .share({
          title: title,
          text: description,
          url: 'https://activity8.app/search/activity/' + this.activity.slug,
        })
        .then(() => {
          this.utils.alert.success('Share with someone to invite');
        })
        .catch((error) => console.log('Error sharing', error));
    } else {
      alert('share not supported');
    }
  }


  animateImage() {
    this.flags.fadeBackground = true;
    setTimeout(() => {
      this.flags.fadeBackground = false;
    }, 1000);
  }

  nextImage(direction) {
    // GESTURES IF NO IMAGES NAVIGATE PROFILE
    if (this.profileImages == null || this.profileImages.length == 1) {
      this.navigate(direction);
      return;
    }

    this.utils.sound('click-face');
    this.animateImage();
    if (direction == -1) {
      this.imageNumber--;
      if (this.imageNumber == -1 && this.profileImages != null) {
        this.imageNumber = this.profileImages.length - 1;
      }
    } else {
      this.imageNumber++;
      if (this.profileImages && this.imageNumber == this.profileImages.length) {
        this.imageNumber = 0;
      }
    }

    if (
      this.profileImages != null &&
      this.profileImages[this.imageNumber] != null
    ) {
      this.profileImage = this.profileImages[this.imageNumber].image.lg;
      this.getImagePreload();
    }
  }

  getImagePreload() {
    if (this.imageNumber - 1 == -1) {
      this.profileImagePreload =
        this.profileImages[this.profileImages.length - 1].image;
    } else {
      this.profileImagePreload =
        this.profileImages[this.imageNumber - 1].image.sm;
    }
  }

  navigate(direction) {
    this.utils.sound('next');
    if (this.user == null) {
      return;
    }

    if (direction == -1) {
      this.matchCount--;
      if (this.matchCount < 0) {
        this.location.back();
      } else {
        this.profile = this.matches[this.matchCount];
      }
    } else {
      this.matchCount++;
      this.profile = this.matches[this.matchCount];

      if (this.matchCount == this.matches.length - 1) {
        this.matchCount = -1;
      }
    }
    if (this.profile == null && this.user.id == this.profile.id) {
      this.profile = this.user;
      this.isUser = true;
    } else {
      this.isUser = false;
      this.liked = this.isLiked.transform(
        this.profile.id,
        this.likes,
        'accounts.User'
      );
    }
    this.setActivityImage();
    // Get users, posts, videos, groups

    this.router.navigate(['/search/activity/' + this.activity.name]);
  }




  randomColor() {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  }


  setActivityImage() {
    this.profileImages = null;
    this.animateImage();
    if (
      this.activity != null &&
      this.activity.images != null &&
      this.activity.images.photos != null &&
      this.activity.images.photos.length > 0
    ) {
      this.imageNumber = this.activity.images.photos.length - 1;
      this.profileImage = this.activity.images.photos[this.imageNumber].image.lg;
      this.profileImages = this.activity.images.photos;
      this.getImagePreload();
    } else {
      let randomImage = Math.floor(Math.random() * this.totalDefaultImages);

      this.profileImage =
        './assets/img/background/activities/default.jpg';
        // './assets/img/profiles/default' + randomImage + '.jpg';
    }
  }

  goToActivity(activity){
    this.getActivity(activity.slug);
    this.router.navigateByUrl('/activity/'+ activity.slug);
  }

  findPrimary(base){
    let filters: any = {};
    filters.fields = 'name, is_primary,slug,is_alias';
    filters.group = base;
    this.activityService.list(filters).subscribe((data) => {
      let activities = _.filter(data.results, activity => activity.is_primary);
      this.relatedActivities =  _.filter(data.results, activity => activity.slug != this.activity.slug);
      if(activities.length > 0){
        this.flags.hasPrimary = true;
        this.primaryActivity = activities[0];
      }
    });
  }

  colorPill(activity){
    if(activity.is_alias){
      return this.color;
    }
  }

  // DATA
  getActivity(searchStr, mode?) {
    this.color = this.randomColor();
    this.flags.hasPrimary = false;
    this.relatedActivities = [];
    this.activityService.get(searchStr, { expand: 'images,group' })
      .subscribe((data) => {
        this.activity = data;

        if(this.activity.is_alias){
          this.findPrimary(this.activity.group['id']);
        }
        // GET GROUPS IF LOGGED IN AS WE NEED LOCATION
        if(this.user != null){
          try{
            this.getGroups(this.activity.slug);
          }
          catch{}
        }
        this.setActivityImage();
        
        this.getProducts(this.activity.slug);
        this.getVideos(this.activity.slug);


        if(this.user != null){
          let activities = _.filter(this.user.activities, (useractivities) => useractivities.activity.id == this.activity.id);
          if(activities != null && activities.length == 0){
            this.flags.showAddActivity = true;
          }
        }
    
      },
      (err)=>{
          this.router.navigateByUrl('/search/activities');
          console.log(err)
      });
  }

  getGroups(slug) {
    let filters: any = {};
    filters.expand = 'location';
    filters.activity = slug;
    filters.location_lat = this.user.location.location.coordinates[0];
    filters.location_lng = this.user.location.location.coordinates[1];
    filters.location_radius = 100;

    this.activityService.listGroups(filters)
      .subscribe((data) => {
        this.groups = data;
      },
      (err)=>{
          console.log(err)
      });
  }

  getVideos(slug) {
    let filters: any = {};
    filters.activity = slug;
    this.activityService.listVideos(filters)
      .subscribe((data) => {
        this.videos = data;
      },
      (err)=>{
          console.log(err)
      });
  }

  getProducts(slug) {
    let filters: any = {};
    filters.activity = slug;

    this.activityService.listProducts(filters)
      .subscribe((data) => {
        this.products = data;
      },
      (err)=>{
          console.log(err)
      });
  }

  getMetrics(id) {
    this.userService.metrics(id).subscribe((data) => {
      this.profile.metrics = data;
      this.profile.kudos = data.kudos;
      this.profile.likes = data.likes;
      this.profile.partners = data.partners;
      this.profile.posts = data.posts;
    });
  }


  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
