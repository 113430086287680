import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models';
import { BackgroundAnimations, inOutAnimation } from '../../animations';
import { Conversation } from '../../models/conversation';
import { MessageService } from '../../services/message.service';
import { UtilsService } from '../../services/utils.service';
import { CurrentUserService, UserService } from '../../services';
import { Message } from '../../models/message';
import { SafePipe } from 'src/app/directives/pipe.component';
import { DomSanitizer } from '@angular/platform-browser';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-message-detail',
  templateUrl: './message-detail.component.html',
  styleUrls: ['./message-detail.component.scss'],
  animations: [BackgroundAnimations, inOutAnimation],
})
export class MessageDetailComponent implements OnInit, OnDestroy {
  form: FormGroup;
  user: User;
  subs: Subscription[] = [];
  flags = {
    sfe: false, // Show Form Errors
    loading: false,
    btnSubmit: false,
    noResults: false,
    noResultsConversations: false,
  };
  bgImages = ['2', '3', '4', '5', '6', '7'];
  bgImg = this.bgImages[Math.floor(Math.random() * this.bgImages.length)];
  typing: boolean = false;
  // conversationSelected: Conversation = null;
  conversationSelected: any;

  conversations: Conversation[] = [];
  messages: Message[] = [];
  conversationid: number;
  addIcon: string = 'add';

  loadLength: number = 20;
  resultOffset: number = 0;
  resultTotal: number = 0;

  resultOffsetConversations: number = 0;
  resultTotalConversations: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    public utils: UtilsService,
    private messageService: MessageService,
    private userService: UserService,
    private sanitizer: DomSanitizer,
  ) {
    this.route.params.subscribe(
      (params) => {
        this.conversationid = params['id'];
      }
    );

    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
      // this.getConversations();
    });
    this.subs.push(user);
    const conversation = this.utils.appState.conversation.subscribe((resp) => {
      this.conversationSelected = resp;
    });
    this.subs.push(user);

    this.form = this.formBuilder.group({
      // message: ['', Validators.required],
      message: [''],
    });
  }

  ngOnInit() {
    this.messages = [];
    this.resultOffset = 0;
    this.resultTotal = 0;
    this.flags.noResults = false;
    // this.conversationSelected = conversation;
    this.getMessages(this.conversationid);

  }

  @HostListener('scroll', ['$event']) onScroll($event: Event): void {
    const element = event.currentTarget as HTMLInputElement
    const scrollItem = element;

    if ((scrollItem.clientHeight + scrollItem.scrollHeight) >= document.body.offsetHeight / 1.1) {
      // you're at the bottom of the page
      if (!this.flags.loading && !this.flags.noResults) {
        this.loadMore();
      }
    }
  };

  getProfileBackground(profile){
    if(profile != null && profile.thumbnail != null && profile.thumbnail.sm){
      return 'url('+ profile.thumbnail.sm +')';
    }
    else{
      return 'none';
    }  
  }
 
  loadMore() {
    this.resultOffset += this.loadLength;
    this.getMessages(this.conversationSelected.id);
  }

  get f() {
    return this.form.controls;
  }

  startTyping() {
    this.typing = true;
    setTimeout(() => (this.typing = false), 3000);
  }

  clickAddConversation() {
    this.user.app_state.startConversation == null
      ? (this.user.app_state.startConversation = true)
      : (this.user.app_state.startConversation = null);
    this.addIcon == 'add' ? (this.addIcon = 'close') : (this.addIcon = 'add');
  }

  // selectConversation(conversation) {
  //   this.messages = [];
  //   this.resultOffset = 0;
  //   this.resultTotal = 0;
  //   this.flags.noResults = false;
  //   this.conversationSelected = conversation;
  //   this.getMessages(conversation.id);
  //   // this.conversation = this.conversation2.slice().reverse();
  // }

  activateBtn(event) {
    if (!this.form.invalid) {
      this.flags.btnSubmit = false;
    } else {
      this.flags.btnSubmit = true;
    }
  }

  onSubmit() {
    let text = this.f.message.value;

    if (_.isEmpty(text)) {
      return;
    }

    this.messageService
      .respond({ room: this.conversationSelected.id, message: text })
      .subscribe((data) => {
        if (
          data != null &&
          data.sender != null &&
          data.sender == this.user.id
        ) {
          let senderid = data.sender;
          data.sender = [];
          data.sender.id = senderid;
          data.sender.color = this.user.color;
        }
        this.messages.unshift(data);
      });

    this.typing = false;
    this.f.message.setValue('');
  }

  randomColor() {
    return (
      'rgb(' +
      Math.floor(Math.random() * 225) +
      ',' +
      Math.floor(Math.random() * 225) +
      ',' +
      Math.floor(Math.random() * 225) +
      ', 0.2)'
    );
  }

  randomColorSolid() {
    return (
      'rgb(' +
      Math.floor(Math.random() * 225) +
      ',' +
      Math.floor(Math.random() * 225) +
      ',' +
      Math.floor(Math.random() * 225) +
      ', 1)'
    );
  }


  // DATA

  getMessages(conversationid) {
    this.flags.loading = true;

    if (this.resultOffset >= this.resultTotal && this.resultOffset != 0) {
      this.flags.noResults = true;
    }

    let filters: any = {};
    filters.offset = this.resultOffset;
    filters.limit = this.loadLength;

    filters.room = conversationid;
    filters.expand = 'sender';

    this.messageService.listMessages(filters).subscribe((data) => {
      this.resultTotal = data.count;
      let messages = Message.fromList(data.results);

      // METRICS
      let unreadCount = 0;
      messages.forEach((message) => {
        if (message.status == 'sent') {
          unreadCount++;
          this.updateMessage(message, 'read');
        }
      });
      if (unreadCount > 0) {
        this.user.metrics.unread_message -= unreadCount;
        this.utils.appState.user.next(this.user);
        this.userService.updateMetrics(this.user.id, { unread_message: this.user.metrics.unread_message }).subscribe();
      }

      if (data.results.length == 0) {
        this.flags.noResults = true;
      }
      this.messages = this.messages.concat(Message.fromList(data.results));
      this.flags.loading = false;
    });
  }

  

  updateMessage(message, status) {
    this.messageService.update(message.id, { status: status, room: message.room }).subscribe((data) => { });
  }

  archieveMessage(value) {
    let text = "Archieve";
    if(!value){
      text = "Unarchieve";
    }
    if (confirm(text + ' this Conversation?')) {
      this.messageService.archieve(this.conversationid, {archived:value}).subscribe((data) => { 
        this.utils.alert.success('Conversation ' + text + 'd');
        this.router.navigateByUrl('/messages');
      });
    }
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
