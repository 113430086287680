import { Component, Input, OnInit, SimpleChanges, OnDestroy, HostListener, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { BackgroundAnimations, inOutAnimation } from 'src/app/animations';
import { User } from 'src/app/models';
import { Post } from 'src/app/models/post';
import { UtilsService } from 'src/app/services';
import { PostService } from 'src/app/services/post.service';
import { Location } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-search-posts',
  templateUrl: './search-posts.component.html',
  styleUrls: ['./search-posts.component.scss'],
  animations: [BackgroundAnimations, inOutAnimation],
})
export class SearchPostsComponent implements OnInit, OnDestroy {
  // @Input() searchStr;
  @Input() slug;
  @Output() resultCount: EventEmitter<Number> = new EventEmitter();

  flags = {
    sfe: false, // Show Form Errors
    loading: false,
    btnSubmit: true,
    noResults: false,
  };

  loadLength: number = 30;
  resultOffset: number = 0;
  resultTotal: number = 0;

  private _searchStr;
  get searchStr(): any {
    return this._searchStr;
  }

  @Input()
  set searchStr(val: any) {
    // console.log('previous item = ', this._searchStr);
    // console.log('currently selected item=', val);
    this._searchStr = val;
  }

  posts: Post[] = [];
  user: User;
  subs: Subscription[] = [];

  constructor(
    private postService: PostService,
    public utils: UtilsService,
    public router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    this.route.params.subscribe(
      (params) => {
        if(this.slug == null){
          this.searchStr = params['searchStr']
        }
      }
    );

  }

  ngOnInit() { 
    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
      if(_.isEmpty(this.searchStr)){
        this.startSearch();
      }
    });
    this.subs.push(user);
    if(this.user == null){
      this.startSearch();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(!_.isEmpty(this.searchStr) ){
      this.startSearch();
    }
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    console.log(window.innerHeight + window.scrollY, '>=', document.body.offsetHeight / 1.3, (window.innerHeight + window.scrollY) >= document.body.offsetHeight / 1.3);
    if(this.slug == null){
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight / 1.3) {
        if (!this.flags.loading && !this.flags.noResults && _.isEmpty(this.searchStr)) {
          this.loadMore();
        }
      }
    }
  };

  swipeLeft(){
      this.router.navigateByUrl('/search/activities');
  }

  nth(i, size){
    return i % size === 0;
  }

  startSearch(){
    this.posts = [];
    this.resultOffset = 0;
    this.resultTotal = 0;
    this.getPosts('-datetime');
  }  

  loadMore() {
    this.resultOffset += this.loadLength;
    this.getPosts('-datetime');
  }

  back() {
    this.location.back();
  }

  openExternal(link) {
    this.router.ngOnDestroy();
    window.open(link, '_blank');
  }

  displayImage(post) {
    if (
      post.images != null &&
      post.images.photos != null &&
      post.images.photos[post.meta.imageNumber] != null
    ) {
      return post.images.photos[post.meta.imageNumber].image.sm;
    } else if (
      post.activity != null
      && post.activity.images != null
      && post.activity.images.photos != null
      && post.activity.images.photos.length > 0
    ) {
      let images = post.activity.images.photos;
      return images[post.meta.defaultImageNumber].image.sm;
    } else {
      return './assets/img/background/activities/default.jpg';
    }
  }

  // DATA

  getPostsByName(name) {
    this.postService.list({ search: name, limit: 10 }).subscribe((data) => {
      this.posts = data;
    });
  }

  getPosts(ordering?:string) {
    this.flags.loading = true;
    let filters: any = {};
    filters.fields= 'creator.username,images.photos,id,datetime,price,activity.name,activity.images.photos.image.sm';
    filters.expand = 'activity,creator,images';
    filters.offset = this.resultOffset;
    filters.limit = this.loadLength;
    filters.private = false;
    if(ordering){
      filters.ordering = ordering;
    }

    if (this.slug != null) {
      filters.activities = this.slug;
    }
    else if (this.searchStr != null) {
      filters.search = this.searchStr;
      // filters.activities = this.searchStr;
    }

    if(this.user != null){
      
      if (this.user.app_state.search_location) {
        filters.location_lat = this.user.location.location.coordinates[0];
        filters.location_lng = this.user.location.location.coordinates[1];
        filters.location_radius = 100;
      }      
      
      if (this.user.app_state.search_upcoming) {
        filters.datetime_after = moment().format('YYYY-MM-DD');
      }

      if (this.user.app_state.search_myactivities) {
        let activityids = this.user.activities.map((activity) => activity['activity'].slug);
        let activitybaseids = this.user.activities.filter((activity) => { 
          if(activity['activity'].group != null){
            return activity['activity'].group.slug;
          }
        }).map((activity) => activity['activity'].group.slug);
            activityids = activityids.concat(activitybaseids);
        let flatactivityids = activityids.join(',');
    
        filters.activities = flatactivityids;
      }      
      
    }

    this.postService.list(filters).subscribe((data) => {
      this.resultTotal = data.count;
      let unsortedPosts = Post.fromList(data.results);

      // ORDERING
      let posts = _.filter(unsortedPosts, post => !moment(post.datetime).isBefore(new Date())).reverse();
      posts = posts.concat(_.filter(unsortedPosts, post => moment(post.datetime).isBefore(new Date())));

      _.each(posts, (post) => {
        let randomTiming = Math.floor(Math.random() * 9);
        // let randomTiming = 0;
        post.meta = {};
        post.meta['timing'] = randomTiming;
        post.meta['imageNumber'] = 0;
        post.meta['past'] = false;
        if (moment(post.datetime).isBefore()) {
          post.meta['past'] = true;
        }
        if (post != null && post.activity != null && post.activity.images != null && post.activity.images.photos != null) {
          let randomImage = Math.floor(
            Math.random() * post.activity.images.photos.length
          );
          post.meta['defaultImageNumber'] = randomImage;
        }
      });

      if (data.results.length == 0) {
        this.flags.noResults = true;
      }

      if (!_.isEmpty(this.searchStr)) {
        this.posts = posts;
      }
      else {
        this.posts = this.posts.concat(posts);
      }
      this.resultCount.emit(this.posts.length);
      this.flags.loading = false;
    });
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
