import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import {
  BackgroundAnimations,
  inOutAnimation,
  openCloseTransition,
  routerTransition,
} from 'src/app/animations';
import { User } from 'src/app/models';
import { Image } from 'src/app/models/image';
import { Post } from 'src/app/models/post';
import { AlertService } from 'src/app/services';
import { ImageService } from 'src/app/services/image.service';
import { PostService } from 'src/app/services/post.service';
import { UtilsService } from '../../services/utils.service';
import { GeoLocation } from 'src/app/models/location';
import { BillingService } from 'src/app/services/billing.service';
import { environment } from 'src/environments/environment';

class ImageSnippet {
  pending: boolean = false;
  status: string = 'init';

  constructor(public src: string, public file: File) { }
}

@Component({
  selector: 'app-edit-post',
  templateUrl: './edit-post.component.html',
  styleUrls: ['./edit-post.component.scss'],
  animations: [
    routerTransition,
    inOutAnimation,
    openCloseTransition,
    BackgroundAnimations,
  ],
})
export class EditPostComponent implements OnInit, OnDestroy {
  @Input() post: Post;
  @Input() updatePostLocation: GeoLocation;

  user: User;
  subs: Subscription[] = [];
  form: FormGroup;
  submitted = false;
  locationDisplay: string;
  location: GeoLocation;
  flags = {
    sfe: false, // Show Form Errors
    loading: false,
    link: false,
    createPostBox: 'outLeft',
    btnSubmit: true,
    datePassed: true,
  };
  // activities: Activity[];
  durations: any = [
    { name: '15 Mins', value: '00:10:00' },
    { name: '30 Mins', value: '00:30:00' },
    { name: '45 Mins', value: '00:45:00' },
    { name: '1 Hour', value: '01:00:00' },
    { name: '75 Mins', value: '01:15:00' },
    { name: '1.5 Hours', value: '01:30:00' },
    { name: '2 Hours', value: '02:00:00' },
    { name: '2.5 Hour', value: '02:30:00' },
    { name: '3 Hour', value: '03:00:00' },
    { name: '3.5 Hour', value: '03:30:00' },
    { name: '4 Hour', value: '04:00:00' },
    { name: '4.5 Hour', value: '04:30:00' },
    { name: '5+ Hours', value: '05:00:00' },
  ];
  times: string[];
  partysizes: any = [1, 2, 3, 4, 5, 10, 25, 50, 88];
  minDate: Date;
  maxDate: Date;
  startDate: Date;
  selectedFile: ImageSnippet;
  images: Image[] = [];
  postCount: number;
  prices: any = ['Free', 1, 2, 3, 5, 'Verify to Unlock'];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private utils: UtilsService,
    private alertService: AlertService,
    private postService: PostService,
    private imageService: ImageService,
    private billingService: BillingService
  ) {
    // this.post = new Post('');
    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
      this.user.activities = _.sortBy(this.user.activities, ['activity.name']);
      // this.initLocation();
      if(!this.user.is_verified && this.user.kudos == 2){
        this.prices = ['Free', 1, 2, 3, 5, 8, 10, 'Verify to Unlock'];
      }
      else if(!this.user.is_verified && this.user.kudos == 3){
        this.prices = ['Free', 1, 2, 3, 5, 8, 10, 15, 'Verify to Unlock'];
      }
      else if(!this.user.is_verified && this.user.kudos == 4){
        this.prices = ['Free', 1, 2, 3, 5, 8, 10, 15, 20, 'Verify to Unlock'];
      }
      else if(!this.user.is_verified && this.user.kudos == 5){
        this.prices = ['Free', 1, 2, 3, 5, 8, 10, 15, 20, 25, 'Verify to Unlock'];
      }
      else if(!this.user.is_verified && this.user.kudos == 6){
        this.prices = ['Free', 1, 2, 3, 5, 8, 10, 15, 20, 25, 30, 'Verify to Unlock'];
      }
      else if(!this.user.is_verified && this.user.kudos == 7){
        this.prices = ['Free', 1, 2, 3, 5, 8, 10, 15, 20, 25, 30, 35, 'Verify to Unlock'];
      }
      else if(!this.user.is_verified && this.user.kudos == 8){
        this.prices = ['Free', 1, 2, 3, 5, 8, 10, 15, 20, 25, 30, 35, 40, 'Verify to Unlock'];
      }
      else if(!this.user.is_verified && this.user.kudos == 9){
        this.prices = ['Free', 1, 2, 3, 5, 8, 10, 15, 20, 25, 30, 35, 40, 42, 45, 'Verify to Unlock'];
      }
      else if(!this.user.is_verified && this.user.kudos == 10){
        this.prices = ['Free', 1, 2, 3, 5, 8, 10, 15, 20, 25, 30, 35, 40, 42, 45, 50, 'Verify to Unlock'];
      }

      if(this.user.is_verified){
        this.prices = ['Free', 1, 2, 3, 5, 8, 10, 15, 20, 25, 30, 35, 40, 42, 45, 50, 60, 70, 80, 88, 90, 100];
      }
    });
    this.subs.push(user);

    const postcount = this.utils.appState.postCount.subscribe((resp) => {
      this.postCount = resp;
    });
    this.subs.push(postcount);

    this.times = this.createTimes();
  }

  ngOnInit() {
    if(this.post != null){
      this.setupForm();
      this.initLocation();
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes != null && changes.updatePostLocation != null && changes.updatePostLocation.currentValue != null){
      let location = changes.updatePostLocation.currentValue;
      this.location = location;
      this.locationDisplay = location.place;
      this.f.location.setValue(this.location);
      this.f.locationDisplay.setValue(this.locationDisplay);
      if(this.f != null){
        this.f.locationDisplay.enable();
      }
    }
  }

  initLocation() {
    if (this.post) {
      this.location = this.post.location;
      if(this.post.location.place != null){
        this.locationDisplay = this.post.location.place;
      }
      else{
        this.locationDisplay = this.user.location.city + ', ' + this.user.location.state;
      }
      
      this.f.location.setValue(this.location);
      this.f.locationDisplay.setValue(this.locationDisplay);
      }
  }

  setLocation() {
    let name = 'unknown-activity';
    if (this.f.activityModel.value.name != null) {
      name = this.f.activityModel.value.name;
    }
    this.user.app_state.showSetLocation = name;
    this.utils.appState.user.next(this.user);
    this.f.locationDisplay.disable();
  }

  setupForm() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const inOneMonth = new Date();
    this.minDate = new Date(new Date());
    this.maxDate = new Date(inOneMonth.setMonth(inOneMonth.getMonth() + 3));
    // this.startDate = new Date(tomorrow);

    this.matchTimes();

    if (this.post != null) {
      if (!_.isEmpty(this.post.url)) {
        this.flags.link = true;
      }
      this.flags.datePassed = moment(this.post.datetime).isBefore();
      if(this.flags.datePassed){
        this.minDate = new Date(inOneMonth.setMonth(inOneMonth.getMonth() - 3000));
      }
      if(this.post.price == '0.00'){
        this.post.price = 'Free';
      }
      else{
        this.post.price = Math.floor(Number(this.post.price)).toString();
      }
      this.form = this.formBuilder.group({
        activityModel: [this.post.activity, Validators.required],
        activity: [this.post.activity.id],
        date: [this.post.datetime, Validators.required],
        time: [this.post.time, Validators.required],
        duration: [this.post.duration, Validators.required],
        size: [this.post.size, Validators.required],
        locationDisplay: [this.locationDisplay],
        location: [this.location],
        tags: [this.post.tags.join(' ')],
        price: [this.roundPrice(this.post.price)],
        description: [this.post.description],
        url: [this.post.url],
        published: [this.post.published],
        invitees: [''],
        private: [this.post.private],
        creator: [this.user.id],
        datetime: [''],
        image: [''],
      });
    }

    if (this.f.activity.value != '') {
      this.activateBtn();
    }
  }

  roundPrice(price){
    if(!isNaN(price)){
      return Math.floor(Number(price));
    }
    else{
      return 'Free';
    }
  }

  matchDuration() {
    // console.log(this.post.duration);
    let duration = moment.duration(this.post.duration).asMinutes();
    // console.log(duration);
    let durationstring = duration.toString().replace('0.', '');
    // console.log(durationstring);

    // this.post.duration = parseInt(durationstring);
  }

  setNearestTime() {
    let nearest = this.times
      .map(function (s) {
        return moment(s, 'h:mma');
      })
      .sort(function (m) {
        return m.valueOf();
      })
      .find(function (m) {
        return m.isAfter();
      });

    if (nearest) {
      this.post.time = nearest.format('h:mma');
      // console.log("Next time is", nearest.format("hh:mma"), "which is", nearest.fromNow());
    } else {
      this.post.time = '10:00am';
    }
  }

  matchTimes() {
    if (this.post != null) {
      this.times.forEach((time) => {
        let posttime = moment(this.post.datetime).format('h:mma');
        if (posttime === time) {
          this.post.time = time;
        }
      });
    }
  }

  createTimes() {
    var x = 15; //minutes interval
    var times = []; // time array
    var tt = 240; // start time
    var ap = ['AM', 'PM']; // AM-PM

    //loop to increment the time and push results in array
    for (var i = 0; tt < 24 * 60; i++) {
      var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      var mm = tt % 60; // getting minutes of the hour in 0-55 format
      times.push(moment(hh + ':' + mm, 'h:mm').format('h:mma'));
      // times[i] = ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh/12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
      tt = tt + x;
    }
    return times;
  }

  togglePostBox(noSound?) {
    if (!noSound) {
      this.utils.sound();
    }
    this.user.app_state['accountBox'] = 'outRight';
    this.user.app_state['createPostBox'] = this.user.app_state['createPostBox'] === 'outLeft' ? 'in' : 'outLeft';
  }

  get f() {
    return this.form.controls;
  }

  priceChange(value){
    this.utils.sound('next')
    // if(value != 'Free' && !this.user.is_premium){
    //   this.router.navigateByUrl('/settings/plans');
    // }
    // else if(this.user.metrics.kudos <= 0 && value != 'Free'){
    //   this.alertService.error('Must have 1 kudos');
    //   this.f.price.setValue('Free');
    // }
    if(value == 'Verify to Unlock' && !this.user.is_verified){
      if(this.user.verification_status == null || (this.user.verification_status != null && this.user.verification_status == 'Not Started')){
        this.verify();
      }
      else{
        this.utils.alert.message(this.user.verification_status);
      }
    }
  }

  verify() {
    this.billingService
      .verifyID({ return_url: 'https://activity8.app/dashboard/verifing' })
      .subscribe((data) => {
        window.location = data.redirect_url;
      });
  }

  onSubmit(change?) {
    if (change && this.post != null && this.post.id != null) {
      return;
    }

    this.submitted = true;

    if (this.form.invalid || this.f.activityModel.value == 0) {
      this.f.activityModel.invalid;
      this.flags.sfe = true;
      return;
    }

    const timezone = moment(this.f.date.value);
    const time = moment(this.f.time.value, ["h:mm a"]);
    const localtz = moment.tz.guess(this.f.date.value);
    const date = timezone.clone().tz(localtz);
    const datetime = moment(date);
    datetime.set('hour', time.hours());
    datetime.set('minute', time.minutes());
    datetime.set('second', 0);
    datetime.set('millisecond', 0);

    this.form.patchValue({ datetime: datetime });
    // this.form.patchValue({ activity: this.f.activityModel.value.id });
    if(this.f.price.value == 'Free'){
      this.form.patchValue({ price: 0 });
    }

    this.flags.btnSubmit = true;

    let tagsValue = this.f.tags.value;
    if(tagsValue.length == 0){
      tagsValue = [];
    }
    else if(!Array.isArray(tagsValue) ){
      tagsValue = this.f.tags.value.split(' ');
    }
    else{
      tagsValue = this.f.tags.value;
    }
    this.form.patchValue({ tags:tagsValue });
    this.form.patchValue({ invitees:[this.user.id] });
    
    this.form.patchValue({ published: true });
    // this.form.patchValue({ location: this.location });
    this.updatePost(this.form.value);
  }

  activateBtn() {
    if (!this.form.invalid) {
      this.flags.btnSubmit = false;
    } else {
      this.flags.btnSubmit = true;
    }
  }

  private onSuccess() {
    this.selectedFile.pending = false;
    this.alertService.success('Uploaded', true);
    this.selectedFile.status = 'ok';
  }

  private onError() {
    this.selectedFile.pending = false;
    this.alertService.error('Upload failed', true);
    this.selectedFile.status = 'fail';
    this.selectedFile.src = '';
  }

  processFile(imageInput: any) {
    if (imageInput) {
      const file: File = imageInput.files[0];
      const reader = new FileReader();

      reader.onloadend = (event:any) => {
        if(reader.error){
          console.log(reader.error)
        } else {

          this.selectedFile = new ImageSnippet(event.target.result, file);

        this.selectedFile.pending = true;
          this.imageService.create({ collection_type: 'post-bg', resource_id: this.post.id }, this.selectedFile.file).subscribe(
            (data) => {
              this.images.push(new Image(data));
              // console.log(this.images);
              this.onSuccess();
            },
            (err) => {
              this.onError();
            }
          );
        }
       }

      reader.readAsDataURL(file);
    }
  }

  // DATA

  updatePost(data) {
    this.utils.sound('yes');
    this.postCount++;
    this.postService.update(this.post.id, data).subscribe(
      (data) => {
        this.alertService.success('Updated', true);
        this.togglePostBox(true);
        this.initLocation();
        this.setupForm();
        this.router.navigateByUrl( this.user.username);
      },
      (error) => {
        this.utils.showServerErrorsInForm(error, this.form);
        this.flags.btnSubmit = false;
      }
    );
  }

  delete() {
    if (confirm('Delete post?')) {
      this.togglePostBox();
      this.locationDisplay = this.user.location.city + ', ' + this.user.location.state;
      this.location = this.user.location;
      this.postService.delete(this.post.id).subscribe();
      this.post = null;
      this.setupForm();
      this.router.navigateByUrl('/dashboard');
      // this.router.navigateByUrl( this.user.username);
    }
  }

  deleteImage(image) {
    if (confirm('Delete image?') && this.post.accepted_invite_count == 0) {
      if (
        this.post.images != null &&
        this.post.images.photos != null &&
        this.post.images.photos.length > 0
        || this.images != null
      ) {

        if (image != null) {
          this.imageService.delete(image.id).subscribe((data) => {
            // REMOVING ON CREATE
            if (this.images != null) {
              _.remove(this.images, (obj) => obj.id == image.id);
            }
            // REMOVING ON EDIT
            if (this.post.images != null) {
              _.remove(this.post.images.photos, (obj) => obj.id == image.id);
            }
          });
        }
      }
    }
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
