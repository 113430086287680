import { Activity } from './activity';
import { GeoLocation } from './location';
import * as _ from 'lodash';

export class User {
  id: number;
  username?: string;
  first_name: string;
  last_name?: string;
  full_name?: string;
  timezone?: string;
  gender?: string;
  orientation?: string;
  email: string;
  change_email_to: string;
  location?: GeoLocation;
  website?: string;
  phone?: string;
  dob?: string;
  // birthday?: string;
  tagline?: string;
  img?: string = 'face.jpg';
  // thumbnail?: string = 'face.jpg';
  thumbnail?: { sm, md };
  settings?: { web_notifications?: boolean, share_location?: boolean, disable_sounds?: boolean };
  profile_pics?: any;
  bg_pics?: string;
  color?: string;
  likes?: number;
  posts?: number;
  partners?: number;
  kudos?: number;
  matchCount?: number;
  metrics?: any;
  membership?: string;
  verification_status?: string;
  is_verified?: boolean;
  is_premium: boolean;
  is_private: boolean;
  allow_guide: boolean;
  has_bank_account: boolean;
  
  is_single: boolean;
  is_smoker: boolean;
  is_drinker: boolean;
  is_straight: boolean;
  emergency_contact: boolean;
  emergency_contact_cell: string;
  emergency_contact_name: string;
  referral_code: string;

  meta: any;

  activities?: Activity[];
  interests?: Activity[];

  last_login?: Date;
  last_activity?: Date;
  date_joined?: Date;
  agree_terms?: Date;
  role?: any;
  email_verified?: boolean;
  permissions?: string[];
  current_org?: object;
  app_state?: any = {
    home: 'in',
    createPostBox: 'outLeft',
    createPostBoxActivity: null,
    createPostBoxBackBlur: 'out',
    homeBackground: 'off',
    homeCover: 'out',
    homeGender: 'in',
    accountBox: 'outRight',
    showSetLocation: null,
    setActivityLocation: null,
    showSetActivities: null,
    showSetExperience: null,
    showSetDays: null,
    startConversation: null,
    addComment: null,
    search_gender: null,
    search_is_single: null,
    search_is_straight: null,
    search_is_drinker: null,
    search_is_smoker: null,
    search_last_login: true,
    search_last_activity: true,
    search_date_joined: false,
    search_location: false,
    search_upcoming: false,
    search_myactivities: false,
  };

  bgImg?: string;

  constructor(data: Object) {
    if(data['app_state'] != null){
      data['app_state'] = JSON.parse(String(data['app_state']));
    }
    Object.assign(this, data);
    if (location == null) {
      this.location = new GeoLocation({ status: '' });
    }
    // SETUP PREMIUM
    this.is_premium = false;
    if (this.membership == 'Premium') {
      this.is_premium = true;
    }

    // SETUP STRAIGHT
    if (this.orientation == 'S') {
      this.is_straight = true;
    }
    else if (this.orientation == 'G') {
      this.is_straight = false;
    }
  }

  public static fromList(datalist: object[]) {
    return _.map(datalist, item => {
      let user = new User(item);
      if (user.membership == 'Premium') {
        user.is_premium = true;
      }
      else {
        user.is_premium = false;
      }
      return user;
    });
  }

  hasPerm(perm:string){
    if(this.permissions != null){
      return this.permissions.indexOf(perm) >= 0;
    }
  }

  // isGuide(){
  //   return this.hasPerm('post.create_paid_post');
  // }

  // allowGuide(){
  //   return this.hasPerm('accounts.add_bank_account');
  // }

  get isAuthenticated() {
    return true;
  }

  zeroInteractions(args): boolean {
    let resp = true;
    _.each(args, arg => {
      if (arg > 0 && (arg == args['comments_by_me'] || arg == args['comments_for_me'] || arg == args['likes_by_me'] || arg == args['likes_for_me'])) {
        resp = false;
      }
    })
    return resp;
  }

  // setLocation(args) {
  //   Object.assign(this, args);
  // }

}
