<mat-progress-bar mode="indeterminate" class="locationProgress" *ngIf="flags.loading"></mat-progress-bar>


<!-- <div class="items"> -->
  <div class="items animate__animated animate__fadeIn" *ngIf="user?.app_state?.showSetLocation">
      
    <ng-container *ngIf="flags.map">
      <div class="item map height90">
        <div class="btns">
          <button mat-button [class.ios]="utils.ios()" class="switchBtn" (click)="toggleMap()" *ngIf="!flags.viewMapOnly"><mat-icon>location_on</mat-icon> Show locations</button>
          <button mat-button [class.ios]="utils.ios()" class="switchBtn" (click)="locate(true)"><mat-icon>gps_fixed</mat-icon> Locate</button>
          <button mat-button [class.ios]="utils.ios()" class="switchBtn" *ngIf="flags.viewMapOnly"><mat-icon>map</mat-icon> Location</button>
          <button mat-button [class.ios]="utils.ios()" class="closeBtn" (click)="close()"><mat-icon>close</mat-icon></button>
        </div>
        <br clear="all" />
        
        <agm-map (mapClick)="mapClick($event)" [class.viewOnly]="flags.viewMapOnly" [class.ios]="utils.ios()"
          [latitude]="mapLat" [longitude]="mapLong" [zoom]="16" [streetViewControl]="false" [mapTypeControl]="false" 
          [fullscreenControl]="true" [clickableIcons]="false" [gestureHandling]="'greedy'"
          >
          <agm-marker [latitude]="mapLat" [longitude]="mapLong"></agm-marker>
          <!-- <agm-marker [latitude]="mapLat" [longitude]="mapLong" [iconUrl]="'./assets/icons/icon-128x128.png'"></agm-marker> -->
       </agm-map>

        <!-- <agm-map [latitude]="lat" [longitude]="lng">
          <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
        </agm-map> -->
      </div>
      <button mat-button color="primary" class="item btnFullBottom height10" (click)="mapSetLocation()" *ngIf="!flags.viewMapOnly">CHOOSE</button>
      <!-- <button [disabled]="flags?.btnDoneDisabled" mat-button color="primary" class="item" (click)="true">CHOOSE</button> -->
    </ng-container>
  
    <ng-container *ngIf="!flags.map">
      <div class="item">
        <button mat-button [class.ios]="utils.ios()" class="switchBtn" (click)="toggleMap()" *ngIf="flags.allowMap"><mat-icon>map</mat-icon> Show map</button>
        <button mat-button [class.ios]="utils.ios()" class="switchBtn" (click)="locate(true)" *ngIf="user?.settings?.share_location && !flags.settingActivity"><mat-icon>gps_fixed</mat-icon> Locate</button>
        <button mat-button [class.ios]="utils.ios()" class="closeBtn" (click)="close()"><mat-icon>close</mat-icon></button>      
        <div class="question" *ngIf="mode != 'interest'">
          <mat-icon>location_on</mat-icon><br />
          Set location
        </div>
      </div>
      <div class="item locations">
        <form [formGroup]="form" (ngSubmit)="clickAddLocation()">
          <!-- <mat-form-field class="example-chip-list"> -->
            <!-- <mat-chip-list #chipList aria-label="Activities">
              <mat-chip
                *ngFor="let activity of activities"
                [class.accentPill]="activity?.activity?.is_premium"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(activity)">
                {{activity?.activity?.name}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input
                placeholder="Enter/Select activities..."
                #activityInput
                [formControl]="locationCtrl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)">
            </mat-chip-list> -->
            <!-- <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
              <mat-option *ngFor="let location of filteredLocations | async" [value]="activity">
                {{location['fields'].city}}, {{location['fields'].state}}
              </mat-option>
            </mat-autocomplete> -->
  
            <mat-form-field>
            <input placeholder="Enter a location" matInput #locationInput [formControl]="locationCtrl" [matAutocomplete]="auto" (focus)="locationCtrl.setValue('')">
            </mat-form-field>
  
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selected($event)">
            <mat-option *ngFor="let location of filteredLocations | async" [value]="location">
              {{location.name}}<span *ngIf="location?.name">, </span>{{location?.place?.properties?.street}}<span *ngIf="location?.place?.properties?.street">, </span>{{location?.place?.properties?.stateCode}}
              <!-- {{location.place}} -->
              <!-- {{location?.place?.properties?.city}}, {{location?.place?.properties?.stateCode}} -->
              </mat-option>
            </mat-autocomplete>
  
          <!-- </mat-form-field> -->
          <!-- <button mat-button mat-flat-button (click)="addFromButton(locationCtrl)" class="addBtn" [disabled]="locationCtrl?.value == null || locationCtrl?.value == '' || locationCtrl?.value?.length <= 0"><mat-icon>keyboard_return</mat-icon></button> -->
  
          <p *ngIf="suggestedLocations?.length > 0">Suggestions</p>
          <button *ngFor="let location of suggestedLocations" mat-button type="button" class="pill" (click)="clickPill(location)">
          <!-- {{ location['fields']?.city }} -->
          {{ location.name }}
          </button>
  
          <br clear="all" />
        </form>
        <br />
      </div>
    </ng-container>
  
  </div>