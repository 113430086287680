import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { DayGroups } from 'src/app/directives/pipe.component';
import { Activity, User } from 'src/app/models';
import { UtilsService } from 'src/app/services';
import { ActivityService } from 'src/app/services/activity.service';

@Component({
  selector: 'app-activity-days',
  templateUrl: './activity-days.component.html',
  styleUrls: ['./activity-days.component.scss'],
})
export class ActivityDaysComponent implements OnInit, OnDestroy {
  @Input() activity: Activity;
  @Output() change: EventEmitter<any>;

  flags = {
    loading: false,
    btnDone: false,
    animation: 'start',
  };
  user: User;
  subs: Subscription[] = [];
  sun: boolean;
  mon: boolean;
  tues: boolean;
  wed: boolean;
  thurs: boolean;
  fri: boolean;
  sat: boolean;
  weekdays: boolean;
  weekends: boolean;
  weekdaysIncomplete: boolean;
  weekendsIncomplete: boolean;

  constructor(
    private utils: UtilsService,
    private activityService: ActivityService,
    private dayGroups: DayGroups
  ) {
    this.change = new EventEmitter();

    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
    });
    this.subs.push(user);
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.post = changes.post.currentValue;
    this.setupForm();
  }

  ngOnInit() {
  }

  setupForm() {
    if (this.activity) {
      this.sun = this.activity.days.includes('Su') ? true : false;
      this.mon = this.activity.days.includes('Mo') ? true : false;
      this.tues = this.activity.days.includes('Tu') ? true : false;
      this.wed = this.activity.days.includes('We') ? true : false;
      this.thurs = this.activity.days.includes('Th') ? true : false;
      this.fri = this.activity.days.includes('Fr') ? true : false;
      this.sat = this.activity.days.includes('Sa') ? true : false;
    }

    this.enableBtn();
  }

  enableBtn() {
    if (
      this.sun ||
      this.mon ||
      this.tues ||
      this.wed ||
      this.thurs ||
      this.fri ||
      this.sat
    ) {
      this.flags.btnDone = false;
    } else {
      this.flags.btnDone = true;
    }

    if (this.mon && this.tues && this.wed && this.thurs && this.fri) {
      this.weekdays = true;
      this.weekdaysIncomplete = false;
    } else if (this.mon || this.tues || this.wed || this.thurs || this.fri) {
      this.weekdays = false;
      this.weekdaysIncomplete = true;
    } else {
      this.weekdays = false;
      this.weekdaysIncomplete = false;
    }

    if (this.sat && this.sun) {
      this.weekends = true;
      this.weekendsIncomplete = false;
    } else if (this.sat || this.sun) {
      this.weekends = false;
      this.weekendsIncomplete = true;
    } else {
      this.weekends = false;
      this.weekendsIncomplete = false;
    }
  }

  toggleWeekends() {
    let any = this.sat || this.sun;
    let all = this.sat && this.sun;
    if ((any || !all) && this.weekends) {
      this.weekends = true;
      this.weekendsIncomplete = false;
      this.sat = true;
      this.sun = true;
    } else {
      this.weekends = false;
      this.weekendsIncomplete = false;
      this.sat = false;
      this.sun = false;
    }
    this.enableBtn();
  }

  toggleWeekdays() {
    let any = this.mon || this.tues || this.wed || this.thurs || this.fri;
    let all = this.mon && this.tues && this.wed && this.thurs && this.fri;
    if ((any || !all) && this.weekdays) {
      this.weekdays = true;
      this.weekdaysIncomplete = false;
      this.mon = true;
      this.tues = true;
      this.wed = true;
      this.thurs = true;
      this.fri = true;
    } else {
      this.weekdays = false;
      this.weekdaysIncomplete = false;
      this.mon = false;
      this.tues = false;
      this.wed = false;
      this.thurs = false;
      this.fri = false;
    }
    this.enableBtn();
  }

  days() {
    let days: string[] = [];
    if (this.mon) {
      days.push('Mo');
    }
    if (this.tues) {
      days.push('Tu');
    }
    if (this.wed) {
      days.push('We');
    }
    if (this.thurs) {
      days.push('Th');
    }
    if (this.fri) {
      days.push('Fr');
    }
    if (this.sat) {
      days.push('Sa');
    }
    if (this.sun) {
      days.push('Su');
    }
    return days.join(',');
  }

  save() {
    this.utils.sound('next');
    this.activity.days = this.days();
    this.activityService.update(this.activity.id, { days: this.activity.days }).subscribe((r) => { });

    this.flags.animation = 'end';
    this.utils.delay(800).then(() => {
      this.flags.animation = 'start';
      this.user.app_state.showSetDays = 'out';
      this.utils.appState.user.next(this.user);
      this.change.emit(false);
    });

  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
