<div class="items animate__animated" [class.animate__zoomInDown]="flags?.state == 'in'" [class.animate__backOutUp]="flags?.state == 'send'" *ngIf="user?.app_state?.addComment != null">

  <div class="item">
    <button mat-button class="closeBtn" (click)="user.app_state.addComment = null" *ngIf="post"><mat-icon>close</mat-icon></button>

    <ng-container>
      <br />
      <form [formGroup]="form" (ngSubmit)="addComment()">
      <mat-form-field>
        <textarea matInput placeholder="Write comment" (keyup)="activateBtn()" (change)="activateBtn()" type="text" class="form-control" formControlName="message" [ngClass]="{ 'is-invalid': flags.sfe && f.invalid }">
        </textarea>
        <mat-error *ngIf="f.message.hasError('serverError')">
          <small>{{f.message.errors.serverError}}</small>
        </mat-error>
        <mat-error *ngIf="f.message.hasError('message')">
          <small>Message contains unallowed words.</small>
        </mat-error>
      </mat-form-field>
      </form>
    </ng-container>
  </div>

  <button [disabled]="flags?.btnAdding" mat-button color="primary" class="item" (click)="addComment()">
    POST <mat-icon>post</mat-icon>
  </button>

</div>
