import * as _ from 'lodash';

export class GeoLocation {
 // id: number;
 name?: string;
 city?: string;
 county?: string;
 state?: string;
 country?: string;
 // type?: string;
 // coordinates?: any;
 location?: { status?: string, city?: string, type?: string, coordinates?: any };
 longitude?: string;
 latitude?: string;
 status?: string;
 fields?: any = {};
 place?: any;

 constructor(data: Object) {
  Object.assign(this, data);
  if (this.location == null) {
   this.location = { status: '' };
  }
 }

 public static fromList(datalist: object[]) {
  return _.map(datalist, item => {
   return new GeoLocation(item);
  });
 }

 setLocation?(coordinates, type?: string) {
  if (type == null) { type = 'Point'; }

  this.location = {};
  this.location.type = type;
  this.location.coordinates = coordinates;
 }

}
