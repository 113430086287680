import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as rxOp from 'rxjs/operators';
import { UtilsService } from './utils.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private http: HttpClient, private utils: UtilsService) {}

  getNotifications(qp?: Object): Observable<any> {
    qp['pager'] = 'no';
    const url = this.utils.getUrl('rest:notifications-list');
    return this.http.get(url, this.utils.requestOptions(qp));
  }

  updateNotifications(id: number, obj: Object, qp?: Object): Observable<any> {
    const url = this.utils.getUrl('rest:notifications-detail', id);
    return this.http.patch(url, obj, this.utils.requestOptions(qp)).pipe(
      rxOp.map((resp) => {
        return resp;
      })
    );
  }

  addPushSubscriber(obj: any, browser: string, qp?: Object): Observable<any> {
    let data = obj.toJSON();
    let newData = {
      registration_id: data['endpoint'].split('/').reverse()[0],
      expirationTime: data['expirationTime'],
      p256dh: data['keys']['p256dh'],
      auth: data['keys']['auth'],
      browser: browser,
    };
    const url = this.utils.getUrl('rest:device-web-list');
    return this.http.post(url, newData, this.utils.requestOptions(qp));
  }

  contactUs(obj: any): Observable<any> {
    const url = this.utils.getUrl('rest:contact-us-list');
    return this.http.post(url, obj).pipe(
      rxOp.map((resp) => {
        return resp;
      })
    );
  }

  // listStockNotifcations(qp?: Object): Observable<PaginatedList> {
  //   const url = this.utils.getUrl('tracked-stocks-list');
  //   return this.http
  //     .get<PaginatedList>(url, this.utils.requestOptions(qp))
  //     .pipe(rxOp.map((resp: PaginatedList) => {
  //       // resp.results = QuoteList.fromList(resp.results);
  //       return resp;
  //     }));
  // }

  // addStockNotification(obj: Object, qp?: Object): Observable<any> {
  //   const url = this.utils.getUrl('tracked-stocks-list');
  //   return this.http
  //     .post(url, obj, this.utils.requestOptions(qp));
  // }

  // updateNotification(id: number, obj: Object, qp?: Object): Observable<any> {
  //   const url = this.utils.getUrl('tracked-stocks-detail', id);
  //   return this.http.patch(url, obj, this.utils.requestOptions(qp))
  //     .pipe(rxOp.map(resp => {
  //       return resp;
  //     }));
  // }

  // deleteStockNotification(id:number, qp?: Object): Observable<any> {
  //   const url = this.utils.getUrl('tracked-stocks-detail', id);
  //   return this.http.delete(url, this.utils.requestOptions(qp));
  // }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a StockService message with the MessageService */
  private log(message: string) {
    console.log(message);
  }
}
