<ng-container *ngIf="router.url.includes('/members') || router.url.includes('/posts') || router.url.includes('/search')">
    <div class="settingsComponent">
        <div class="toggleItem">Near by</div>
        <mat-slide-toggle labelPosition="before" (change)="toggleLocation();utils.sound('toggle');" [ngModel]="user?.app_state.search_location"></mat-slide-toggle>
        <br />
        <div class="toggleItem">My Activities</div>
        <mat-slide-toggle labelPosition="before" (change)="toggleMyActivities();utils.sound('toggle');" [ngModel]="user?.app_state.search_myactivities"></mat-slide-toggle>

        <ng-container *ngIf="router.url.includes('/posts') || router.url == '/search'">
            <br />
            <div class="toggleItem">Upcoming</div>
            <mat-slide-toggle labelPosition="before" (change)="toggleUpcoming();utils.sound('toggle');" [ngModel]="user?.app_state.search_upcoming"></mat-slide-toggle>
        </ng-container>


        <ng-container *ngIf="router.url.includes('/members')">
            <br />
            <div class="toggleItem">Recently Active</div>
            <mat-slide-toggle labelPosition="before" (change)="toggleActive();utils.sound('toggle');" [ngModel]="user?.app_state.search_last_activity"></mat-slide-toggle>
    
            <ng-container *ngIf="flags.moreSettings">
                <br />
                <div class="toggleItem">Recently Joined</div>
                <mat-slide-toggle labelPosition="before" (change)="toggleJoined();utils.sound('toggle');" [ngModel]="user?.app_state.search_date_joined"></mat-slide-toggle>
    
                <br />
                <div class="toggleItem">Gender</div>
                <div class="gender">
                    <a (click)="toggleGender()" *ngIf="user.app_state.search_gender == 'F'">Woman</a>
                    <a (click)="toggleGender()" *ngIf="user.app_state.search_gender == 'M'">Man</a>
                    <a (click)="toggleGender()" *ngIf="user.app_state.search_gender == null">Indifferent</a>
                </div>
    
                <br />
                <div class="toggleItem">Single</div>
                <mat-checkbox class="inlineBtn" [matTooltip]="user.app_state.search_is_single === null ? 'Indifferent' :user.app_state.search_is_single === true ? 'Single' : 'Not Single'" [checked]="user.app_state.search_is_single" [indeterminate]="singleNull()" (change)="toggleSingle()"></mat-checkbox><br />
    
                <div class="toggleItem">Straight</div>
                <mat-checkbox class="inlineBtn" [matTooltip]="user.app_state.search_is_straight === null ? 'Indifferent' :user.app_state.search_is_straight === true ? 'Straight' : 'Not Straight'"  [checked]="user?.app_state.search_is_straight" [indeterminate]="straightNull()" (change)="toggleStraight()"></mat-checkbox><br />
    
                <div class="toggleItem">Alcohol</div>
                <mat-checkbox class="inlineBtn" [matTooltip]="user.app_state.search_is_drinker === null ? 'Indifferent' :user.app_state.search_is_drinker === true ? 'Alcohol' : 'No Alcohol'"  [checked]="user?.app_state.search_is_drinker" [indeterminate]="drinkerNull()" (change)="toggleDrinker()"></mat-checkbox><br />
    
                <div class="toggleItem">Smoker/Weed/Vape</div>
                <mat-checkbox class="inlineBtn" [matTooltip]="user.app_state.search_is_smoker === null ? 'Indifferent' :user.app_state.search_is_smoker === true ? 'Smoker/Weed/Vape' : 'No Smoker/Weed/Vape'"  [checked]="user?.app_state.search_is_smoker" [indeterminate]="smokerNull()" (change)="toggleSmoker()"></mat-checkbox><br />
            </ng-container>       
        
        </ng-container>
    </div>

    <button mat-button (click)="flags.moreSettings = !flags.moreSettings" class="more" *ngIf="router.url.includes('/members')">
        <mat-icon *ngIf="!flags.moreSettings">arrow_drop_down</mat-icon>
        <mat-icon *ngIf="flags.moreSettings">arrow_drop_up</mat-icon>
    </button>
</ng-container>

