<!-- <div fxLayout="row" fxLayoutAlign="center center" fxFlex class="createPostComponent" [@slideInOut]=user?.app_state?.createPostBox> -->
  <mat-progress-bar mode="indeterminate" class="loginProgress" *ngIf="flags.loading || (selectedFile != null && selectedFile.pending)"></mat-progress-bar>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">

  <mat-card class="createPostComponent" [@slideInOut]="user?.app_state?.createPostBox">
    <button mat-button type="button" class="closeBtn" (click)="togglePostBox()"><mat-icon>close</mat-icon></button>
  <!-- <mat-icon class="cardTab cardTabRight" (click)="togglePostBox()">edit</mat-icon>
    <mat-icon class="cardTab cardTabRight explode" (click)="togglePostBox()">edit</mat-icon> -->

  <!-- fxFlex.xs="80%" fxFlex.sm="50%" fxFlex.md="40%" fxFlex.lg="30%" fxFlex="15%" -->

  <mat-card-content class="loginCardContent">
    <!-- <div class="text-right"><img width="55%" src="./assets/img/logo2019.png"></div> -->
    <h1>Edit post <mat-icon>date_range</mat-icon></h1>

      <div fxLayout="row" fxLayoutGap="20px">
        <div fxFlex="60">
          <mat-form-field>
            <mat-select placeholder="Activity" formControlName="activity" (selectionChange)="onSubmit(true)" class="form-control" [disabled]="post?.accepted_invite_count > 0" [ngClass]="{ 'is-invalid': flags.sfe && f.activityModel.invalid }">
              <!-- <mat-option value=''>Select</mat-option> -->
              <mat-option *ngFor="let activities of user?.activities" [value]="activities['activity'].id">
                {{activities['activity'].name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="35">
          <mat-form-field>
            <mat-select placeholder="Duration" formControlName="duration">
              <mat-option *ngFor="let duration of durations" [value]="duration.value">
                {{duration.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="20px">
        <div fxFlex="60">
          <mat-form-field>
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" [disabled]="post?.accepted_invite_count > 0" (keyup)="activateBtn()" (change)="activateBtn()" type="text" formControlName="date" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.date.errors }">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker [startAt]="startDate"></mat-datepicker>
            <mat-error *ngIf="f.date.hasError('serverError')">
              <div>{{f.date.errors.serverError}}</div>
            </mat-error>
            <mat-error *ngIf="f.date.hasError('birthday')">
              <div>Invalid entry.</div>
            </mat-error>
            <mat-error *ngIf="f.date.hasError('required')">
              <div>Required field.</div>
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="35">
          <mat-form-field>
            <mat-select placeholder="Time" formControlName="time">
              <mat-option *ngFor="let time of times" [value]="time">
                {{time}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="20px">
        <div fxFlex="60">
          <mat-form-field (click)="setLocation()">
            <input matInput placeholder="Location" type="text" autocomplete="off" (keyup)="activateBtn()" (change)="activateBtn()" class="form-control" formControlName="locationDisplay" [value]="locationDisplay" [ngClass]="{ 'is-invalid': flags.sfe && f.locationDisplay.invalid }">
            <mat-icon matSuffix>location_on</mat-icon>
            <mat-error *ngIf="f.locationDisplay.hasError('serverError')">
              <small>{{f.locationDisplay.errors.serverError}}</small>
            </mat-error>
            <mat-error *ngIf="f.locationDisplay.hasError('location')">
              <small>Invalid Location</small>
            </mat-error>
            <mat-error *ngIf="f.locationDisplay.hasError('required')">
              <small>Location is required</small>
            </mat-error>
          </mat-form-field>
          </div>
        <div fxFlex="35">
          <mat-form-field>
            <mat-select placeholder="Max People" formControlName="size">
              <mat-option *ngFor="let size of partysizes" [value]="size">
                {{size}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      
      <div fxLayout="row" fxLayoutGap="20px">
        <div fxFlex="60">
          <!-- <mat-form-field>
            <input matInput placeholder="Tags" type="text" class="form-control" formControlName="tags" [ngClass]="{ 'is-invalid': flags.sfe && f.tags.invalid }">
            <mat-error *ngIf="f.tags.hasError('serverError')">
              <small>{{f.tags.errors.serverError}}</small>
            </mat-error>
            <mat-error *ngIf="f.tags.hasError('tag')">
              <small>Invalid tag</small>
            </mat-error>
          </mat-form-field> -->
          <mat-form-field>
              <mat-select placeholder="Public" formControlName="private" [ngModel]="post.private">
              <mat-option [value]="false">Public</mat-option>
              <mat-option [value]="true">Private</mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div fxFlex="35">
          <mat-form-field>
            <span matPrefix>$ &nbsp;</span>
            <!-- CAN"T GET SELECTED VALUE TO SELECT CURRENT VALUE "Free" -->
              <mat-select placeholder="Price" formControlName="price" (selectionChange)="priceChange($event.value)" [value]="roundPrice(post.price)">
              <mat-option *ngFor="let price of prices" [value]="(price)">{{price}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
          <!-- <div fxFlex="35" class="text-center">
          <mat-slide-toggle labelPosition="top">
            <small>Private</small>
            {{ descriptionsChecked == true ? 'On' : 'Off' }}
            </mat-slide-toggle>    
        </div> -->
      </div>
      
      <div fxLayout="row" fxLayoutGap="20px">
        <div fxFlex>
          <mat-form-field>
            <textarea matInput placeholder="Description" type="text" class="form-control" formControlName="description" [ngClass]="{ 'is-invalid': flags.sfe && f.description.invalid }" autocomplete="post-description" id="autofill-edit-post-description"></textarea>
            <mat-error *ngIf="f.description.hasError('serverError')">
              <small>{{f.description.errors.serverError}}</small>
            </mat-error>
            <mat-error *ngIf="f.description.hasError('required')">
              <small>Invalid description</small>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- LINK -->
      <div class="item date" *ngIf="flags.link">
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex>
            <mat-form-field>
              <input matInput placeholder="eg. gregloveyoga.com" type="text" class="form-control" formControlName="url" [ngClass]="{ 'is-invalid': flags.sfe && f.url.invalid }" autocomplete="post-url" id="autofill-post-url">
              <mat-error *ngIf="f.url.hasError('serverError')">
                <small>{{f.url.errors.serverError}}</small>
              </mat-error>
              <mat-error *ngIf="f.url.hasError('url')">
                <small>Invalid link</small>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- PHOTO & LINK -->
      <div class="item date buttons animate__animated animate__fadeIn animate__flipInY">
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="60" class="text-center">
            <button mat-button type="button" (click)="imageInput.click();">
              <mat-icon>photo_camera</mat-icon>
              <small> Add Photo</small>
            </button>
            <input hidden (change)="processFile(imageInput)" #imageInput type="file" accept="image/*">
            <div *ngIf="selectedFile" class="img-preview-container">
              <div class="img-preview{{selectedFile.status === 'fail' ? '-error' : ''}}"
                    [ngStyle]="{'background-image': 'url('+ selectedFile.src + ')'}">
              </div>
            </div>
          </div>
          <div fxFlex="60" class="text-center">
            <button mat-button type="button" *ngIf="!flags.link" (click)="flags.link=!flags.link">
              <mat-icon>link</mat-icon>
              <small> Add Link</small>
            </button>
            <button mat-button type="button" *ngIf="flags.link" (click)="flags.link=!flags.link;f.url.setValue('');post.url=''">
              <mat-icon>link_off</mat-icon>
              <small> Remove Link</small>
            </button>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="20px">
        <div fxFlex class="uploadedImages">
          <ng-container *ngFor="let image of images">
            <mat-icon (click)="deleteImage(image)" class="deleteImage">cancel</mat-icon>
            <img [src]="image.image" />
          </ng-container>

            <ng-container *ngFor="let image of post?.images?.photos">
              <mat-icon (click)="deleteImage(image)" class="deleteImage">cancel</mat-icon>
              <img [src]="image.image.sm" />
            </ng-container>
        </div>
      
      
      </div>

  </mat-card-content>
  <button [disabled]="flags.btnSubmit" mat-flat-button color="primary" class="item">Save</button>
  <mat-icon (click)="delete()" class="deleteIcon" *ngIf="post?.id && post?.accepted_invite_count == 0">delete</mat-icon>
</mat-card>
</form>

<!-- </div> -->
<!-- <div class="backBlur" [@fadeBackground]=user?.app_state?.createPostBoxBackBlur></div> -->