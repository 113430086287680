import { Component, Input, OnInit, SimpleChanges, OnDestroy, HostListener, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { BackgroundAnimations, inOutAnimation } from 'src/app/animations';
import { Activity, User } from 'src/app/models';
import { ImageSnippet } from 'src/app/models/image';
import { UtilsService } from 'src/app/services';
import { ActivityService } from 'src/app/services/activity.service';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-search-activities',
  templateUrl: './search-activities.component.html',
  styleUrls: ['./search-activities.component.scss'],
  animations: [BackgroundAnimations, inOutAnimation],
})
export class SearchActivitiesComponent implements OnInit, OnDestroy {
  @Input() searchStr;
  @Input() slug;
  @Output() resultCount: EventEmitter<Number> = new EventEmitter();

  flags = {
    sfe: false, // Show Form Errors
    loading: false,
    btnSubmit: true,
    noResults: false,
    popular: '',
  };

  loadLength: number = 30;
  resultOffset: number = 0;
  resultTotal: number = 0;

  tags: string[];
  selectedTags: string[] = [];

  activities: Activity[] = [];
  user: User;
  subs: Subscription[] = [];
  selectedFile: ImageSnippet;
  form: FormGroup;

  constructor(
    private activityService: ActivityService,
    public utils: UtilsService,
    public router: Router,
    private route: ActivatedRoute,
    private imageService: ImageService,
    private formBuilder: FormBuilder,
  ) {
    this.route.params.subscribe(
      (params) => {
        if(this.slug == null){
          if(params['searchStr'] != null){
            this.searchStr = params['searchStr'].toLowerCase();
          }
          if(params['tag'] != null){
            if(params['tag'].includes('popular')){
              this.flags.popular = 'popular';
              this.selectedTags = params['tag'].replace('popular','');
              
              if (this.selectedTags[0] == ',') { 
                this.selectedTags = params['tag'].replace('popular','').substring(1);
              }              
              if (this.selectedTags[this.selectedTags.length-1] == ',') { 
                this.selectedTags = params['tag'].replace('popular','').replace(/,\s*$/, "");
              }              
            }
            else{
              this.selectedTags = [params['tag']];
            }
          }
        }
      }
    );
    this.getTags();
    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
    });
    this.subs.push(user);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      image: [''],
    });

   }

  // ADMIN
  adminSaveUrl(activity){
    this.activityService.updateActivity(activity.id, {url:activity.url}).subscribe();
    activity.meta.showUrl = false;
  }

  adminTogglePremium(activity){
    if (confirm('Change ' + activity.name + ' Premium:' + !activity.is_premium)) {
      activity.is_premium = !activity.is_premium;
      this.activityService.updateActivity(activity.id, {is_premium:activity.is_premium}).subscribe();
    }
 }

  adminProcessFile(imageInput: any, activity: Activity) {
    if (imageInput) {
      this.flags.loading = true;
      const file: File = imageInput.files[0];
      const reader = new FileReader();

      reader.onloadend = (event:any) => {
        if(reader.error){
          console.log(reader.error)
        } else {

          this.selectedFile = new ImageSnippet(event.target.result, file);
          this.selectedFile.pending = true;
          this.imageService.create({ collection_type: 'activity-bg', resource_id: activity.id }, this.selectedFile.file).subscribe(
            (data) => {
              if(activity['images'] == null){
                activity['images'] = [];
              }
              if(activity['images']['photos'] == null){
                activity['images']['photos'] = [];
              }
              activity['images']['photos'].push(data);
              activity.meta.imageUrl = data.image;
              this.selectedFile.pending = false;
              this.utils.alert.success('Uploaded', true);
              this.selectedFile.status = 'ok';          
              this.flags.loading = false;
            },
            (err) => {
              this.selectedFile.pending = false;
              this.utils.alert.error('Upload failed', true);
              this.selectedFile.status = 'fail';
              this.selectedFile.src = '';
              this.flags.loading = false;
            }
          );
        }
       }

      reader.readAsDataURL(file);
    }
  }

  adminDeletePhoto(activity) {
  if (confirm('Delete image?')) {
      this.imageService.delete(activity.meta.image.id).subscribe((data) => {
          _.remove(activity['images'].photos, (obj) => obj.id == activity.meta.image.id);

          activity.meta.imageUrl = this.displayImage(activity);

      });
    }
  }

// END ADMIN

  ngOnChanges(changes: SimpleChanges) {
    this.activities = [];
    this.resultOffset = 0;
    this.resultTotal = 0;
    this.getActivities(this.flags.popular);
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    if(this.slug == null){
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight / 1.3) {
        if (!this.flags.loading && !this.flags.noResults && _.isEmpty(this.searchStr)) {
          this.loadMore();
        }
      }
    }
  };

  swipeLeft(){
    this.router.navigateByUrl('/members');
  }

  nth(i, size){
    return i % size === 0;
  }

  loadMore() {
    this.resultOffset += this.loadLength;
    this.getActivities(this.flags.popular);
  }

  openExternal(link) {
    this.router.ngOnDestroy();
    window.open(link, '_blank');
  }

  togglePopular(){
    if(this.flags.popular == '')
    {
      this.flags.popular = 'popular';
    }
    else{
      this.flags.popular = '';
    }
    this.activities = [];
    this.resultOffset = 0;
    this.resultTotal = 0;
    this.getActivities(this.flags.popular);
  }

  toggleTag(tag){
    if(this.selectedTags.includes(tag)){
      _.pull(this.selectedTags, tag);
    }
    else{
      if(this.selectedTags.length > 1){
        // delete this.selectedTags[1];
        this.selectedTags.shift();
      }
      this.selectedTags = this.selectedTags.concat(tag);
    }
    this.activities = [];
    this.resultOffset = 0;
    this.resultTotal = 0;
    this.getActivities(this.flags.popular);
    if(this.router.url.includes('/activities/tag/') && this.selectedTags.length == 0){
      this.router.navigateByUrl('/activities/');
    }
  }

  tagSelected(tag){
    return this.selectedTags.includes(tag);
  }

  displayImage(activity) {
    if (
      activity != null
      && activity.images != null
      && activity.images.photos != null
      && activity.meta != null
      && activity.images.photos.length > 0
    ) {
      let randomImage = Math.floor(
        Math.random() * activity.images.photos.length
      );

      activity.meta['image'] = activity.images.photos[randomImage];
      return activity.images.photos[randomImage].image.sm;
    } 
    else {
      return './assets/img/background/activities/default.jpg';
    }
  }

  // DATA
  getTags() {
    this.activityService.tags().subscribe((data) => {
      this.tags = data;
    });
  }

  getActivities(ordering?:string) {
    this.flags.loading = true;
    let filters: any = {};
    filters.expand = 'images';
    filters.fields = 'id,name,images.photos,url,slug';
    filters.offset = this.resultOffset;
    filters.limit = this.loadLength;
    if(this.user != null && this.user.hasPerm('admin.add_logentry')){
      filters.ordering = '-created';
    }
    else{
      filters.is_alias = false;
    }
    
    if(ordering){
      filters.ordering = '-' + ordering;
    }
    
    if (this.selectedTags != null && _.isEmpty(this.searchStr)) {
      this.searchStr = '';
      filters.tags = _.toString(this.selectedTags);
    }
    
    if (!_.isEmpty(this.searchStr)) {
      this.selectedTags = [];
      filters.search = this.searchStr;
    }

    this.activityService.list(filters).subscribe((data) => {
      this.resultTotal = data.count;
      let activities = Activity.fromList(data.results);

      _.each(activities, (activity) => {
        let randomTiming = Math.floor(Math.random() * 9);
        activity.meta = {};
        activity.meta['timing'] = randomTiming;
        activity.meta['imageUrl'] = this.displayImage(activity);
      });

      if (data.results.length == 0) {
        this.flags.noResults = true;
      }

      if (!_.isEmpty(this.searchStr)) {
        this.activities = activities;
      }
      else {
        this.activities = this.activities.concat(activities);
      }

      this.resultCount.emit(this.activities.length);
      this.flags.loading = false;
    });
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
