<mat-progress-bar mode="indeterminate" *ngIf="flags.loading" [class.iosProgress]="utils.ios()"></mat-progress-bar>
<ng-container  *ngIf="user?.hasPerm('admin.add_logentry')">
  <div class="backgroundSquares fadeIn"
      *ngFor="let activity of activities"
      [style.background-image]="'url(' + activity?.meta?.imageUrl + ')'" 
      [@zoomBack]=user?.app_state?.homeBackground
      [style.animation-delay]="'0.' +activity?.meta?.timing + 's'" (click)="utils.sound('click-face')">
    <div fxlayout="row">
  
      <div class="title" [routerLink]="'/posts/' + activity.name">
        {{ activity.name }}
      </div>
      <mat-icon (click)="adminTogglePremium(activity)" matTooltip="Toggle Premium" class="premium" *ngIf="!activity?.is_premium">star_border</mat-icon>
      <mat-icon (click)="adminTogglePremium(activity)" matTooltip="Toggle Premium" class="premium" *ngIf="activity?.is_premium">star</mat-icon>
      <button mat-button class="help" (click)="openExternal(activity?.url)" *ngIf="activity?.url != null"><mat-icon>help</mat-icon></button>
    </div>
    <button mat-button class="adminBtn" (click)="imageInput.click()"><mat-icon>add_a_photo</mat-icon></button>
    <button mat-button class="adminBtn" (click)="adminDeletePhoto(activity)" *ngIf="activity['images'] && activity['images'].photos.length > 0 && activity.meta.image != null && activity.meta.image.image.sm != null"><mat-icon>delete</mat-icon></button>
    <input hidden (change)="adminProcessFile(imageInput, activity)" #imageInput type="file" accept="image/*">

    <button mat-button class="adminBtn" (click)="activity.meta.showUrl=true"><mat-icon>help</mat-icon></button>
    <form [formGroup]="form" (ngSubmit)="adminSaveUrl(activity)" class="urlBox" *ngIf="activity.meta.showUrl">
      <mat-form-field>
        <input matInput placeholder="Url" type="text" class="form-control" [(ngModel)]="activity.url" [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <button mat-button (click)="adminSaveUrl(activity)"><mat-icon>save</mat-icon>Save</button>
    </form>
  
  </div>
</ng-container>

<!-- <ng-container  *ngIf="!user?.hasPerm('admin.add_logentry') && activities.length != 0"> -->
<ng-container  *ngIf="!user?.hasPerm('admin.add_logentry')">
  <div class="tags" *ngIf="searchStr == '' && (router.url == '/activities' || router.url == '/search/activities')">
    <button mat-button class="tag" [class.on]="tagSelected(tag.slug)" *ngFor="let tag of tags" (click)="toggleTag(tag.slug)">{{tag.name}}</button>
    <button mat-button class="tag" [class.on]="flags.popular" (click)="togglePopular()">Popular</button>
  </div>
<div  (swipeleft)="swipeLeft()">
  <div class="resultsHeader" [class.on]="searchStr != null" *ngIf="activities.length != 0"><mat-icon *ngIf="searchStr != null">accessibility_new</mat-icon> Activities</div>
  <a class="backgroundSquares fadeIn"
      *ngFor="let activity of activities; let i = index;"
      [style.background-image]="'url(' + activity.meta.imageUrl + ')'" 
      [@zoomBack]=user?.app_state?.homeBackground [routerLink]="'/activity/' + activity.slug"
      [style.animation-delay]="'0.' +activity?.meta?.timing + 's'" (click)="utils.sound('click-face')" [class.one]="nth(i,3)" [class.three]="nth(i+1,3)">
    <div fxlayout="row">
      <div class="title">
        {{ activity.name }}
      </div>
      <mat-icon matTooltip="Premium Activity" class="premium" *ngIf="activity?.is_premium">star</mat-icon>
    </div>
  </a>

  <div class="noResults" *ngIf="(searchStr == null || searchStr == '') && flags.noResults && !flags.loading">
    <h2><mat-icon>accessibility_new</mat-icon></h2>
    <h5>No more activities found</h5>

    <br /><br />
    <button mat-button routerLink="/activities" *ngIf="searchStr" (click)="searchStr=null;getActivities()"><mat-icon>arrow_back</mat-icon> Back</button>
    <br /><br />
  </div>
</div>
</ng-container>
