import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { User, UiState } from '../models';
import * as _ from 'lodash';
import { uiState } from '../models/ui-state';
import { Invite } from '../models/invite';
import { Like } from '../models/like';


@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  // Current User
  // private _uiState: UiState;
  user: ReplaySubject<User>;
  conversation: ReplaySubject<User>;
  likes: ReplaySubject<Like[]>;
  matches: ReplaySubject<User[]>;
  invitesFromMe: ReplaySubject<Invite[]>;
  invitesToMe: ReplaySubject<Invite[]>;
  postCount: ReplaySubject<number>;
  sidenav: ReplaySubject<boolean>; // holds value of sidenav opened or closed
  uiState: ReplaySubject<UiState>;

  constructor() {
    // this._uiState = new UiState(); // Todo: this can be fetched from api
    this.user = new ReplaySubject(1);
    this.conversation = new ReplaySubject(1);
    this.likes = new ReplaySubject(1);
    this.matches = new ReplaySubject(1);
    this.invitesFromMe = new ReplaySubject(1);
    this.invitesToMe = new ReplaySubject(1);
    this.postCount = new ReplaySubject(1);
    this.sidenav = new ReplaySubject(1);
    this.uiState = new ReplaySubject(1);
  }

  createUiState(data:object){
    this.uiState.next(new UiState(data));
  }
}
