import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MessagesComponent } from './messages/messages.component';
import { MessageDetailComponent } from './messages/message-detail/message-detail.component';
import { AuthGuard } from './guards';
import { InteractionComponent } from './dashboard/interaction/interaction.component';
import { RedirectComponent } from './public/redirect/redirect.component';
import {
  MetaModule,
  MetaLoader,
  MetaStaticLoader,
  PageTitlePositioning,
  MetaGuard,
} from '@ngx-meta/core';
import { SearchActivityComponent } from './search/search-activity/search-activity.component';
import { SearchBaseComponent } from './search/search-base/search-base.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./public/public.module').then((m) => m.PublicModule),
    canActivateChild: [MetaGuard],
  },

  // // { path: 'profile/:username', component: ProfileBaseComponent },
  { path: 'messages', component: MessagesComponent, canActivate: [AuthGuard] },
  {
    path: 'messages/:id',
    component: MessageDetailComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivateChild: [AuthGuard, MetaGuard],
  },

  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsModule),
    data: { state: 'settings' },
    canActivateChild: [AuthGuard, MetaGuard],
  },

  { path: 'recent', component: InteractionComponent, canActivate: [AuthGuard] },

  {
    path: 'profile/:username',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
    data: { state: 'profile' },
    canActivateChild: [MetaGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
    data: { state: 'profile' },
    canActivateChild: [MetaGuard],
  },

  {
    path: 'search',
    loadChildren: () =>
      import('./search/search.module').then((m) => m.SearchModule),
    data: { state: 'search' },
    canActivateChild: [MetaGuard],
  },
  { path: 'activities', component: SearchBaseComponent },
  { path: 'activities/tag/:tag', component: SearchBaseComponent },
  { path: 'posts', component: SearchBaseComponent },
  { path: 'members', component: SearchBaseComponent },

  { path: 'activity/:searchStr', component: SearchActivityComponent },


  // {
  //   path: 'search/:activity',
  //   loadChildren: () =>
  //     import('./search/search.module').then((m) => m.SearchModule),
  //   data: { state: 'search' },
  //   canActivateChild: [MetaGuard],
  // },

  {
    path: 'pitchdeck',
    component: RedirectComponent,
    resolve: {
      url: 'externalUrlRedirectResolver',
    },
    data: {
      externalUrl:
        'https://activity8.com/pitchdeck',
    },
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    data: { state: 'admin' },
    canActivateChild: [AuthGuard],
  },
  {
    path: ':username',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
    data: { state: 'profile' },
    canActivateChild: [MetaGuard],
  },

  // { path: 'logout', redirectTo: '/', pathMatch: 'full' },
];



// THIS ISN"T WORKING ARUN HELP META
export function metaFactory(): MetaLoader {
  return new MetaStaticLoader({
    pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
    pageTitleSeparator: ' - ',
    applicationName: 'activity8',
    defaults: {
      title: 'Do stuff, meet people!',
      description: 'I thought you may like this on activity8 app 🤍 ',
      'og:image':
      'https://activity8.app/assets/img/activity8-logo.png',
      'og:type': 'website',
      'og:locale': 'en_US',
    },
  });
}


@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { preloadingStrategy: PreloadAllModules }
      // RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, useHash: true}
    ),

    // THIS ISN"T WORKING ARUN HELP META
    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: metaFactory,
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      },
    },
  ],
})
export class AppRoutingModule {}
