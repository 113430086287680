import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UtilsModule } from '../utils/utils.module';
import { FooterComponent } from './footer/footer.component';
import { NavComponent } from './nav/nav.component';
import { ServerErrorComponent } from './server-error/server-error.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { AddActivitiesComponent } from './add-activities/add-activities.component';
import { AddConversationComponent } from './add-conversation/add-conversation.component';
import { ExperienceComponent } from './experience/experience.component';
import { ActivityDaysComponent } from './activity-days/activity-days.component';
import { EditPostComponent } from './edit-post/edit-post.component';
import { AddCommentComponent } from './add-comment/add-comment.component';
import { LocationComponent } from './location/location.component';
import { IDCardComponent } from './idcard/idcard.component';
import { AgmCoreModule } from '@agm/core';
import { MapComponent } from './map/map.component';
import { MatAnimatedIconComponent } from './mat-animated-icon/mat-animated-icon.component';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeaderComponent } from './header/header.component';
import { CreateInviteComponent } from './create-invite/create-invite.component';

@NgModule({
  imports: [
    CommonModule, 
    RouterModule, 
    UtilsModule, 
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB_gzHqA30wcUxAa3WTa0cIe_6UHFRkNxs'
    }),
    FontAwesomeModule,
    ShareModule,
    ShareIconsModule,
  ],
  declarations: [
    HeaderComponent,
    NavComponent,
    ServerErrorComponent,
    FooterComponent,
    SidenavComponent,
    ColorPickerComponent,
    CreateInviteComponent,
    AddActivitiesComponent,
    AddConversationComponent,
    AddCommentComponent,
    ExperienceComponent,
    ActivityDaysComponent,
    EditPostComponent,
    LocationComponent,
    MapComponent,
    IDCardComponent,
    MatAnimatedIconComponent,
  ],
  exports: [
    AgmCoreModule,
    HeaderComponent,
    NavComponent,
    SidenavComponent,
    ColorPickerComponent,
    CreateInviteComponent,
    AddActivitiesComponent,
    AddConversationComponent,
    AddCommentComponent,
    ExperienceComponent,
    ActivityDaysComponent,
    EditPostComponent,
    LocationComponent,
    MapComponent,
    IDCardComponent,
    MatAnimatedIconComponent,
    FooterComponent,
    ServerErrorComponent,
    FontAwesomeModule,
    ShareModule,
    ShareIconsModule,
  ],
  entryComponents: [],
})
export class ComponentsModule { }
