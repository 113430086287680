import { User } from ".";
import * as _ from 'lodash';

export class Message {
  id: number;
  date: string;
  time: string;
  from: User;
  sender: User;
  to: User;
  message: string;

  constructor(data: Object) {
    Object.assign(this, data);
  }

  public static fromList(datalist: object[]) {
    return _.map(datalist, item => {
      return new Message(item);
    });
  }

}
