import * as _ from 'lodash';

export class UiState {
  private locationFilter?: boolean;

  constructor(data?: object) {
    Object.assign(this, data || {});
  }

  setProperty(key: string, val: any) {
    if (_.has(this, key)) {
      _.set(this, key, val);
      this.save();
    } else {
      throw Error(`Invalid key: ${key}`);
    }
  }

  getProperty(key: string): any {
    return _.get(this, key);
  }

  // save to backend/db
  private save() {
    console.warn("Needs to save in permanent storage/backend");
  }
}