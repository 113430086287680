import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { User } from 'src/app/models';
import { UserService, UtilsService } from 'src/app/services';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-add-conversation',
  templateUrl: './add-conversation.component.html',
  styleUrls: ['./add-conversation.component.scss'],
})
export class AddConversationComponent implements OnInit, OnDestroy {
  @Input() to: User;
  @Output() change: EventEmitter<any>;

  flags = {
    loading: false,
    btnAdding: true,
    sfe: true,
    state: '',
  };
  user: User;
  subs: Subscription[] = [];
  form: FormGroup;
  form2: FormGroup;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  recipientCtrl = new FormControl();
  filteredRecipients: Observable<User[]>;
  recipient: User;
  recipients: User[] = [];
  allRecipients: User[] = [];
  popularRecipients: User[] = [];

  @ViewChild('recipientInput') recipientInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private utils: UtilsService,
    private formBuilder: FormBuilder
  ) {
    this.change = new EventEmitter();

    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
    });
    this.subs.push(user);

    this.filteredRecipients = this.recipientCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value['full_name'])),
      map((name) => {
        return this._filter(name);
      })
    );

    // this.getPopularRecipients();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.recipients = [];
    this.to = changes.to.currentValue;
    if (this.to != null) {
      this.recipients.push(this.to);
    }
  }

  ngOnInit() {
    this.flags.state = 'in';

    this.form = this.formBuilder.group({
      recipients: ['', [Validators.required]],
    });

    this.form2 = this.formBuilder.group({
      message: ['', [Validators.required]],
    });
  }

  get f1() {
    return this.form.controls;
  }
  get f2() {
    return this.form2.controls;
  }

  clickDone() {
    // this.user.app_state.showSetRecipient = false;
    // this.utils.appState.user.next(this.user);
    this.change.emit(false);
  }

  clickAddPill(recipient?) {
    // console.log(activity);
    this.popularRecipients = this.popularRecipients.filter(
      (obj) => obj.id != recipient.id
    );
    if (!this.isAdded(recipient)) {
      this.addRecipient(recipient);
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add recipient
    if ((value || '').trim()) {
      this.addRecipient(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.recipientCtrl.setValue(null);
  }

  remove(recipient: User): void {
    this.recipients = this.recipients.filter((obj) => obj.id !== recipient.id);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let recipient = event.option.value;
    this.popularRecipients = this.popularRecipients.filter(
      (obj) => obj.id != recipient.id
    );
    if (!this.isAdded(recipient)) {
      this.addRecipient(recipient);
    }
    this.recipientInput.nativeElement.value = '';
    this.recipientCtrl.setValue('');
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();

    this.getRecipientsByName(filterValue);

    // console.log(this.allRecipients);
    return this.allRecipients.filter((user) => user.full_name.toLowerCase().indexOf(filterValue) === 0);
  }

  displayFn(recipient: User): string {
    return recipient && recipient.full_name ? recipient.full_name : '';
  }

  activateBtn() {
    if (this.recipients != null && this.recipients.length > 0) {
      this.flags.btnAdding = false;
    } else {
      this.flags.btnAdding = true;
    }
  }

  filterPopular() {
    this.recipients.forEach((user) => {
      _.remove(this.popularRecipients, (obj) => obj.id == user.id);
    });
  }

  // FUNCTIONS
  isAdded(recipient): boolean {
    let exists = this.recipients.filter((obj) => obj.id === recipient.id);
    if (exists.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  addRecipient(recipient) {
    this.recipients.push(recipient);
    this.filterPopular();
  }

  removeRecipient(activityid) {
    this.filterPopular();
  }

  // DATA
  sendMessage() {
    if (this.form2.invalid) {
      this.flags.sfe = true;
      return;
    }

    this.utils.sound('send');
    this.flags.state = 'send';
    let recipientIds = [];
    let names = [];

    this.recipients.forEach((user) => {
      recipientIds.push(user.id);
      names.push(user.first_name);
    });
    recipientIds.push(this.user.id);

    let data = {
      title: names.join(),
      room_type: 'oneToOne',
      members: recipientIds,
      message: this.f2.message.value,
    };

    this.messageService.create(data).subscribe((data) => {
      this.user.app_state.startConversation = null;
      this.utils.appState.user.next(this.user);
      this.recipients = [];
      this.f2.message.setValue('');
      this.flags.state = 'in';
    });
  }

  getRecipientsByName(name) {
    this.userService
      .getByName({ search: name, limit: 10 })
      .subscribe((data) => {
        // NEED TO REMOVE USER ACTIVITIES
        // _.remove(data, function(activity) {
        //     return activity === this.user.activities;
        // });

        this.allRecipients = data;
      });
  }

  getPopularRecipients() {
    this.userService.getAll({ limit: 20 }).subscribe((data) => {
      this.popularRecipients = data;
      this.filterPopular();
    });
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
